import * as React from 'react'

export default function Icon(props) {
  const { width, height, ...rest } = props

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 43.31 43.31"
      width={width || 43.31}
      height={height || 43.31}
      {...rest}
    >
      <g>
        <path fill="currentColor" d="M29.79,33.66H13.52a3.87,3.87,0,0,1-3.86-3.87V27.87a1,1,0,0,1,2,0v1.92a1.87,1.87,0,0,0,1.86,1.87H29.79a1.87,1.87,0,0,0,1.87-1.87V27.87a1,1,0,0,1,2,0v1.92A3.88,3.88,0,0,1,29.79,33.66Z"/>
        <path fill="currentColor" d="M21.66,28.76a1,1,0,0,1-1-1V10.66a1,1,0,0,1,2,0v17.1A1,1,0,0,1,21.66,28.76Z"/>
        <path fill="currentColor" d="M28.66,18.66a1,1,0,0,1-.71-.3l-6.29-6.29-6.3,6.29A1,1,0,1,1,14,17l7-7a1,1,0,0,1,1.41,0l7,7a1,1,0,0,1,0,1.41A1,1,0,0,1,28.66,18.66Z"/>
      </g>
    </svg>
  )
}
