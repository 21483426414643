import Maybe from '../../../../../core/Maybe'
import Wrap from '../../../../../core/wraps/Wrap'
import checkSelectionsForLoading from '../checkSelectionsForLoading'
import Dispatch from '../Dispatch'
import GetState from '../GetState'

export default (wrap:Maybe<Wrap>) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({ type:'SELECT_ACCENT_WRAP', wrap:wrap })
		dispatch(checkSelectionsForLoading())
  }
}