import Dispatch from './Dispatch'
import GetState from './GetState'
import Vehicle from '../../../../core/vehicles/Vehicle'
import selectors from '../store/selectors'
import View from '../store/models/View';
import EditorPanelState from '../store/models/EditorPanelState';
import removePaintProtectionFilm from './films/removePaintProtectionFilm'

export default (vehicle:Vehicle) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		const state = getState()
		const currentView = selectors.getCurrentView(state)
		if (currentView !== View.VehicleEdit) {
			console.warn('selectVehicle called, but the UI state was not in the edit vehicle state. Only call this action when in the edit vehicle state.')
			return
		}

    if (!vehicle) {
      console.warn('selectVehicle called, but no vehicle was temporarily selected! Ignoring.')
      return
		}

		const currentVehicle = selectors.getSelectedVehicle(state)
		if (vehicle === currentVehicle) {
			console.warn('selectVehicle called, but the vehicle was the same. Ignoring.')
			return
    }
    
    const selectedFilm = selectors.getSelectedPaintProtectionFilm(state)
    if (selectedFilm && !selectors.doesVehicleSupportPaintProtectionFilm(vehicle, selectedFilm)) {
      // If the new vehicle doesn't support the same PPF option that was selected, just remove the PPF selection.
      dispatch(removePaintProtectionFilm())
    }

    dispatch({
      type: 'SELECT_VEHICLE',
      vehicle: vehicle
    })

    try
    {
      const environmentConfig = selectors.getEnvironmentConfig(state)
      await vehicle.load(environmentConfig.getAssetBasePath())
    }
    catch(e) {
      console.warn('Unable to load selected vehicle! Aborting!')
      return
    }

		dispatch({
			type: 'SET_EDITOR_PANEL_STATE',
			panelState: EditorPanelState.Closed
		})
  }
}