import * as React from 'react'
import cx from 'classnames'

let styles = {
  all: {
    fill: 'none',
    stroke: 'currentColor'
  }
}

interface Props {
  className?: string
}

export default class MaterialsIcon extends React.Component<Props, {}> {
  render() {
    return (
      <svg
        className={cx('MaterialsIcon', this.props.className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.31 22"
        width="44"
        height="44"
        style={{ maxWidth: '100%' }}
      >
        <circle style={styles.all} cx="4.5" cy="18.01" r="1" />
        <path
          style={styles.all}
          d="M8.1,21.31,20.57,8.94a1,1,0,0,0-.11-1.41L15.91,3.62a1,1,0,0,0-.74-.24"
        />
        <rect style={styles.all} x="0.5" y="0.5" width="8" height="21" rx="1" />
        <path
          style={styles.all}
          d="M8.5,20.21,15.2,4.14a1,1,0,0,0-.59-1.29L9,.77a1,1,0,0,0-.78,0"
        />
      </svg>
    )
  }
}
