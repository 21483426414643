import UserSelections from "./UserSelections"
import PanelGroup from "./vehicles/PanelGroup"
import Tint from "./tints/Tint"
import Wrap from "./wraps/Wrap"
import Vehicle from "./vehicles/Vehicle"
import StringTaker from './utils/StringTaker'
import SceneMode from "./SceneMode";
import PaintProtectionFilm from "./films/PaintProtectionFilm"
import { CLIENT_RENEG_LIMIT } from "tls"
import Maybe from './Maybe'

class SharingUtility {
	static Placeholder = '-' // This character is used when the user has selected null/empty for a given slot.

	static SceneModeIdentifierLength = 1
	static TintIdentifierLength = 1
	static VehicleIdentifierLength = 1
	static WrapIdentifierLength = 2
	static PaintProtectionFilmIdentifierLength = 1

	static createShareCode = (selections:UserSelections, isPreview:boolean = false):string => {
		// Remember if you change the organization of the code structure here, you must update the `selectionsFromCode` method as well to match!
		let code = ''

		const vehicle = selections.getVehicle()
		if (!vehicle) { // It's possible the user hasn't selected a vehicle yet; if they click share from the vehicle selection screen.
			return code
		}
		code += vehicle.shareIdentifier

		const tint = selections.getTint()
		if (!tint) {
			code += SharingUtility._repeatPlaceholder(SharingUtility.TintIdentifierLength)
		}
		else {
			code += tint.shareIdentifier
		}

		const accent = selections.getAccent()
		if (!accent) {
			code += SharingUtility._repeatPlaceholder(SharingUtility.WrapIdentifierLength)
		}
		else {
			code += accent.meta.shareIdentifier
    }

		const sceneMode = selections.getSceneMode()
		code += (isPreview ? "P" : (sceneMode === SceneMode.Daytime ? "D" : "N"))

		for(let i=0; i<PanelGroup.Keys.length; i++) {
			const panelKey = PanelGroup.Keys[i]
			const panelGroup = vehicle.getPanelGroup(panelKey)
			if (!panelGroup) {
				code += SharingUtility._repeatPlaceholder(SharingUtility.WrapIdentifierLength)
				continue
			}

			const wrap = selections.getWrapForPanelGroup(panelGroup)
			if (!wrap) {
				code += SharingUtility._repeatPlaceholder(SharingUtility.WrapIdentifierLength)
				continue
			}

			// console.log(wrap.meta.shareIdentifier)
			code += wrap.meta.shareIdentifier
		}

		const film = selections.getPaintProtectionFilm()
		if (!film) {
			code += SharingUtility._repeatPlaceholder(SharingUtility.PaintProtectionFilmIdentifierLength)
		}
		else {
			code += film.shareIdentifier
		}

		return code
	}

	static selectionsFromCode = (
    code:string,
    vehicles:Array<Vehicle>,
    tints:Array<Tint>,
    wraps:Array<Wrap>,
    films:Array<PaintProtectionFilm>
  ):Maybe<UserSelections> => {
		if (!code) {
			console.warn('SharingUtility.selectionsFromCode called, but no code was supplied. Did you mean to call this method?')
			return null
		}

		// This expected length check lets us use the StringTaker below without constantly doing length checks.
		const expectedLength = SharingUtility.VehicleIdentifierLength
			+ SharingUtility.TintIdentifierLength
			+ SharingUtility.WrapIdentifierLength
			+ SharingUtility.SceneModeIdentifierLength
			+ (PanelGroup.Keys.length * SharingUtility.WrapIdentifierLength)
		const expectedLengthAlt = expectedLength + SharingUtility.PaintProtectionFilmIdentifierLength

		//console.log("code: ", code, ", expectedLength: ", expectedLength, ", code.length: ", code.length)
		if (code.length !== expectedLength && code.length !== expectedLengthAlt) {
			console.warn('SharingUtility.selectionsFromCode called, but the code was not of the expected length!')
			return null
		}

		const selections = new UserSelections()
		const taker = new StringTaker(code)

		const vehicleKey = taker.take(SharingUtility.VehicleIdentifierLength)
		const vehicle = vehicles.find( (vehicle) => {
			return vehicle.shareIdentifier.toLowerCase() === vehicleKey.toLowerCase()
		})
		if (!vehicle) {
			console.warn('SharingUtility.selectionsFromCode called, but the vehicle key `' + vehicleKey + '` was not found!')
			return null
		}
		selections.setVehicle(vehicle)

		const tintKey = taker.take(SharingUtility.TintIdentifierLength)
		if (tintKey.indexOf(SharingUtility.Placeholder) < 0) {
			const tint = tints.find( (tint) => {
				return tint.shareIdentifier.toLowerCase() === tintKey.toLowerCase()
			})
			if (!tint) {
				console.warn('SharingUtility.selectionsFromCode called, but the tint key `' + tintKey + '` was not found!')
			}
			else {
				selections.setTint(tint)
			}
		}

		const accentKey = taker.take(SharingUtility.WrapIdentifierLength)
		if (accentKey.indexOf(SharingUtility.Placeholder) < 0) {
			const accent = wraps.find( (wrap) => {
				return wrap.meta.shareIdentifier.toLowerCase() === accentKey.toLowerCase()
			})
			if (!accent) {
				console.warn('SharingUtility.selectionsFromCode called, but the accent key `' + accentKey + '` was not found!')
			}
			else {
				selections.setAccent(accent)
			}
		}

		const sceneKey = taker.take(SharingUtility.SceneModeIdentifierLength)
		if (sceneKey.indexOf(SharingUtility.Placeholder) < 0) {
			const scene = sceneKey.toLowerCase() === "d" ? SceneMode.Daytime : SceneMode.Nighttime
			selections.setSceneMode(scene)
		}

		for(let i=0; i<PanelGroup.Keys.length; i++) {
			const panelKey = PanelGroup.Keys[i]
			const panelGroup:Maybe<PanelGroup> = vehicle.getPanelGroup(panelKey)

			const wrapKey = taker.take(SharingUtility.WrapIdentifierLength)
			if (wrapKey.indexOf(SharingUtility.Placeholder) < 0) {
				const wrap = wraps.find( (wrap) => {
					return wrap.meta.shareIdentifier.toLowerCase() === wrapKey.toLowerCase()
				})
				if (!wrap) {
					console.warn('SharingUtility.selectionsFromCode called, but the wrap key `' + wrapKey + '` was not found!')
				}
				else {
					selections.setPanelGroupWrap(panelGroup, wrap)
				}
			}
		}

		const filmKey = taker.maybeTake(SharingUtility.PaintProtectionFilmIdentifierLength)
		if (filmKey) {
			const match = films.find( (film) => { return film.shareIdentifier.toLowerCase() === filmKey.toLowerCase()})
			if (!match) {
				console.warn('SharingUtility.selectionsFromCode called, but the film key `' + filmKey + '` was not found!')	
			}
			else {
				selections.setPaintProtectionFilm(match)
			}
		}

		return selections
	}

	static _repeatPlaceholder = (numberOfTimes:number):string => {
		let value = ''
		for(let i=1; i<=numberOfTimes; i++) {
			value += SharingUtility.Placeholder
		}
		return value
	}

	static createWrapsDescription = (selections:UserSelections, translate:any):Array<string> => {
		let phrases = []
		const vehicle = selections.getVehicle()
		if ( vehicle ) {
			for(let i = 0; i < PanelGroup.Keys.length; i++) {
				const panelKey = PanelGroup.Keys[i]
				const panelGroup = vehicle.getPanelGroup(panelKey)
				if ( panelGroup ) {
					const wrap = selections.getWrapForPanelGroup(panelGroup)
					if ( wrap ) {
						const phrase = SharingUtility.removeSeriesFromFamilyName(translate(wrap.meta.productFamily)) + " " + translate(wrap.meta.name)
						if (phrases.indexOf(phrase) === -1) {
							phrases.push(phrase)
						}
					}			
				}
			}
		}
		return phrases
	}

	static createTintDescription = (selections:UserSelections, translate:any):Maybe<string> => {
		const tint = selections.getTint()
		if (!tint) {
			return null
		}

		return translate(tint.productFamily) + " " + translate(tint.name)
	}

	static removeSeriesFromFamilyName = (productFamily:string):string => { // Why don't they just do this in the translation files...?
		if (!productFamily) {
			return ''
		}

		return productFamily.replace(' 1080', '').replace(' 2080', '')
	}
}

export default SharingUtility