import THREE from '../../three/threeWithExtensions'
import IMaterial from '../IMaterial'
import shaderMaterialPromiseCreator from '../../utils/ShaderMaterialPromiseCreator'
import VoidPromise from '../../utils/VoidPromise'
import { GuiFieldName, prepareColorField, onColorChange } from '../../utils/onColorChange'
import Maybe from '../../Maybe'

const vertexShader = `
varying vec4 mvPosition;
varying vec3 worldNormal;
varying vec3 cameraToVertex;

void main() {
  mvPosition = modelViewMatrix * vec4( position, 1.0 );
  worldNormal = mat3( modelMatrix[ 0 ].xyz, modelMatrix[ 1 ].xyz, modelMatrix[ 2 ].xyz ) * normal;
  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  cameraToVertex = normalize(worldPosition.xyz - cameraPosition);

  gl_Position = projectionMatrix * mvPosition;
}
`

const fragmentShader = `
uniform float opacity;
uniform samplerCube envMap;
uniform samplerCube envMapNight;

uniform float daytimePercentage;
uniform vec3 windowColor;
uniform float windowOpacity;
uniform float environmentContribution;
uniform vec3 subtractionColor;

varying vec3 worldNormal;
varying vec4 mvPosition;
varying vec3 cameraToVertex;

void main() {
  float fFresnel = dot( normalize( -cameraToVertex ), worldNormal);
  vec3 reflection = 2.0 * worldNormal * fFresnel - normalize(-cameraToVertex);

  vec4 envColorDay = textureCube( envMap, vec3( -reflection.x, reflection.yz ));
  vec4 envColorNight = textureCube( envMapNight, vec3( -reflection.x, reflection.yz ));
  vec4 envColor = mix(envColorNight, envColorDay, daytimePercentage);
  envColor.rgb *= environmentContribution;

  gl_FragColor = vec4(windowColor, 1.0 - windowOpacity) - vec4(subtractionColor.rgb, 0.0) + vec4(envColor.rgb, 0.0);
}
`

class TintMaterial implements IMaterial {
	_uniforms:any
	_material:Maybe<THREE.ShaderMaterial> = null
  _folder:any
  _loadPromise:Maybe<Promise<THREE.ShaderMaterial>> = null

	constructor(uniforms:any) {
		this._uniforms = {
      daytimePercentage: { type: "f", value: 0.0, min: 0.0, max: 1.0 },
      windowColor: prepareColorField({ type: "c", value: new THREE.Color(uniforms.windowColor) }),
      windowOpacity: { type: "f", value: uniforms.windowOpacity, min: 0.0, max: 1.0 },
      environmentContribution: { type: "f", value: uniforms.environmentContribution, min: 0.0, max: 1.0 },
      subtractionColor: prepareColorField({ type: "c", value: new THREE.Color(uniforms.subtractionColor) }),
      envMap: { type: "t", value: null },
      envMapNight: { type: "t", value: null }
    }
	}

  getRenderMaterial = ():THREE.Material => {
		return this._material
  }

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    /* This material doesn't need to utilize the color map. So it's okay that it does nothing here. */
  }

	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		this._uniforms.envMap.value = dayEnvMap
    this._uniforms.envMapNight.value = nightEnvMap

    const material = this.getRenderMaterial() as THREE.ShaderMaterial
    material.map = dayEnvMap // This is needed for iOS/Android/Mac
	}

	setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
		this._uniforms.daytimePercentage.value = daytimePercentage
  }
  
  isLoading = ():boolean => {
    return (this._loadPromise && !this.isLoaded())
  }

  isLoaded = ():boolean => {
		return !!this._material
	}

  load = async (basePath:string):Promise<void> => {
    if (this._loadPromise) {
      return this._loadPromise
    }

    this._loadPromise = shaderMaterialPromiseCreator({ // This call can fail when WebGL is not available
      uniforms : this._uniforms,
      vertexShader : vertexShader,
      fragmentShader : fragmentShader,
      blending: THREE.NormalBlending,
      transparent: true,
      extensions: {
        derivatives: true
      }
    })
    this._loadPromise.then( (material:THREE.ShaderMaterial) => {
      this._material = material
      this._material.name = "TintMaterial"
    })

    return this._loadPromise
  }

  configureGui = (gui:dat.GUI):void => {
    this._folder = gui.addFolder('Tint')
    this._folder.addColor(this._uniforms.windowColor, GuiFieldName).name('Window Color').onChange( onColorChange(this._uniforms.windowColor) )
    this._folder.add(this._uniforms.windowOpacity, 'value', 0, 1).name('Window Opacity').step(0.01)
    this._folder.add(this._uniforms.environmentContribution, 'value', 0, 1).name('Environment Contribution').step(0.01)
    this._folder.addColor(this._uniforms.subtractionColor, GuiFieldName).name('Subtraction Color').onChange( onColorChange(this._uniforms.subtractionColor) )
  }

  unloadGui = (gui:dat.GUI):void => {
    if (this._folder) {
      gui.removeFolder(this._folder)
      this._folder = null
    }
  }
}

export default TintMaterial