import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class GenericGlass extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "GenericGlass",
      roughness: 0.0,
      transparent: true,
      opacity: 0.1
    }), false)
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		this._material.envMap = dayEnvMap
	}
}

export default GenericGlass