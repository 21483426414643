import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import Wrap from '../../../../../core/wraps/Wrap'

export default (wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		const state = getState()
		const panelGroup = selectors.getSelectedPanelGroup(state)

		dispatch({ type:'TEMPORARILY_SELECT_WRAP', panelGroup:panelGroup, wrap:wrap })
  }
}