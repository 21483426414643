import * as React from 'react'

let styles = {
  line: {
    stroke: 'currentColor'
  },
  path: {
    fill: 'none',
    stroke: 'currentColor',
    strokeLineCap: 'round',
    strokeLineJoin: 'round'
  }
}

export default function(): React.ReactElement<{}> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.14 14.91"
      width="48"
      height="30"
      style={{ maxWidth: '100%' }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <line
            style={styles.line}
            x1="16.38"
            y1="8.12"
            x2="13.05"
            y2="11.45"
          />
          <line style={styles.line} x1="15.33" y1="5.67" x2="9.9" y2="11.1" />
          <path
            style={styles.path}
            d="M23.64,2.71H13.36a3.92,3.92,0,0,0-2.74,1.13l-10,9.83a.43.43,0,0,0,.3.74H23.64Z"
          />
          <path
            style={styles.path}
            d="M4.38.5A2.52,2.52,0,0,1,1.86,3.09,2.51,2.51,0,0,1,4.38,5.67,2.51,2.51,0,0,1,6.9,3.09,2.52,2.52,0,0,1,4.38.5Z"
          />
        </g>
      </g>
    </svg>
  )
}
