import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import closeModal from './closeModal'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    let state = getState()
    let modal = selectors.getCurrentModal(state)
    while(modal) {
      await dispatch(closeModal(modal))
      state = getState()
      modal = selectors.getCurrentModal(state)
    }
  }
}