import Dispatch from './Dispatch'
import GetState from './GetState'
import * as querystring from 'querystring'
import TrackingMeta from '../store/models/TrackingMeta'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {

		const params = querystring.parse(window.location.search.substring(1))
		
		const meta = new TrackingMeta()
		if (params.utm_source) {
			meta.utm_source = params.utm_source.toString()
		}
		if (params.utm_medium) {
			meta.utm_medium = params.utm_medium.toString()
		}
		if (params.utm_campaign) {
			meta.utm_campaign = params.utm_campaign.toString()
		}
		if (params.utm_term) {
			meta.utm_term = params.utm_term.toString()
		}
		if (params.utm_content) {
			meta.utm_content = params.utm_content.toString()
		}

		dispatch({
			type: 'SET_TRACKING_META',
			meta: meta
		})
	}
}