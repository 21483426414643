import cx from 'classnames'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import Maybe from '../../../../../../../core/Maybe'
import Wrap from '../../../../../../../core/wraps/Wrap'
import { clamp, remap } from '../../../../helpers/math'
import useGesture from '../../../../helpers/react-use-gesture'
import CancelIcon from '../../../icons/Cancel'
import DesktopScrollBar from '../DesktopScrollBar/DesktopScrollBar'

const SWATCH = {
  boundingSize: 36,
  size: 28,
  margin: 8
}

export interface PagerSwatch {
  code: string
  swatchColor: string
  swatchImage?: string
}

interface OwnProps {
  basePath: string
  wraps: Wrap[]
  width: number
  selectedWrap: Maybe<Wrap>
  temporaryWrap: Maybe<Wrap>
  onHoverWrap: (wrap:Maybe<Wrap>) => void
  onSelectWrap: (wrap:Maybe<Wrap>) => void
}

const DesktopPager = (props:OwnProps) => {
  const numberOfWraps:number = props.wraps.length
  const columns:number = Math.floor(props.width / SWATCH.boundingSize)
  const perPage:number = columns * 2
  const pages:number = Math.ceil(numberOfWraps / perPage)

  const scrollAreaWidth:number = Math.ceil((numberOfWraps + 1) / 2) * SWATCH.boundingSize
  const farthestPossibleScroll:number = scrollAreaWidth - props.width

  // sideFadeSpring.value:
  // -1 === right-fade visible
  //  0 === both left-fade and right-fade visible
  //  1 === left-fade visible
  // starting value is -1 because the Pager starts scrolled to the left.
  const [sideFadeSpring, setSideFadeSpring] = useSpring(() => ({ value:-1 }))
  const updateFadeSpring = useCallback(
    (scrollLeft:number) => {
      if (scrollLeft <= 10) {
        setSideFadeSpring({ value: -1 })
      }
      else if (scrollLeft >= farthestPossibleScroll - 10) {
        setSideFadeSpring({ value: 1 })
      }
      else {
        setSideFadeSpring({ value: 0 })
      }
    },
    [farthestPossibleScroll]
  )

  const scrollBarRef = useRef(null) // This will be an object with a setX method
  const bind = useGesture({
    onScroll: (event) => {
      const scrollLeft:number = event.xy[0]
      const x:number = remap(0, farthestPossibleScroll, 0, 1, scrollLeft)
      if (scrollBarRef.current) {
        // @ts-ignore Yes, this exists.
        scrollBarRef.current.setX(x)
      }
      updateFadeSpring(scrollLeft)
    }
  })

  const scrollPanelRef = React.useRef(null)
  const onScrollBarScroll = React.useCallback(
    (x:number) => {
      const scrollLeft:number = remap(0, 1, 0, farthestPossibleScroll, x)
      const element:Maybe<HTMLDivElement> = scrollPanelRef.current
      if (element) {
        // @ts-ignore Yes, this exists.
        element.scrollLeft = scrollLeft
      }
      updateFadeSpring(scrollLeft)
    },
    [farthestPossibleScroll]
  )

  return (
    <div className='DesktopPager'>
      <animated.div
        key='left-fade'
        className='DesktopPager--leftFade'
        style={{
          height: SWATCH.boundingSize * 2,
          transform: sideFadeSpring.value.to(
            (v:number) => `translateX(${clamp(-50, 0, remap(-1, 0, -50, 0, v))}px)`
          )
        }}
      />
      <animated.div
        key='right-fade'
        className='DesktopPager--rightFade'
        style={{
          height: SWATCH.boundingSize * 2,
          transform: sideFadeSpring.value.to(
            (v:number) => `translateX(${clamp(0, 50, remap(0, 1, 0, 50, v))}px)`
          )
        }}
      />
      <div
        ref={scrollPanelRef as any}
        {...bind()}
        className='DesktopPager--scrollArea pager-scrollarea'
        style={{
          width: props.width,
          height: SWATCH.boundingSize * 2 + 20
        }}
      >
        <div
          className='DesktopPager--scrollAreaInner'
          style={{
            height: SWATCH.boundingSize * 2,
            width: Math.ceil(numberOfWraps / 2) * SWATCH.boundingSize
          }}
        >
          <Page
            basePath={props.basePath}
            wraps={props.wraps}
            columns={columns}
            selectedWrap={props.selectedWrap}
            temporaryWrap={props.temporaryWrap}
            onHoverWrap={props.onHoverWrap}
            onSelectWrap={props.onSelectWrap}
          />
        </div>
      </div>
      <div className='DesktopPager--scrollBar'>
        <DesktopScrollBar
          ref={scrollBarRef}
          width={props.width}
          handleWidth={(props.width) / pages}
          onScroll={onScrollBarScroll}
        />
      </div>
    </div>
  )
}

export default DesktopPager

interface PageProps {
  basePath: string
  wraps: Array<Wrap>
  columns: number
  selectedWrap: Maybe<Wrap>
  temporaryWrap: Maybe<Wrap>
  onHoverWrap: (wrap:Maybe<Wrap>) => void
  onSelectWrap: (wrap:Maybe<Wrap>) => void
}

const Page = (props:PageProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: SWATCH.boundingSize + SWATCH.boundingSize,
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: SWATCH.boundingSize,
        gridTemplateRows: '1fr 1fr',
        justifyItems: 'center',
        alignItems: 'center'
      }}
    >
      <button
        className={cx('wrap-select-button', !props.selectedWrap && 'selected')}
        style={{
          height: SWATCH.boundingSize
        }}
        onMouseEnter={() => {
          props.onHoverWrap(null)
        }}
        onClick={() => {
          if (!props.selectedWrap) { // If there is no selected wrap, then there's no need to change anything.
            return
          }

          props.onSelectWrap(null)
        }}
      >
        <div
          className='wrap-select-swatch'
          style={{
            height: SWATCH.size,
            width: SWATCH.size,
            display: 'inline-block',
            color: '#b7b7b7'
          }}
        >
          <CancelIcon />
        </div>
      </button>
      {props.wraps.map((wrap:Wrap) => {
        const styles:any = {
          backgroundColor: wrap.meta.swatchColor
        }
        
        if (wrap.meta.swatchImage) {
          styles.backgroundImage = 'url("' + props.basePath + wrap.meta.swatchImage + '")'
        }

        return (
          <button
            key={wrap.meta.code}
            data-sort-order={wrap.meta.sortOrder}
            className={cx('wrap-select-button desktop-swatch', {
              selected: wrap === props.selectedWrap,
              temporary: wrap === props.temporaryWrap
            })}
            style={styles}
            onMouseEnter={() => {
              props.onHoverWrap(wrap)
            }}
            onClick={() => {
              props.onSelectWrap(wrap)
            }}
          />
          )
      })}
    </div>
  )
}