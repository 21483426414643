class Size {
	width:number
	height:number

	constructor(width:number, height:number) {
		this.width = width
		this.height = height
	}

	equals = (size:Size):boolean => {
		return this.width === size.width && this.height === size.height
	}
}

export default Size