import cx from 'classnames'
import * as React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import Tint from '../../../../../../../core/tints/Tint'
import selectTint from '../../../../actionCreators/tints/selectTint'
import AppState from '../../../../store/models/AppState'
import selectors from '../../../../store/selectors'
import TintsIcon from '../../../icons/Tints'
import DesktopAssetSelectorPanel from '../DesktopAssetSelectorPanel/DesktopAssetSelectorPanel'
import DesktopScrollArea from '../DesktopScrollArea/DesktopScrollArea'

interface OwnProps {
  onBackClick: () => void
}

interface ReduxProps {
  tints: Array<Tint>
  selectedTint: Tint
  translate: (key: string) => string
}

interface DispatchProps {
  selectTint: (tint:Maybe<Tint>) => void
}

const NO_TINT:Tint = new Tint(' ', 'editor.no-selection.title', 'editor.no-selection.caption', ' ', '', {})

const DesktopTintSelector = (props:OwnProps & ReduxProps & DispatchProps) => {
  const [hoveredTint, setHoveredTint] = useState<Maybe<Tint>>(null)
  const highlightedTint:Tint = hoveredTint || props.selectedTint || NO_TINT

  return (
    <DesktopAssetSelectorPanel
      title={props.translate('editor.tints.title')}
      titleIcon={<TintsIcon />}
      className='DesktopTintSelector panel-group-selector--tints'
      bodyClassName='panel-group-selector--body'
      footerText={props.translate('editor.tints.caption')}
    >
      <div>
        <div>
          <b>{props.translate(highlightedTint.productFamily)}</b>
        </div>
        <div>
          {props.translate(highlightedTint.code)}
        </div>
      </div>

      <DesktopScrollArea>
        <ul className='h-list'>
          <li className={cx('no-selection', !props.selectedTint && 'selected')}>
            <button
              className='DesktopTintSelector--swatchButton DesktopTintSelector--swatchButtonNone'
              type='button'
              onMouseEnter={() => { setHoveredTint(NO_TINT) }}
              onMouseLeave={() => { setHoveredTint(null) }}
              onClick={() => { props.selectTint(null) }}
            >
              <NoTintIcon />
            </button>
          </li>
          
          {props.tints
            .slice()
            .reverse()
            .map((tint) => {
              return (
                <li
                  key={tint.code}
                  className={cx(props.selectedTint === tint && 'selected')}
                >
                  <button
                    className='DesktopTintSelector--swatchButton'
                    type='button'
                    style={{ background: tint.swatchColor }}
                    onMouseEnter={() => {
                      setHoveredTint(tint)
                    }}
                    onClick={() => {
                      props.selectTint(tint)
                    }}
                  />
                </li>
              )
            })}
          </ul>
      </DesktopScrollArea>
    </DesktopAssetSelectorPanel>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      tints: selectors.getTints(state),
      selectedTint: selectors.getSelectedTint(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectTint
  }
)(DesktopTintSelector)

const NoTintIcon = () => {
  return (
    <svg height="76" width="76">
      <defs>
        <clipPath id="xxx">
          <circle fill="white" cx="38" cy="38" r="36" />
        </clipPath>
      </defs>
      <circle
        stroke="#b7b7b7"
        fill="none"
        cx="38" cy="38"
        r="36"
        strokeWidth="2"
      />
      <line
        x1="76" y1="76"
        x2="0" y2="0"
        stroke="#b7b7b7"
        strokeWidth="8"
        style={{
          clipPath: 'url(#xxx)'
        }}
      />
    </svg>
  )
}
