import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import EditorPanelState from '../../store/models/EditorPanelState'
import deselectPanelGroup from '../wraps/deselectPanelGroup'

export default (panelState:EditorPanelState) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		const state = getState()

		const currentPanelState = selectors.getEditorPanelState(state)
		if (currentPanelState === panelState) { // If we're already there, we don't need to do anything.
			return
		}

		switch(panelState) {
			case EditorPanelState.Closed:
				dispatch(deselectPanelGroup())
				break
			case EditorPanelState.SelectAccentOpen:
				const accentPanelGroup = selectors.getAccentPanelGroup(state)
				if (!accentPanelGroup) {
					console.warn('setEditorPanelState SelectAccentOpen selected, but no Accent panel group was found!')
					return
				}

				dispatch({
					type: 'SELECT_PANEL_GROUP',
					panelGroup: accentPanelGroup
				})
				break
			case EditorPanelState.SelectTintOpen:
				const tintPanelGroup = selectors.getWindowPanelGroup(state)
				if (!tintPanelGroup) {
					console.warn('openWindowTintPanel called, but no Tint panel group was found!')
					return
				}

				dispatch({
					type: 'SELECT_PANEL_GROUP',
					panelGroup: tintPanelGroup
				})
				break
		}

		dispatch({
      type: 'SET_EDITOR_PANEL_STATE',
      panelState: panelState
    })
  }
}