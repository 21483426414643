import THREE from '../../three/threeWithExtensions'
import IMaterial from '../IMaterial'
import AssetLibrary from '../../AssetLibrary'
import VoidPromise from '../../utils/VoidPromise'
import meshStandardMaterialPromiseCreator from '../../utils/MeshStandardMaterialPromiseCreator'
import Maybe from '../../Maybe'

class PhysicalBasedMaterial implements IMaterial {
	_diffuseMapURL:Maybe<string>
	_normalMapURL:Maybe<string>
	_roughnessMapURL:Maybe<string>
	_metallicMapURL:Maybe<string>
  _bumpMapURL:Maybe<string>
  _materialOptions:any
  _material:Maybe<THREE.MeshStandardMaterial> = null
	_promise:Maybe<Promise<void>> = null
	_loaded:boolean

	constructor(
		diffuseMapURL:Maybe<string>,
		normalMapURL:Maybe<string>,
		roughnessMapURL:Maybe<string>,
		metallicMapURL:Maybe<string>,
		bumpMapURL:Maybe<string>,
		materialOptions:Maybe<any>
	) {
		this._diffuseMapURL = diffuseMapURL
		this._normalMapURL = normalMapURL
		this._roughnessMapURL = roughnessMapURL
		this._metallicMapURL = metallicMapURL
    this._bumpMapURL = bumpMapURL

    this._materialOptions = materialOptions

		this._material = new THREE.MeshStandardMaterial({
			name: 'PhysicalBasedMaterial',
			color: new THREE.Color(0xffffff),
			...materialOptions
		})
		this._loaded = false
	}

	getRenderMaterial = ():THREE.Material => {
		return this._material
	}

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    this._material.map = colorMap
  }

	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		this._material.envMap = dayEnvMap
	}

	setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
		// Nothing needed here.
	}

  isLoaded = ():boolean => {
		return this._loaded
	}

  load = (basePath:string):Promise<void> => {
		if (this._promise) {
			return this._promise
		}

    const promises = []

    const materialPromise = meshStandardMaterialPromiseCreator(this._materialOptions)
    materialPromise.then( (material) => {
      this._material = material
    })
    promises.push(materialPromise)

		if (this._diffuseMapURL) {
			const diffusePromise = AssetLibrary.loadTexture(basePath + this._diffuseMapURL).then( (texture:THREE.Texture) => {
				this._material.map = texture
			})
			promises.push(diffusePromise)
		}

		if (this._normalMapURL) {
			const normalPromise = AssetLibrary.loadTexture(basePath + this._normalMapURL).then( (texture:THREE.Texture) => {
				this._material.normalMap = texture
			})
			promises.push(normalPromise)
		}

		if (this._roughnessMapURL) {
			const roughnessPromise = AssetLibrary.loadTexture(basePath + this._roughnessMapURL).then( (texture:THREE.Texture) => {
				this._material.roughnessMap = texture
			})
			promises.push(roughnessPromise)
		}

		if (this._metallicMapURL) {
			const metallicPromise = AssetLibrary.loadTexture(basePath + this._metallicMapURL).then( (texture:THREE.Texture) => {
				this._material.metalnessMap = texture
			})
			promises.push(metallicPromise)
		}

		if (this._bumpMapURL) {
			const bumpPromise = AssetLibrary.loadTexture(basePath + this._bumpMapURL).then( (texture:THREE.Texture) => {
				this._material.bumpMap = texture
			})
			promises.push(bumpPromise)
		}

		this._promise = VoidPromise.all(promises)
		this._promise.then( () => {
			this._loaded = true
			this._material.needsUpdate = true
    })
		return this._promise
	}
}

export default PhysicalBasedMaterial