import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import setLanguage from './setLanguage'
import SessionManager from '../helpers/SessionManager'

export default (language:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const instance = selectors.getLanguageObject(state)
    instance.changeLanguage(language, (error, t) => {
      if (!error) {
        dispatch(setLanguage(instance, language))
      }
    })
  }
}