import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class Interior extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "Interior",
      color: new THREE.Color('#101010'), // #5b4928
      roughness: 1.0,
      metalness: 0.0
    }), false)
	}

	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		// No environment map
	}
}

export default Interior