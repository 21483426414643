import * as React from 'react'
import HeadlineIcon from './icons/Headline'

interface OwnProps {}
interface ReduxProps {}
interface DispatchProps {}
type State = {}

class Loading extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
	render() {
		return  <div
			className="loading-modal-overlay"
			style={{
				background: 'rgba(0,0,0,0.6)',
				position: 'fixed',
				bottom: '0',
				top: '0',
				left: '0',
				right: '0',
				zIndex: 200,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<div
				className="changing-vehicle-loading-modal"
				style={{
					background: 'white',
					borderRadius: '50%',
					minWidth: '150px',
					minHeight: '150px',
					padding: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<HeadlineIcon animate={true} />
			</div>
		</div>
	}
}

export default Loading