
import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import Tint from '../../../../../../../core/tints/Tint'
import selectTint from '../../../../actionCreators/tints/selectTint'
import AppState from '../../../../store/models/AppState'
import selectors from '../../../../store/selectors'
import CancelIcon from '../../../icons/Cancel'
import TintsIcon from '../../../icons/Tints'
import MobileAssetSelectorPanel from '../MobileAssetSelectorPanel'
import MobileSelectedItemInfo from '../MobileSelectedItemInfo'

interface OwnProps { }

interface ReduxProps {
  tints: Array<Tint>
  selectedTint: Maybe<Tint>
  translate: (key:string) => string
}

interface DispatchProps {
  selectTint: (tint:Maybe<Tint>) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function MobileTintSelector(props:Props) {
  return (
    <MobileAssetSelectorPanel
      title={props.translate('editor.tints.title')}
      icon={<TintsIcon />}
      iconClassName='tints'
      footerText={props.translate('editor.tints.caption')}
    >
      <MobileSelectedItemInfo
        name={props.selectedTint ? props.translate(props.selectedTint.productFamily) : props.translate('editor.no-selection.title')}
        code={props.selectedTint ? props.selectedTint.code : props.translate('editor.no-selection.captionMobile')}
      />

      <div className='panel-group-selector--mobile--list'>
        <button
          className={cx(
            'mobile-swatch',
            !props.selectedTint && 'selected'
          )}
          onClick={() => props.selectTint(null)}
        >
          <CancelIcon />
        </button>
        {props.tints.reverse().map((tint:Tint) => (
          <button
            key={tint.code}
            className={cx(
              'mobile-swatch',
              props.selectedTint === tint && 'selected'
            )}
            style={{
              background: tint.swatchColor
            }}
            onClick={() => props.selectTint(tint)}
          />
        ))}
      </div>
    </MobileAssetSelectorPanel>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      tints: selectors.getTints(state),
      selectedTint: selectors.getSelectedTint(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectTint
  }
)(MobileTintSelector)