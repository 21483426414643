import Maybe from '../Maybe'
import UserSelections from "../UserSelections"
import Wrap from '../wraps/Wrap'
import Vehicle from './Vehicle'

class VehicleMeshVisibilityUpdater {
	configure = (basePath:string, selections:UserSelections, forceAccentsToBeVisible:boolean) => {
    const vehicle:Maybe<Vehicle> = selections.getVehicle()
    if (!vehicle) {
      return
    }

    const accentWrap:Maybe<Wrap> = selections.getAccent()
    
    vehicle.load(basePath).then( () => {
      vehicle.model.traverse( (child:any) => {
        if (child.isMesh) {
          child.visible = true
          if (vehicle.isAccentMeshName(child.name)) {
            child.visible = !!accentWrap || forceAccentsToBeVisible
          }
        }
      })
    })
	}
}

export default VehicleMeshVisibilityUpdater