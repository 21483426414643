import PhysicalBasedMaterial from './PhysicalBasedMaterial'

class PolypropylenePlastic extends PhysicalBasedMaterial {
	constructor(materialOptions?:any) {
		super(
			'assets/textures/rim/TexturesCom_Plastic_Polypropylene_512_albedo.png',
			'assets/textures/rim/TexturesCom_Plastic_Polypropylene_512_normal.png',
			'assets/textures/rim/TexturesCom_Plastic_Polypropylene_512_roughness.png',
			null,
			null,
			{
				name: 'PolypropylenePlastic',
				...materialOptions
			}
		)
	}
}

export default PolypropylenePlastic