import Wrap from '../../../../../core/wraps/Wrap'
import Dispatch from '../Dispatch'
import GetState from '../GetState'

export default (wrap:Wrap) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		dispatch({
      type: 'TEMPORARILY_SELECT_ACCENT_WRAP',
      wrap: wrap
    })
  }
}