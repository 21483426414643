
import * as React from 'react'
import { connect } from 'react-redux'
import AppState from '../../store/models/AppState'
import TranslationComponent from '../TranslationComponent'
import Modal from '../../store/models/Modal'
import closeModal from '../../actionCreators/modals/closeModal'
import cx from 'classnames'

interface OwnProps {
  className?:string
  children: any
  closeable: boolean
}

interface ReduxProps {
}

interface DispatchProps {
  closeModal: (modal:Modal|null) => void
}

interface StateProps {
}

class ModalComponent extends React.Component<OwnProps & ReduxProps & DispatchProps, StateProps> {
  _wrapper:any

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div ref={(element) => { this._wrapper = element }}
        className={cx('modal', this.props.className)}
        onClick={this._onWrapperClick}
      >
        <div className='modal-inner'>
          {this.props.closeable && (
            <button className="modal-close-btn" onClick={this._closeModal}>
              <TranslationComponent localizationKey='global.close-button' />
            </button>
          )}

          <div className='modal-content'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

  _onWrapperClick = (evt) => {
    if (!this.props.closeable) {
      return
    }

    if (evt.target === this._wrapper) {
      this._closeModal()
    }
  }

  _closeModal = () => {
    this.props.closeModal(null)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {}
  },
  {
    closeModal
  }
)(ModalComponent)