import Maybe from '../../../../../core/Maybe'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../core/wraps/Wrap'
import selectors from '../../store/selectors'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import closeModal from '../modals/closeModal'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const panelGroup:Maybe<PanelGroup> = selectors.getSelectedPanelGroup(state) // No need to null check this, since it is a nullable field. This means the "entire vehicle" is selected
    const wrap:Maybe<Wrap> = selectors.getTemporarilySelectedWrapForPanelGroup(state, panelGroup) // No need to null check this, since null is the valid value for `No Wrap`

		dispatch({ type:'SELECT_WRAP', panelGroup:panelGroup, wrap:wrap })
    dispatch(closeModal(null))
  }
}