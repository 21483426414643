import * as React from 'react'

const TRANSFORMS = {
  up: 'translate(11, 11.5) rotate(180) translate(-11, -11.5)',
  down: '',
  left: 'translate(11, 11.5) rotate(90) translate(-11, -11.5)',
  right: 'translate(11, 11) rotate(-90) translate(-12, -11)'
}

type Props = {
  width?: number
  direction?: 'up' | 'down' | 'left' | 'right'
  forceWidth?: number
  forceHeight?: number
}

const WIDTH = 21.26
const HEIGHT = 23
export const ASPECT_RATIO = HEIGHT / WIDTH

export default function(props: Props) {
  const { width = 20, direction = 'right' } = props

  let useWidth = props.forceWidth || width
  let useHeight = props.forceHeight || width * ASPECT_RATIO
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 " + WIDTH + " " + HEIGHT}
      width={useWidth}
      height={useHeight}
    >
      <path
        fill='currentColor'
        transform={TRANSFORMS[direction]}
        d="M12.63,1.53V15l5-5.22a2.16,2.16,0,0,1,3.05,3L10.63,23,.56,13a2.11,2.11,0,0,1,.1-3A2.09,2.09,0,0,1,3.61,10l5,5V1.5c0-1,1-1.5,2-1.5S12.63.56,12.63,1.53Z"
      />
    </svg>
  )
}
