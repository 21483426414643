class ViewportDetector {
  static SmallMinimum = 360
  static HandMinimum = 480
  static LapMinimum = 768
  static DesktopMinimum = 1024

  static isSmallViewport = ():boolean => {
    return window.innerWidth < ViewportDetector.HandMinimum
  }

  static isLargerThanSmallViewport = ():boolean => {
    return window.innerWidth >= ViewportDetector.SmallMinimum
  }

  static isHandViewport = ():boolean => {
    return (
      window.innerWidth >= ViewportDetector.HandMinimum
      && window.innerWidth < ViewportDetector.LapMinimum
    )
  }

  static isLapViewport = ():boolean => {
    return (
      window.innerWidth >= ViewportDetector.LapMinimum
      && window.innerWidth < ViewportDetector.DesktopMinimum
    )
  }

  static isMobile():boolean {
    return window.innerWidth < ViewportDetector.DesktopMinimum
  }

  static isDesktop():boolean {
    return !ViewportDetector.isMobile()
  }

  static isPortrait():boolean {
    return ((window.innerWidth/window.innerHeight) < 0.71)
  }
}

export default ViewportDetector