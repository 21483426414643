import * as React from 'react'
import AppState from '../store/models/AppState'
import selectors from '../store/selectors'
import { connect } from 'react-redux'

interface OwnProps {
  localizationKey:string,
  html?:boolean
  replace?:any
}

interface ReduxProps {
  translated: string
}

interface DispatchProps {
}

class TranslationComponent extends React.Component<OwnProps & ReduxProps & DispatchProps, {}> {
    render() {
      let useCopy = this.props.translated
      if (this.props.replace) {
        for (let key in this.props.replace) {
          if (this.props.replace.hasOwnProperty(key)) {
            useCopy = useCopy.replace('[' + key + ']', this.props.replace[key])
          }
        }
      }

      if (this.props.html) {
        return <span dangerouslySetInnerHTML={{ __html: useCopy }} />
      }
      return useCopy
    }
}


export default connect(
  (state: AppState, ownProps:OwnProps): ReduxProps => {
    return {
      translated: selectors.translate(state, ownProps.localizationKey)
    }
  },
  { }
)(TranslationComponent)