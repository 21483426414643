import * as React from 'react'
import { connect } from 'react-redux'
import closeModal from '../../../actionCreators/modals/closeModal'
import AppState from '../../../store/models/AppState'
import TranslationComponent from '../../TranslationComponent'
import applyTemporarilySelectedWrapAndCloseModal from '../../../actionCreators/wraps/applyTemporarilySelectedWrapAndCloseModal'
import clearTemporarilySelectedWrapAndCloseModal from '../../../actionCreators/wraps/clearTemporarilySelectedWrapAndCloseModal'

interface OwnProps { }

interface ReduxProps {
  
}

interface DispatchProps {
  cancelSelected: () => any
  confirmSelected: () => any
}

const ConfirmWrapModal = (props:OwnProps & ReduxProps & DispatchProps) => {
  return (
    <div className='ConfirmWrapModal'>
      <div className='ConfirmWrapModal--inner'>
        <div className='ConfirmWrapModal--content'>
          <h2 className='ConfirmWrapModal--title'>
            <TranslationComponent localizationKey='editor.conflicting-wrap.title' />
          </h2>

          <p className='ConfirmWrapModal--text landmarkless'>
            <TranslationComponent localizationKey='editor.conflicting-wrap.text' />
          </p>
        </div>

        <div className='ConfirmWrapModal--buttons'>
          <button 
            className='ConfirmWrapModal--cancelButton'
            onClick={() => { props.cancelSelected() }}
          >
            <TranslationComponent localizationKey='editor.conflicting-wrap.cancel' />
          </button>
          
          <button 
            className='ConfirmWrapModal--continueButton'
            onClick={() => { props.confirmSelected() }}
          >
            <TranslationComponent localizationKey='editor.conflicting-wrap.confirm' />
          </button>
        </div>
        
      </div>
    </div>
  )
}

export default connect(
  (state: AppState):ReduxProps => {
    return {
      
    }
  },
  {
    cancelSelected: clearTemporarilySelectedWrapAndCloseModal,
    confirmSelected: applyTemporarilySelectedWrapAndCloseModal
  }
)(ConfirmWrapModal)