import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'

export default (message:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		dispatch({
			type: 'SET_LOADING_MESSAGE',
			message: message
		})
  }
}