import * as React from 'react'

type SelectedItemInfoProps = {
  name: string
  code: string
}

function MobileSelectedItemInfo(props: SelectedItemInfoProps) {
  return (
    <div
      style={{
        textAlign: 'center',
        lineHeight: 1.4,
        padding: '24px 0 5px'
      }}
    >
      <div>
        <b>{props.name}</b>
      </div>
      <div>{props.code}</div>
    </div>
  )
}

export default MobileSelectedItemInfo