// Typescript sometimes gets overzealous in it's typechecking.
// Sometimes we just want to return Promise<void>, even if a
// promise does actually return things. This promise helper
// facilitates this desire.
class VoidPromise {
  static resolve = ():Promise<void> => {
    return new Promise( (resolve, reject) => {
      resolve()
    })
  }

  static all(promises:Array<Promise<any>>):Promise<void> {
    return new Promise( (resolve, reject) => {
      Promise.all(promises)
        .then( () => {
          resolve()
        })
        .catch( () => {
          reject()
        })
    })
  }

  static race(promises:Array<Promise<any>>):Promise<void> {
    return new Promise( (resolve, reject) => {
      Promise.race(promises)
        .then( () => {
          resolve()
        })
        .catch( () => {
          reject()
        })
    })
  }
}

export default VoidPromise