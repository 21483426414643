import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import PanelGroup from '../../../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../../../core/wraps/Wrap'
import selectAccentWrap from '../../../../actionCreators/accents/selectAccentWrap'
import setEditorPanelState from '../../../../actionCreators/editorPanels/setEditorPanelState'
import selectFinishGroup from '../../../../actionCreators/selectFinishGroup'
import deselectPanelGroup from '../../../../actionCreators/wraps/deselectPanelGroup'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import selectWrap from '../../../../actionCreators/wraps/selectWrap'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import selectors from '../../../../store/selectors'
import AccentsIcon from '../../../icons/Accents'
import MaterialsIcon from '../../../icons/Materials'
import PwfIcon from '../../../icons/PwfIcon'
import MobileFilmSelector from '../MobileFilmSelector'
import MobileTintSelector from '../MobileTintSelector'
import MobileWrapSelector from '../MobileWrapSelector'
import useSlideCanvasUpIf from '../useSlideCanvasUpIf'

type OwnProps = {}

type ReduxProps = {
  editorPanelState: EditorPanelState
  selectedPanelGroupWrap: Maybe<Wrap>
  selectedAccentWrap: Maybe<Wrap>
  translate: (key: string) => string
  panelGroups: Maybe<PanelGroup[]>
  selectedPanelGroup: Maybe<PanelGroup>
  wraps: Array<Wrap>
  selectedWrap: Maybe<Wrap>
}

type DispatchProps = {
  setEditorPanelState: (state:EditorPanelState) => void
  selectPanelGroup: (panelGroup:Maybe<PanelGroup>) => void
  selectWrap: (wrap:Maybe<Wrap>) => void
  selectAccentWrap: (wrap:Maybe<Wrap>) => void
  selectFinishGroup: (group:Maybe<string>) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

const tryToGetComponent = (props: Props) => {
  switch(props.editorPanelState) {
    case EditorPanelState.SelectPanelOpen:
    case EditorPanelState.SelectMaterialOpen:
      return (
        <MobileWrapSelector
          type='materials'
          title={props.translate('editor.materials.title')}
          icon={<MaterialsIcon />}
          selectedWrap={props.selectedWrap}
          onSelectWrap={props.selectWrap}
          panelGroups={props.panelGroups}
          selectPanelGroup={props.selectPanelGroup}
          selectedPanelGroup={props.selectedPanelGroup}
        />
      )
    case EditorPanelState.SelectAccentOpen:
        return (
          <MobileWrapSelector
            type='accents'
            title={props.translate('editor.accents.title')}
            icon={<AccentsIcon />}
            selectedWrap={props.selectedAccentWrap}
            onSelectWrap={props.selectAccentWrap}
          />
        )
    case EditorPanelState.SelectTintOpen:
      return <MobileTintSelector />
    case EditorPanelState.SelectPpfOpen:
      return <MobileFilmSelector />
    case EditorPanelState.SelectPwfOpen:
      return (
        <MobileWrapSelector
          type='pwf'
          title={props.translate('editor.pwf.title')}
          icon={<PwfIcon />}
          selectedWrap={props.selectedWrap}
          onSelectWrap={props.selectWrap}
          panelGroups={props.panelGroups}
          selectPanelGroup={props.selectPanelGroup}
          selectedPanelGroup={props.selectedPanelGroup}
        />
      )
  }

  return null
}

function MobileOptionsSelector(props: Props) {
  const panelIsOpen = (
    props.editorPanelState === EditorPanelState.SelectPanelOpen ||
    props.editorPanelState === EditorPanelState.SelectMaterialOpen ||
    props.editorPanelState === EditorPanelState.SelectAccentOpen ||
    props.editorPanelState === EditorPanelState.SelectTintOpen ||
    props.editorPanelState === EditorPanelState.SelectPpfOpen ||
    props.editorPanelState === EditorPanelState.SelectPwfOpen
  )
  useSlideCanvasUpIf(panelIsOpen)

  const component = tryToGetComponent(props)
  return component
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedPanelGroupWrap: selectors.getSelectedWrapForSelectedPanelGroup(
        state
      ),
      selectedAccentWrap: selectors.getSelectedAccentWrap(state),
      wraps: selectors.getWrapsInSelectedFinishGroup(state),
      selectedWrap: selectors.getSelectedWrapForSelectedPanelGroup(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectPanelGroup,
    deselectPanelGroup,
    setEditorPanelState,
    selectWrap,
    selectAccentWrap,
    selectFinishGroup
  }
)(MobileOptionsSelector)