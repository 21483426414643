import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class RedGlass extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: 'RedGlass',
      roughness: 0.25,
      transparent: true,
      opacity: 0.9,
      emissive: new THREE.Color('#ffffff')
    }), true)
	}

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    if (this._shouldUseVehicleColorMap) {
      this._material.map = colorMap
      this._material.emissiveMap = colorMap
    }
  }
}

export default RedGlass