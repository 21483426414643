import Dispatch from './Dispatch'
import GetState from './GetState'
import Modal from '../store/models/Modal'
import selectors from '../store/selectors'
import View from '../store/models/View'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const view = selectors.getCurrentView(state)
    const modals = selectors.getAllOpenModals(state)

    const desiredRenderingStatus = shouldBeActivelyRendering(view, modals)
    if (desiredRenderingStatus) {
      dispatch({ type: 'UNPAUSE_RENDERING' })
    }
    else {
      dispatch({ type: 'PAUSE_RENDERING' })
    }
  }
}

const shouldBeActivelyRendering = (view:View, modals:Array<Modal>) => {
  if (modals && modals.length > 0) { // If any modals are open, then we should not be actively rendering Three.js frames.
    return false
  }

  if (view === View.Preloading || view === View.VehicleEdit || view === View.Finale) {
    return true
  }

  return false
}