import ILoadable from "../../ILoadable"
import VoidPromise from "../../utils/VoidPromise"
import PolypropylenePlastic from './PolypropylenePlastic'
import RubberTire from './RubberTire'
import BrushedSteel from './BrushedSteel'
import Chrome from './Chrome'
import BrakeCaliper from './BrakeCaliper'
import PolypropylenePlasticRough from './PolypropylenePlasticRough'
import Polymer from './Polymer'
import Black from './Black'
import Interior from './Interior'
import ClearGlass from './Clearglass'
import GenericGlass from './GenericGlass'
import RedGlass from './RedGlass'
import OrangeGlass from './OrangeGlass'
import TintMaterial from "../tints/TintMaterial"
import SunRoof from "./SunRoof"
import HeadlightGlass from "./HeadlightGlass"
import ColorMappedGlass from "./ColorMappedGlass"
import ColorMappedMaterial from "./ColorMappedMaterial"
import Lights from "./Lights"
import LightsRed from "./LightsRed"
import LightsOrange from "./LightsOrange"
import ModernShaderMaterial from '../wraps/ModernShaderMaterial'
import BlackNoColorMap from './BlackNoColorMap'

class PartsInstances implements ILoadable {
  _isLoaded:boolean = false

  polypropylenePlastic = new PolypropylenePlastic()
  polypropylenePlasticRough = new PolypropylenePlasticRough()
  brushedSteel = new BrushedSteel()
  polymer = new Polymer()
  rubberTire = new RubberTire()
  brakeCaliper = new BrakeCaliper()
  chrome = new Chrome()
  black = new Black()
  blackNoColorMap = new BlackNoColorMap()
  lights = new Lights()
  lightsRed = new LightsRed()
  lightsOrange = new LightsOrange()
  interior = new Interior()
  clearGlass = new ClearGlass()
  genericGlass = new GenericGlass()
  colorMappedMaterial = new ColorMappedMaterial()
  colorMappedGlass = new ColorMappedGlass()
  headlightGlass = new HeadlightGlass()
  windowGlass = new TintMaterial({
    windowColor: "#cacaca",
    windowOpacity: 0.9,
    environmentContribution: 0.75,
    subtractionColor: "#ffffff"
  })
  redGlass = new RedGlass()
  orangeGlass = new OrangeGlass()
  sunroof = new SunRoof()
  primerGrey = new ModernShaderMaterial("Primer Grey", {
    defines: {
      HAS_NORMALS: true,
      HAS_BASECOLORMAP: false,
      HAS_NORMALMAP: false,
      HAS_TANGENTS: false,
      USE_IBL: false,
      USE_TEX_LOD: false,
      USE_IRIDESCENCE: false,
      USE_TWOCOLOR: false,
      USE_ANISOTROPIC: false
    },
    uniforms: {
      u_BaseColorSampler: "assets/textures/modern-shader/PaintColors.png",
      u_brdfLUT: "assets/textures/modern-shader/brdfLUT.png",
      u_NormalSampler: "assets/textures/modern-shader/PaintNormals.png",
      u_BaseColorFactor: "#ffffff",
      u_ColorBoost: 6,
      u_ambientLightColor: "#000000",
      u_NormalScale: 1,
      textureScale: 20,
      u_textureStretch: 1,
      u_Clearcoat: 0,
      u_MetallicRoughnessValuey: 1,
      u_MetallicRoughnessValuex: 0,
      u_ClearcoatRoughness: 1,
      u_EnvContrib: 0,
      u_EnvBlur: 0,
      u_ColorChangeScaling: 1,
      u_TwoColorStart: "#ff0000",
      u_TwoColorEnd: "#00ff00",
      u_IridescentStartColor: "#0000ff",
      u_IridescentScale: 5,
      u_IridescentDesaturate: 5,
      u_IridescentFalloff: 5,
      u_IridescentContrib: 1,
      u_AnisoAngle: 0
    }
  })

  load = (basePath:string) => {
    return VoidPromise.all([
      this.polypropylenePlastic.load(basePath),
      this.polypropylenePlasticRough.load(basePath),
      this.brushedSteel.load(basePath),
      this.polymer.load(basePath),
      this.rubberTire.load(basePath),
      this.brakeCaliper.load(basePath),
      this.chrome.load(basePath),
      this.black.load(basePath),
      this.blackNoColorMap.load(basePath),
      this.lights.load(basePath),
      this.lightsRed.load(basePath),
      this.lightsOrange.load(basePath),
      this.interior.load(basePath),
      this.headlightGlass.load(basePath),
      this.colorMappedMaterial.load(basePath),
      this.colorMappedGlass.load(basePath),
      this.genericGlass.load(basePath),
      this.clearGlass.load(basePath),
      this.windowGlass.load(basePath),
      this.redGlass.load(basePath),
      this.orangeGlass.load(basePath),
      this.primerGrey.load(basePath)
    ]).then( () => {
      this._isLoaded = false
    })
  }

  isLoaded = ():boolean => {
    return this._isLoaded
  }
}

const instance = new PartsInstances()
export default instance