import { Maybe } from '../Maybe'
import SharingUtility from '../SharingUtility'

export enum WrapArea {
  /**
   * Indicates a wrap is available in the general wraps section of the site
   */
  Wraps,

  /**
   * Indicates a wrap is available in the PWF section of the site
   */
  ProtectionWrapFilm
}

class WrapMetaData {
	shareIdentifier:string
  code:string
  name:string
  productFamily:string
  colorGroup:string
	finishGroup:string
	isNew:boolean
	isDefault:boolean
	sortOrder:number
  swatchColor:string
  swatchImage:string
  area:WrapArea

	constructor(
    shareIdentifier:string,
    code:string,
    name:string,
    productFamily:string,
    colorGroup:string,
    finishGroup:string,
    swatchColor:string,
    swatchImage:string,
    area: Maybe<string>
  ) {
		if (!shareIdentifier) {
      throw new Error('shareIdentifier is required')
		}
		if (shareIdentifier.length !== SharingUtility.WrapIdentifierLength) {
			throw new Error('shareIdentifier must be of length ' + SharingUtility.WrapIdentifierLength)
		}
		this.shareIdentifier = shareIdentifier

		if (!code) {
      throw new Error('wrapCode is required')
    }
		this.code = code

		if (!name) {
			throw new Error('name is required')
		}
		this.name = name

		if (!productFamily) {
			throw new Error('productFamily is required')
		}
		this.productFamily = productFamily

		if (!colorGroup) {
			throw new Error('colorGroup is required')
		}
		this.colorGroup = colorGroup

		if (!finishGroup) {
			throw new Error('finishGroup is required')
		}
		this.finishGroup = finishGroup

		if (!swatchColor) {
			throw new Error('swatchColor is required')
		}
    this.swatchColor = swatchColor
    this.swatchImage = swatchImage

    this.area = area ? parseWrapArea(area) : WrapArea.Wraps
	}
}

export default WrapMetaData

const parseWrapArea = (area:string):WrapArea => {
  if (!area) {
    return WrapArea.Wraps
  }

  switch(area.toLowerCase()) {
    case 'pwf':
      return WrapArea.ProtectionWrapFilm
    default:
      return WrapArea.Wraps
  }
}