import * as React from 'react'

const WIDTH = 94.65
const HEIGHT = 124.56
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?: number
	className?: string
	styles?: any
}

export default function Icon(props:Props) {
	const width = props.width || WIDTH
	const height = width * ASPECT_RATIO
  return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px" y="0px"
			viewBox="0 0 94.65 124.56"
			width={width}
			height={height}
			style={props.styles}
			className={props.className}
		>
			<g>
				<path fill="currentColor" d="M47.32,124.56l-1.19-0.51c-0.47-0.2-11.59-5.05-22.87-14.22C8.05,97.46,0,83.12,0,68.38V7.92l3.57,0.69
	c0.19,0.03,19.49,3.52,42.41-7.94L47.32,0l1.34,0.67c22.92,11.46,42.23,7.97,42.42,7.94l3.56-0.68l0.01,3.62v56.82
	c0,14.75-8.05,29.08-23.27,41.45c-11.27,9.16-22.4,14.01-22.87,14.22L47.32,124.56z M6,14.95v53.42c0,28.39,34.43,46.31,41.32,49.61
	c6.9-3.3,41.32-21.22,41.32-49.61V14.95c-6.86,0.59-22.8,0.63-41.32-8.26C28.8,15.58,12.87,15.54,6,14.95z"/>
				<path fill="currentColor" d="M37.75,96.53c-12.49-10.27-18.83-21.24-18.83-32.6V30.05h6v33.88c0,9.48,5.6,18.89,16.64,27.97L37.75,96.53z"/>
			</g>
		</svg>
  )
}