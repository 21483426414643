import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import SceneMode from '../../../../../../../core/SceneMode'
import PanelGroup from '../../../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../../../core/wraps/Wrap'
import applyTemporarilySelectedAccentWrap from '../../../../actionCreators/accents/applyTemporarilySelectedAccentWrap'
import selectAccentWrap from '../../../../actionCreators/accents/selectAccentWrap'
import temporarilySelectAccentWrap from '../../../../actionCreators/accents/temporarilySelectAccentWrap'
import setEditorPanelState from '../../../../actionCreators/editorPanels/setEditorPanelState'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import selectWrap from '../../../../actionCreators/wraps/selectWrap'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import selectors from '../../../../store/selectors'
import AccentsIcon from '../../../icons/Accents'
import MaterialsIcon from '../../../icons/Materials'
import PwfIcon from '../../../icons/PwfIcon'
import DesktopFilmSelector from '../DesktopFilmSelector'
import DesktopMaterialSelector from '../DesktopMaterialSelector'
import DesktopTintSelector from '../DesktopTintSelector'

interface OwnProps { }

interface ReduxProps {
  sceneMode: SceneMode
  editorPanelState: EditorPanelState
  selectedPanelGroup: Maybe<PanelGroup>
  selectedPanelGroupWrap: Maybe<Wrap>
  selectedAccentWrap: Maybe<Wrap>
  temporarilySelectedPanelGroupWrap: Maybe<Wrap>
  temporarilySelectedAccentWrap: Maybe<Wrap>
  translate: (key: string) => string
}

interface DispatchProps {
  setEditorPanelState: (state:EditorPanelState) => void
  selectPanelGroup: (panelGroup:Maybe<PanelGroup>) => void
  selectWrap: (wrap:Maybe<Wrap>) => void
  selectAccentWrap: (wrap:Maybe<Wrap>) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

class DesktopOptionsSelector extends React.Component<Props> {
  render() {
    return (
      <div className='DesktopOptionsSelector'>
        {this.props.editorPanelState === EditorPanelState.SelectMaterialOpen && (
          <DesktopMaterialSelector
            type='materials'
            title={this.props.translate('editor.materials.title')}
            icon={<MaterialsIcon className='DesktopOptionsSelector--materialsIcon' />}
            selectedWrap={this.props.selectedPanelGroupWrap}
            onWrapSelected={this.props.selectWrap}
            onBackClick={this.onBackSelected}
            showPanelGroupFilter={true}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectTintOpen && (
          <DesktopTintSelector
            onBackClick={this.onBackSelected}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectAccentOpen && (
          <DesktopMaterialSelector
            type='accents'
            title={this.props.translate('editor.accents.title')}
            icon={<AccentsIcon />}
            selectedWrap={this.props.selectedAccentWrap}
            onWrapSelected={this.props.selectAccentWrap}
            onBackClick={this.onBackSelected}
            showPanelGroupFilter={false}
          />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectPpfOpen && (
          <DesktopFilmSelector onBackClick={this.onBackSelected} />
        )}

        {this.props.editorPanelState === EditorPanelState.SelectPwfOpen && (
          <DesktopMaterialSelector
            type='pwf'
            title={this.props.translate('editor.pwf.title')}
            icon={<PwfIcon className='DesktopOptionsSelector--pwfIcon' />}
            selectedWrap={this.props.selectedPanelGroupWrap}
            onWrapSelected={this.props.selectWrap}
            onBackClick={this.onBackSelected}
            showPanelGroupFilter={true}
          />
        )}
      </div>
    )
  }

  onBackSelected = () => {
    this.props.selectPanelGroup(null)
    this.props.setEditorPanelState(EditorPanelState.Closed)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      temporarilySelectedPanelGroupWrap: selectors.getTemporarilySelectedWrapForSelectedPanelGroup(state),
      selectedPanelGroupWrap: selectors.getSelectedWrapForSelectedPanelGroup(state),
      selectedAccentWrap: selectors.getSelectedAccentWrap(state),
      temporarilySelectedAccentWrap: selectors.getTemporarilySelectedAccentWrap(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    setEditorPanelState,
    selectPanelGroup,
    selectWrap,
    selectAccentWrap,
    temporarilySelectAccentWrap,
    applyTemporarilySelectedAccentWrap
  }
)(DesktopOptionsSelector)
