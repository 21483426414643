import * as React from 'react'

const WIDTH = 43.31
const HEIGHT = 43.31
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?:number
}

export default function Email({ width=43 }:Props) {
	const height = width * ASPECT_RATIO
  return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 43.31 43.31"
			width={width} height={height}
		>
			<g>
				<path fill="currentColor" d="M21.66,0A21.66,21.66,0,1,0,43.31,21.66,21.68,21.68,0,0,0,21.66,0Zm-9,14.66h18l-9,7.22-9-7.22Zm19.5,12.5a1.51,1.51,0,0,1-1.5,1.5h-18a1.5,1.5,0,0,1-1.5-1.5v-11a.49.49,0,0,1,0-.12L21,23.94a1,1,0,0,0,1.25,0L32.14,16a.5.5,0,0,1,0,.12Z"/>
				<path fill='white' d="M22.28,23.94a1,1,0,0,1-1.25,0L11.17,16a.49.49,0,0,0,0,.12v11a1.5,1.5,0,0,0,1.5,1.5h18a1.51,1.51,0,0,0,1.5-1.5v-11a.5.5,0,0,0,0-.12Z"/>
				<path fill='white' d="M30.68,14.66h-18l9,7.22Z"/>
			</g>
		</svg>
  )
}
