// @ts-ignore For some reason, tsc can't find the `underscore` types. Probably because we're in a lifted folder.
import * as _ from 'underscore'
import AssetLibrary from "../AssetLibrary"
import Maybe from '../Maybe'
import Wrap from './Wrap'
import WrapFactory from './WrapFactory'

class WrapsConfigLoader {
	constructor() {

	}

	load = async (url:string):Promise<Maybe<Wrap[]>> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		if (!data.wraps) {
			throw new Error('Loaded JSON did not have a `wraps` element.')
		}

		let loaded:Wrap[] = []
		data.wraps.forEach( (wrapJSON:any) => {
			const wrap = WrapFactory.fromConfig(wrapJSON, data)
			if (wrap) {
				loaded.push(wrap)
			}
		})

		// Make sure all of the shareIdentifiers are unique
		loaded.forEach( (wrap:Wrap) => {
			const matches = loaded.filter( (otherWrap) => {
				return otherWrap.meta.shareIdentifier === wrap.meta.shareIdentifier
			})

			if (matches.length !== 1) {
				throw new Error('Loaded wraps JSON had multiple items with the same shareIdentifier key `' + wrap.meta.shareIdentifier + '`!')
			}
		})

    loaded = _.sortBy(loaded, (wrap:Wrap) => {
      return wrap.meta.sortOrder
    })

		return loaded
	}

}

export default WrapsConfigLoader