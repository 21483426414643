enum EditorPanelState {
	Closed,
	ChangeVehicleOpen,
	YourDesignOpen,
	SelectPanelOpen,
	SelectMaterialOpen,
	SelectAccentOpen,
	SelectTintOpen,
	SelectPpfOpen,
  SelectPwfOpen
}

export default EditorPanelState