import * as _ from 'underscore'
import PaintProtectionFilm from '../../../../../../core/films/PaintProtectionFilm'
import Maybe from '../../../../../../core/Maybe'
import THREE from '../../../../../../core/three/threeWithExtensions'
import UserSelections from '../../../../../../core/UserSelections'
import getDevicePixelRatio from '../../../../../../core/utils/getDevicePixelRatio'
import Vehicle from '../../../../../../core/vehicles/Vehicle'
import EditorPanelState from '../../../store/models/EditorPanelState'

const PPF_MATTE_MATERIAL_SETTINGS = {
  color: 0x21d5f4,
  emissive: 0x000000,
  shininess: 1,
  specular: 0xffffff,
  transparent: true,
  opacity: 0.5
}

const PPF_GLOSS_MATERIAL_SETTINGS = {
  color: 0x21d5f4,
  emissive: 0x000000,
  shininess: 80,
  specular: 0xffffff,
  transparent: true,
  opacity: 0.4
}

class HighlightController {
  _canvas:HTMLCanvasElement
  _eventElement:HTMLElement
	_scene:THREE.Scene
	_camera:THREE.Camera
	_outlinePass:any
  _raycaster:THREE.Raycaster

  _mouseDownAt:THREE.Vector2
  _lastMoveAt:THREE.Vector2

  _vehiclePpfModel:THREE.Mesh

  _ppfMaterial:THREE.MeshPhongMaterial
  _ppfScale:number
  _ppfScaleTarget:number
  _ppfAnimationCallback:Maybe<Function>
  _enabled:boolean

  _onMeshesSelected:(list:Array<THREE.Mesh>) => void

	constructor(
    canvas:HTMLCanvasElement,
    eventElement:HTMLElement,
    scene:THREE.Scene,
    camera:THREE.Camera,
    outlinePass:any,
    onMeshesSelected: (list:Array<THREE.Mesh>) => void
  ) {
    this._canvas = canvas
    this._eventElement = eventElement
		this._scene = scene
		this._camera = camera
    
    this._outlinePass = outlinePass
    this._outlinePass.edgeStrength = 5
    this._outlinePass.edgeGlow = 0.25
    this._outlinePass.edgeThickness = 0.5
    this._outlinePass.pulsePeriod = 5
    this._outlinePass.visibleEdgeColor.set('#21d5f4') // #fff000
    this._outlinePass.hiddenEdgeColor.set('#157484') // #ff8700

    this._onMeshesSelected = onMeshesSelected

    this._ppfMaterial = new THREE.MeshPhongMaterial(PPF_MATTE_MATERIAL_SETTINGS)

    this._raycaster = new THREE.Raycaster()
    this._mouseDownAt = new THREE.Vector2()
    this._lastMoveAt = new THREE.Vector2()
  }

	disable = ():void => {
    if (!this._enabled) {
      return
    }

    this._eventElement.removeEventListener('mousedown', this._onMouseDown, false)
    this._eventElement.removeEventListener('mouseup', this._onMouseUp, false)

    this._eventElement.removeEventListener('touchstart', this._onTouchStart, false)
    this._eventElement.removeEventListener('touchmove', this._onTouchMove, false)
    this._eventElement.removeEventListener('touchend', this._onTouchEnd, false)

    this._outlinePass.selectedObjects = []
    this._enabled = false
	}

	enable = ():void => {
    if (this._enabled) {
      return
    }

    this._eventElement.addEventListener('mousedown', this._onMouseDown, false)
    this._eventElement.addEventListener('mouseup', this._onMouseUp, false)

    this._eventElement.addEventListener('touchstart', this._onTouchStart, false)
    this._eventElement.addEventListener('touchmove', this._onTouchMove, false)
    this._eventElement.addEventListener('touchend', this._onTouchEnd, false)

    
    this._enabled = true

    requestAnimationFrame(this._animationLoop)
  }

  _animationLoop = () => {
    if (!this._enabled) {
      return
    }

    const difference = this._ppfScaleTarget - this._ppfScale
    if (Math.abs(difference) <= 0.00001) {
      this._ppfScale = this._ppfScaleTarget

      if (this._ppfAnimationCallback) {
        this._ppfAnimationCallback()
        this._ppfAnimationCallback = null
      }
    }
    else {
      const SLOWNESS = 25 // Higher the number, the longer the animation takes to finish.
      this._ppfScale += (difference / SLOWNESS) 
    }

    if (this._vehiclePpfModel) {
      this._vehiclePpfModel.scale.x = this._ppfScale
      this._vehiclePpfModel.scale.y = this._ppfScale
      this._vehiclePpfModel.scale.z = this._ppfScale
    }

    this._ppfMaterial.opacity = this._ppfMaterial.baseOpacity + (Math.sin(new Date().getTime() / 500) * 0.2)

    window.requestAnimationFrame(this._animationLoop)
  }

  _animatePpfScale = (fromScale:number, toScale:number, animate:boolean, callback?:Function) => {
    if (!animate) {
      this._ppfScaleTarget = this._ppfScale = toScale
      callback?.()
      return
    }
    
    this._ppfAnimationCallback = callback
    this._ppfScale = fromScale
    this._ppfScaleTarget = toScale
  }

  _updateToBaseState = () => {
    this._outlinePass.selectedObjects = []

    if (this._vehiclePpfModel) {
      this._scene.remove(this._vehiclePpfModel)
    }
  }

  configure(previousSelections:UserSelections|null, selections:UserSelections, panelState:EditorPanelState, animate:boolean) {
    this._updateToBaseState()    

    const vehicle:Maybe<Vehicle> = selections.getVehicle()
    if (!vehicle) {
      return
    }

    let meshes:THREE.Mesh[] = []
    switch(panelState) {
      case EditorPanelState.SelectAccentOpen:
        meshes = vehicle.getAccentMeshes()
        break
      case EditorPanelState.SelectTintOpen:
        meshes = vehicle.getWindowMeshes()
        break
      case EditorPanelState.SelectPpfOpen:
        const selectedPaintProtectionFilm:Maybe<PaintProtectionFilm> = selections.getPaintProtectionFilm()
        const allPpfMeshes:THREE.Mesh[] = vehicle.getAllPaintProtectionFilmMeshes()
        const ppfMeshes:THREE.Mesh[] = vehicle.getPaintProtectionFilmMeshes(selectedPaintProtectionFilm)
        allPpfMeshes.forEach( (mesh) => {
          mesh.visible = (ppfMeshes.indexOf(mesh) >= 0)
          
          const isGlossFinish = selectedPaintProtectionFilm && selectedPaintProtectionFilm.isGlossy
          this._ppfMaterial.setValues(
            isGlossFinish ? PPF_GLOSS_MATERIAL_SETTINGS : PPF_MATTE_MATERIAL_SETTINGS
          )
          this._ppfMaterial.baseOpacity = this._ppfMaterial.opacity
          mesh.material = this._ppfMaterial
        })

        this._vehiclePpfModel = vehicle.paintProtectionFilmModel
        this._scene.add(this._vehiclePpfModel)

        const previousPaintProtectionFilm = previousSelections ? previousSelections.getPaintProtectionFilm() : null
        if (
          (!previousPaintProtectionFilm && selectedPaintProtectionFilm)
          || (previousPaintProtectionFilm && selectedPaintProtectionFilm && previousPaintProtectionFilm.name !== selectedPaintProtectionFilm.name)
        ) {
          this._animatePpfScale(1.2, 1.0, true)
        }
        break
    }

    this._outlinePass.selectedObjects = meshes
  }

  deselectAll = () => {
    this._outlinePass.selectedObjects = []
  }

	_onMouseDown = (e):void => {
    this._handleDown(e.clientX, e.clientY)
  }

  _onMouseUp = (e):void => {
    const pixelRatio = getDevicePixelRatio()
    this._handleUp(e.clientX / pixelRatio, e.clientY / pixelRatio)
  }

  _onTouchStart = (e):void => {
    const firstTouch = this._getFirstTouch(e)
    if (!firstTouch) {
      return
    }

    this._handleDown(firstTouch.clientX, firstTouch.clientY)
  }

  _onTouchMove = (e):void => {
    const firstTouch = this._getFirstTouch(e)
    if (!firstTouch) {
      return
    }

    this._lastMoveAt.set(firstTouch.clientX, firstTouch.clientY)
  }

  _onTouchEnd = (e):void => {
    this._handleUp(this._lastMoveAt.x, this._lastMoveAt.y) // No pixel ratio adjustment, since _lastMoveAt already has that incorporated.
  }

  _getFirstTouch = (e):any => {
    if (!e || !e.touches) {
      return null
    }

    const touch = e.touches[0]
    return touch
  }

  _handleDown = (x:number, y:number) => {
    const pixelRatio = getDevicePixelRatio()
    this._mouseDownAt.set(x / pixelRatio, y / pixelRatio)
    this._lastMoveAt.set(x / pixelRatio, y / pixelRatio)
  }

  _handleUp = (x:number, y:number) => {
    const mouseUp = new THREE.Vector2(x, y)
    const distance = this._mouseDownAt.sub(mouseUp).length()
    if (distance < 20) {
      var mouseVector = new THREE.Vector2()
      mouseVector.x = 2 * (x / this._canvas.width) - 1
      mouseVector.y = 1 - 2 * (y / this._canvas.height)

      this._raycaster.setFromCamera(mouseVector, this._camera)
      const intersects = this._raycaster.intersectObjects(this._scene.children, true)
      if (intersects.length > 0) {
        const meshes = _.pluck(intersects, 'object') // Get the mesh
        this._onMeshesSelected(meshes)
      }
    }
  }
}

export default HighlightController
