import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class Black extends BakedMaterial {
	constructor() {
		super(new THREE.MeshBasicMaterial({
      name: 'Black',
			color: new THREE.Color('#ffffff')
    }), true)
	}
	
	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		// Nope. Not using the environment map.
	}
}

export default Black