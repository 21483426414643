import Dispatch from '../Dispatch'
import GetState from '../GetState'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		dispatch({
      type: 'SELECT_ACCENT_WRAP',
      wrap: null
    })
  }
}