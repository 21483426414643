import * as React from 'react'

// Source https://commons.wikimedia.org/wiki/File:3M_wordmark.svg

const WIDTH = 300
const HEIGHT = 157.69046
const ASPECT_RATIO = HEIGHT / WIDTH

export default function Logo({ width=300, ...props}) {
  const { ...rest } = props

  const height = width * ASPECT_RATIO

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg3400"
      viewBox="0 0 300 157.69046"
      width={width || 300}
      height={height || 157.69046}
      {...rest}
    >
      <defs id="defs3402">
        <clipPath id="clipPath3350" clipPathUnits="userSpaceOnUse">
          <path id="path3352" d="M 0,0 603,0 603,783 0,783 0,0 Z" />
        </clipPath>
      </defs>
      <g transform="translate(-322.94143,-762.82546)" id="layer1">
        <g
          id="g3346"
          transform="matrix(7.5619164,0,0,-7.5619164,48.401073,1766.4504)"
        >
          <g clipPath="url(#clipPath3350)" id="g3348">
            <g transform="translate(67.5289,132.1326)" id="g3354">
              <path
                id="path3356"
                fill="currentColor"
                stroke="none"
                fillOpacity={1}
                fillRule={'nonzero'}
                d="M 0,0 -2.83,-11.512 -5.668,0 l -8.197,0 0,-4.251 c -0.996,3.72 -4.65,4.653 -7.915,4.706 -3.981,0.052 -8.392,-1.673 -8.545,-6.937 l 5.506,0 c 0,1.589 1.455,2.499 2.829,2.476 1.687,0.014 2.463,-0.706 2.502,-1.827 -0.058,-0.973 -0.632,-1.65 -2.488,-1.65 l -1.852,0 0,-3.82 1.681,0 c 0.955,0 2.284,-0.52 2.336,-1.88 0.064,-1.621 -1.063,-2.333 -2.45,-2.347 -2.476,0.092 -3.115,1.994 -3.115,3.63 l -5.715,0 c 0.024,-1.102 -0.012,-8.285 8.945,-8.232 4.291,-0.039 7.414,1.765 8.295,4.252 l 0,-3.812 5.512,0 0,12.42 3.048,-12.42 4.925,0 3.059,12.406 0,-12.417 5.624,0 L 8.317,0 0,0 Z m -13.847,-11.485 c -0.702,1.452 -1.69,2.087 -2.246,2.288 1.167,0.519 1.778,1.223 2.246,2.626 l 0,-4.914 z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
