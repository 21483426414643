import partsInstances from '../materials/parts/PartsInstances'
import Maybe from '../Maybe'
import THREE from '../three/threeWithExtensions'
import IMaterial from './IMaterial'

class MaterialLookup {
  static tryToFindPartMaterialForMesh = (mesh:THREE.Mesh):Maybe<IMaterial> => {
    if (!mesh) {
      console.warn('MaterialLookup.tryToFindPartMaterialForMesh called, but no mesh supplied. Returning null.')
      return null
		}

		if (MaterialLookup._isBlacklisted(mesh.name)) {
			console.warn('MaterialLookup.tryToFindPartMaterialForMesh called, but mesh is blacklisted. Returning null.')
			return null
		}

		let material = MaterialLookup._tryToFindPartMaterialByMeshName(mesh.name)
		if (material) {
			return material
		}

		material = MaterialLookup._tryToFindPartMaterialByMaterialName(mesh.originalMaterialName)
		return material
	}

	static _tryToFindPartMaterialByMaterialName = (materialName:string):Maybe<IMaterial> => {
		if (!materialName) {
			return partsInstances.colorMappedMaterial
		}

		switch (materialName.toLowerCase()) {
			case 'black':
				return partsInstances.black
			case 'blackmapless':
				return partsInstances.blackNoColorMap
			case 'chrome':
				return partsInstances.chrome
			case 'headlightglass':
				return partsInstances.headlightGlass
			case 'lightsred':
				return partsInstances.lightsRed
			case 'lightsorange':
				return partsInstances.lightsOrange
			case 'lights':
				return partsInstances.lights
			case "redglass.gt":
			case 'redglass':
			case 'clearglass':
			case 'orangeglass':
				return partsInstances.redGlass
      case 'windowglass':
        return partsInstances.genericGlass
			case 'tire':
				return partsInstances.rubberTire
			case 'interior':
				return partsInstances.interior
			case 'blackinterior':
				return partsInstances.blackNoColorMap
			case 'sunroof':
				return partsInstances.sunroof
			case 'carpaint':
				return null
			default:
				// console.log('Unrecognized material name `' + materialName + '`. Using color mapped material.')
				return partsInstances.colorMappedMaterial
		}
	}

	static _isBlacklisted = (meshName:string):boolean => {
		const blacklist:string[] = [
			//"body_gt"
		]

		return (blacklist.indexOf(meshName.toLowerCase()) >= 0)
	}

	static _tryToFindPartMaterialByMeshName = (meshName:string):Maybe<IMaterial> => {
		if (!meshName) {
			return null
		}

		switch(meshName.toLowerCase()) {
			case 'interior_gt':
				return partsInstances.interior
		}
	}
}

export default MaterialLookup