import * as dat from 'dat.gui'
import AssetLibrary from '../../AssetLibrary'
import Maybe from '../../Maybe'
import THREE from '../../three/threeWithExtensions'
import { GuiFieldName, onColorChangeTHREE, prepareColorFieldTHREE } from '../../utils/onColorChange'
import VoidPromise from '../../utils/VoidPromise'
import AbstractWrapMaterial from './AbstractWrapMaterial'

class PhysicalMaterial extends AbstractWrapMaterial {
	_originalOptions:Maybe<any> = null
	_folder:Maybe<dat.GUI> = null

  constructor(options:any) {
		super()

		// Need to remove some things while we load them. This cleans up console logs.
		this._originalOptions = Object.assign({}, options)
		delete options['map']
		delete options['mapRepeat']
    delete options['normalMap']
    delete options['normalRepeat']
		delete options['normalMapRepeat']
		delete options['roughnessMap']
    delete options['roughnessMapRepeat']
    delete options['roughnessRepeat']

    let material = new THREE.MeshPhysicalMaterial({
      ...options
    })
    material.color = prepareColorFieldTHREE(material.color)

		this.setRenderMaterial(material)
	}

	load = (basePath:string):Promise<void> => {
    let promise:Maybe<Promise<void>> = this.getLoadPromise()
		if (!!promise) {
			return promise
		}

    const loadPromises = []
		if (this._originalOptions.map) {
			const diffusePromise = AssetLibrary.loadTexture(basePath+this._originalOptions.map, this._originalOptions.mapRepeat)
			diffusePromise.then( (texture:THREE.Texture) => {
        const material = this.getRenderMaterial() as THREE.MeshPhysicalMaterial
				material.map = texture
			})
			loadPromises.push(diffusePromise)
		}

		if (this._originalOptions.normalMap) {
			const normalPromise = AssetLibrary.loadTexture(basePath+this._originalOptions.normalMap, this._originalOptions.normalMapRepeat)
			normalPromise.then( (texture:THREE.Texture) => {
				const material = this.getRenderMaterial() as THREE.MeshPhysicalMaterial
				material.normalMap = texture
			})
			loadPromises.push(normalPromise)
		}

		if (this._originalOptions.roughnessMap) {
			const roughnessPromise = AssetLibrary.loadTexture(basePath+this._originalOptions.roughnessMap, this._originalOptions.roughnessMapRepeat)
			roughnessPromise.then( (texture:THREE.Texture) => {
				const material:THREE.MeshPhysicalMaterial = this.getRenderMaterial() as THREE.MeshPhysicalMaterial
				material.roughnessMap = texture
			})
			loadPromises.push(roughnessPromise)
		}

    promise = VoidPromise.all(loadPromises)
    this.setLoadPromise(promise)
    return promise
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
    const material = this.getRenderMaterial() as THREE.MeshPhysicalMaterial
		material.envMap = dayEnvMap
  }

  configureGui = (gui:dat.GUI):void => {
    this._folder = gui.addFolder('Texture Material')

    const material = this.getRenderMaterial() as THREE.MeshPhysicalMaterial
    if (material.color) {
      this._folder.addColor(material.color, GuiFieldName).name('color').onChange( onColorChangeTHREE(material.color) )
    }

    this._folder.add(material, 'clearCoat', 0, 1).step(0.01)
    this._folder.add(material, 'clearCoatRoughness', 0, 1).step(0.01)
    this._folder.add(material, 'reflectivity', 0, 1).step(0.01)
    this._folder.add(material, 'metalness', 0, 1).step(0.01)
    this._folder.add(material, 'roughness', 0, 1).step(0.01)
  }

  unloadGui = (gui:any) => {
    gui.removeFolder(this._folder)
    this._folder = null
  }
}

export default PhysicalMaterial