import * as React from 'react'

let styles = {
  stroke: {
    fill: 'none',
    stroke: 'currentColor'
  }
}

export default function(): React.ReactElement<{}> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="26"
      height="24"
    >
      <polygon
        style={styles.stroke}
        points="16.09 6.09 19 5 16.09 3.91 15 1 13.91 3.91 11 5 13.91 6.09 15 9 16.09 6.09"
      />
      <polygon
        style={styles.stroke}
        points="20.55 11.54 22 11 20.55 10.45 20 9 19.45 10.45 18 11 19.45 11.54 20 13 20.55 11.54"
      />
      <path
        style={styles.stroke}
        d="M17,15.5a8.51,8.51,0,0,1-8.13-11,8.5,8.5,0,1,0,8.26,11Z"
      />
    </svg>
  )
}
