import THREE from '../three/threeWithExtensions'

const createPromise = (options:any):Promise<THREE.ShaderMaterial> => {
  return new Promise( (resolve, reject) => {
    try {
      const material = new THREE.ShaderMaterial(options)
      resolve(material)
    }
    catch(error) {
      reject(error)
    }
  })
}

export default createPromise