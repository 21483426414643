import THREE from '../three/threeWithExtensions'
import IMaterial from '../materials/IMaterial'
import SharingUtility from '../SharingUtility'
import TintMaterial from '../materials/tints/TintMaterial'

class Tint implements IMaterial {
  shareIdentifier:string
  name:string
  code:string
  productFamily:string
  new:boolean = false
  isDefault:boolean = false
  sortOrder:number = 0
  uniforms:any
  _swatchColor:string

  _material:TintMaterial

  constructor(shareIdentifier:string, name:string, code:string, productFamily:string, swatchColor:string, uniforms:any) {
    if (!shareIdentifier) {
      throw new Error('shareIdentifier is required')
    }
    if (shareIdentifier.length !== SharingUtility.TintIdentifierLength) {
      throw new Error('shareIdentifier must have a length of ' + SharingUtility.TintIdentifierLength)
    }
    this.shareIdentifier = shareIdentifier

    if (!name) {
      throw new Error('Name is required')
    }
    this.name = name

    if (!code) {
      throw new Error('Code is required')
    }
    this.code = code

    if (!productFamily) {
      throw new Error('Product Family is required')
    }
    this.productFamily = productFamily

    this._swatchColor = swatchColor

    if (!uniforms) {
      throw new Error('Uniforms are required')
    }
    this.uniforms = uniforms

    this._material = new TintMaterial(uniforms)
  }

  getRenderMaterial = ():THREE.Material => {
    return this._material.getRenderMaterial()
  }

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    this._material.configureVehicleColorMap(colorMap)
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
    this._material.configureEnvironmentMaps(dayEnvMap, nightEnvMap)
  }

  setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
    this._material.setEnvironmentMapDaytimePercentage(daytimePercentage)
  }

  isLoading = ():boolean => {
    return this._material.isLoading()
  }

  isLoaded = ():boolean => {
    return this._material.isLoaded()
  }

  load = (basePath:string):Promise<void> => {
    return this._material.load(basePath)
  }

  get swatchColor () {
    return this._swatchColor
  }

  serialize = () => {
    return {
      shareIdentifier: this.shareIdentifier,
      name: this.name,
      code: this.code,
      productFamily: this.productFamily
    }
  }

  configureGui = (gui:dat.GUI):void => {
    this._material.configureGui(gui)
  }

  unloadGui = (gui:dat.GUI):void => {
    this._material.unloadGui(gui)
  }
}

export default Tint