import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const isConfigured = selectors.hasEloquaCookiePreferencesConfigured(state)
    if (!isConfigured) {
      console.warn('openEloquaCookiePreferences action called, but the method was not configured. Ignoring.')
      return
    }

    const windowRef = (window as any);
    windowRef.utag.gdpr.showConsentPreferences()
  }
}