import ShareURLGenerator from '../SharingUtility'
import PaintProtectionFilm from './PaintProtectionFilm'

class FilmFactory {
	static fromConfig = (json:any):PaintProtectionFilm => {
		if (!json) {
			throw new Error('Must supply a JSON configuration node!')
    }

    if (json.disabled) {
      return null
    }

    if (!json.shareIdentifier) {
      console.warn('FilmFactory - film config missing `shareIdentifier` property: `' + JSON.stringify(json) + '`.')
      return null
    }
    if (json.shareIdentifier.length !== ShareURLGenerator.PaintProtectionFilmIdentifierLength) {
      console.warn('FilmFactory - `shareIdentifier` property was not of length ' + ShareURLGenerator.PaintProtectionFilmIdentifierLength + ': `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.name) {
      console.warn('FilmFactory - film config missing `name` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.code) {
      console.warn('FilmFactory - film config missing `code` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.finish) {
      console.warn('FilmFactory - film config missing `finish` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    if (!json.icon) {
      console.warn('FilmFactory - film config missing `icon` property: `' + JSON.stringify(json) + '`.')
      return null
    }

    let film = null
    try {
      film = new PaintProtectionFilm(json.shareIdentifier ,json.name, json.code, json.finish, json.icon, !!json.isGlossy)
    }
    catch(e) {
      console.warn('FilmFactory - Unable to create PaintProtectionFilm oject from json: `' + JSON.stringify(json) + '`.')
      return null
    }

    film.new = !!json.new

    if (json.sortOrder) {
			const sortOrder = parseInt(json.sortOrder)
			if (!isNaN(sortOrder)) {
				film.sortOrder = sortOrder
			}
		}

    return film
	}
}

export default FilmFactory