import * as React from 'react'

// Source https://commons.wikimedia.org/wiki/File:3M_wordmark.svg
export default function Icon(props) {
  const { width, height, ...rest } = props

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 43.31 43.31"
      width={width || 43.31}
      height={height || 43.31}
      {...rest}
    >
      <g>
        <path fill="currentColor" d="M21.66,35.33h0a1,1,0,0,1-.84-.44C20,33.58,12.39,22,12.39,17.25a9.27,9.27,0,1,1,18.53,0c0,4.73-7.57,16.33-8.43,17.64A1,1,0,0,1,21.66,35.33Zm0-25.35a7.28,7.28,0,0,0-7.27,7.27c0,3.12,4.66,11.13,7.27,15.24,2.6-4.11,7.26-12.12,7.26-15.24A7.27,7.27,0,0,0,21.66,10Z"/>
        <path fill="currentColor" d="M21.66,21.55a4.31,4.31,0,1,1,4.3-4.3A4.31,4.31,0,0,1,21.66,21.55Zm0-6.61A2.31,2.31,0,1,0,24,17.25,2.3,2.3,0,0,0,21.66,14.94Z"/>
      </g>
    </svg>
  )
}
