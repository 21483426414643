import PhysicalBasedMaterial from './PhysicalBasedMaterial'

/// https://www.textures.com/download/pbr0147/133182
class Polymer extends PhysicalBasedMaterial {
	constructor(materialOptions?:any) {
		super(
			'assets/textures/polymer-gun-grip/TexturesCom_Plastic_Polymer_512_albedo.png',
			'assets/textures/polymer-gun-grip/TexturesCom_Plastic_Polymer_512_normal.png',
			'assets/textures/polymer-gun-grip/TexturesCom_Plastic_Polymer_512_roughness.png',
			null,
			'assets/textures/polymer-gun-grip/TexturesCom_Plastic_Polymer_512_height.png',
			{
				name: 'Polymer',
				...materialOptions
			}
		)
	}
}

export default Polymer