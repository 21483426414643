import * as React from 'react'
import cx from 'classnames'

const styles = {
  cls1: {
    fill: 'none',
    strokeLineCap: 'round',
    strokeLineJoin: 'round',
    strokeWidth: 18,
    stroke: 'currentColor'
  },
  cls2: {
    fill: 'currentColor',
    strokeMiterlimit: 10,
    stroke: 'currentColor'
  }
}

interface Props {
  className?: string
}

export default class PwfIcon extends React.Component<Props, {}> {
  render() {
    return (
      <svg
        className={cx('PwfIcon', this.props.className)}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 523.26 461.52'
        width='54'
        height='54'
        style={{ maxWidth: '100%' }}
      >
        <g id='Layer_1'>
          <g>
            <path style={styles.cls1} d='M173.26,443.26h.08c.24.79.48,1.59.72,2.38,156.48,3.97,184.13.72,236.5-69.14,52.37-69.93,97.91-130.73,97.91-130.73-38.62-28.93-63.26-64.13-77.4-89.33'/>
            <path style={styles.cls1} d='M313.36,58.94c-12-.72-23.44-2.15-34.25-4.13'/>
            <path style={styles.cls1} d='M329.97,75.79c14.46,13.51,35.21,30.2,61.67,44.66,8.74,4.85,18.04,9.38,27.97,13.51,5.4,2.23,11.05,4.37,16.85,6.36,0,0-1.99,5.88-5.4,16.13-9.06,26.46-28.05,82.17-48.95,143.6-29.01,85.11-54.99,97.35-208.77,143.21h-.08c-.4.16-.79.24-1.19.4-.08,0-.08-.08-.16-.08'/>
            <path style={styles.cls1} d='M329.97,59.42v174.2c0,92.82-21.46,113.48-156.64,209.64h-.08c-.32.24-.56.4-.87.64-.08-.08-.24-.16-.32-.24-.08,0-.08-.08-.16-.08C36.17,347.11,14.79,326.6,14.79,233.62V59.42c91.07,0,157.59-44.66,157.59-44.66,0,0,42.75,28.77,106.73,40.05,10.81,1.99,22.25,3.42,34.25,4.13,5.4.32,10.97.48,16.61.48Z'/>
            <path style={styles.cls2} d='M191.03,366.83c0,10.3-8.35,18.64-18.64,18.64s-18.64-8.35-18.64-18.64,8.35-18.64,18.64-18.64,18.64,8.36,18.64,18.64Z'/>
          </g>
        </g>
      </svg>
    )
  }
}
