import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectors from '../../store/selectors'
import setEditorPanelState from '../editorPanels/setEditorPanelState'
import EditorPanelState from '../../store/models/EditorPanelState'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const temporaryAccentWrap = selectors.getTemporarilySelectedAccentWrap(state) // No need to null check this, as null is the valid value for `No Accent`

    dispatch({
      type: 'SELECT_ACCENT_WRAP',
      wrap: temporaryAccentWrap
    })
    dispatch(setEditorPanelState(EditorPanelState.Closed))
  }
}