import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import EnvironmentConfig from '../../../../../core/EnvironmentConfig'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import selectTemporaryVehicle from '../../actionCreators/selectTemporaryVehicle'
import temporarilySelectVehicle from '../../actionCreators/temporarilySelectVehicle'
import Exhaust from '../../components/fx/Exhaust'
import HeadlineDark from '../../components/icons/HeadlineDark'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import Flickity from "../FlickityComponent"
import TranslationComponent from '../TranslationComponent'
import BaseView from './BaseView'


interface OwnProps { }
interface ReduxProps {
  environmentConfig:EnvironmentConfig,
  vehicles: Array<Vehicle>,
  temporaryVehicle: Vehicle,
  isLoading: boolean,
  isDesktop: boolean,
  translate: (key: string) => string
}
interface DispatchProps {
  temporarilySelectVehicle: (vehicle:Vehicle) => Promise<void>
  selectTemporaryVehicle: () => Promise<void>
}

interface State {
  cssClass: string,
  suppressHovers: boolean
}

class VehicleSelectionView extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  _delayTimeout
  _viewportInterval

  constructor(props) {
    super(props)
    this.state = {
      cssClass: '',
      suppressHovers: false
    }
  }

  componentDidMount() {
    this._delayTimeout = setTimeout(() => { // Give the images a little bit of time to load so that they don't snap in.
      this.setState({ cssClass: 'delay-complete'})
    }, 500)

    this._viewportInterval = setInterval(this.checkViewportRatio, 250)

    this.checkViewportRatio()
    window.addEventListener('resize', this.checkViewportRatio, true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkViewportRatio, true)
    clearTimeout(this._delayTimeout)
    clearInterval(this._viewportInterval)
  }

  checkViewportRatio = () => {
    const ratio = window.innerWidth / window.innerHeight
    const suppressHovers = ratio > 1.9 // When the ratio is larger than this, the sports car title can collide with the main site logo. So we hide it.
    this.setState({ suppressHovers:suppressHovers })
  }

  render() {
    const listItems = this.props.vehicles.map( (vehicle, index) => {
      const headlightsImageURL = this.props.isDesktop ? vehicle.highlightImageURL : vehicle.highlightImageMobileURL
      return <div
        key={index}
        className={cx('carousel-cell', vehicle === this.props.temporaryVehicle && 'is-selected')}
        onClick={() => {
          this.props.temporarilySelectVehicle(vehicle)
        }}
      >
        {!this.state.suppressHovers &&
          <div className="car-name">
            <TranslationComponent localizationKey={vehicle.nameLocalizationKey} />
          </div>
        }
        <div className={cx('car-image', vehicle.selectCssClassName)}>
          <span>
            <img src={this.props.environmentConfig.getAssetFullPath(vehicle.selectImageMobileURL)} className="hidden-desk-and-up" />
            <img src={this.props.environmentConfig.getAssetFullPath(vehicle.selectImageURL)} className="hidden-below-desk" />
          </span>
          <Exhaust/>
          <Exhaust/>
          <div
            className="headlights"
            style={{ backgroundImage: 'url("' + this.props.environmentConfig.getAssetFullPath(headlightsImageURL) + '")' }}
          ></div>
        </div>
        <div>
          <em><TranslationComponent localizationKey={vehicle.captionLocalizationKey} /></em>
        </div>
      </div>
    })

    const selectedIndex = this.props.vehicles.indexOf(this.props.temporaryVehicle)
    return (
      <BaseView className={cx('view-select-vehicle', this.state.cssClass)}>
        <h1 className="headline text-center landmarkless">
          <HeadlineDark />
          <strong>
            <TranslationComponent localizationKey='global.app-title-bold' />
          </strong>&nbsp;
            <TranslationComponent localizationKey='global.app-title-regular' />
        </h1>

        <div className="selection-list">
          {this.props.isDesktop && listItems}

          {!this.props.isDesktop && <Flickity
            slideIndex={selectedIndex}
            onSlideIndexChange={(index) => {
              const vehicle = this.props.vehicles[index]
              this.props.temporarilySelectVehicle(vehicle)
            }}
            >{listItems}</Flickity>}
        </div>

        <div className="button-row">
          <div className="text-center">
            <button className={cx('start-button', '3m_start-button', this.props.translate('vehicle-selection.start').length > 5 && 'start-button--larger')} onClick={this.props.selectTemporaryVehicle} disabled={!this.props.temporaryVehicle || this.props.isLoading}>
              <span><TranslationComponent localizationKey='vehicle-selection.start' /></span>
            </button>
          </div>
        </div>
      </BaseView>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state),
      vehicles: selectors.getVehicles(state),
      temporaryVehicle: selectors.getTemporarilySelectedVehicle(state),
      isLoading: selectors.isSelectedVehicleLoading(state),
      isDesktop: selectors.isDesktopViewport(state),
      translate: selectors.getTranslator(state)
    }
  },
  { temporarilySelectVehicle, selectTemporaryVehicle }
)(VehicleSelectionView)