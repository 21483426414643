import selectors from '../store/selectors'
import Dispatch from './Dispatch'
import GetState from './GetState'
import Size from '../../../../core/utils/Size'
const innerHeight = require('ios-inner-height')

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    const newValue = new Size(
      window.innerWidth, 
      innerHeight()
    )
    const existingValue = selectors.getViewportSize(state)
    if (existingValue.equals(newValue)) {
      return // No need to do anything if the value is already set
    }

    dispatch({
      type: 'SET_VIEWPORT_SIZE',
      size: newValue
    })
  }
}