/*
  Oh man. So this Tealium window makes some changes to the DOM, but doesn't clean
  up after itself. So we watch for some things and then do our own cleanup to fix
  it.
*/
export default class TealiumModalMonitor {
  static _interval:NodeJS.Timeout

  static start = () => {
    TealiumModalMonitor._interval = setInterval(TealiumModalMonitor._checkForModal, 500)
  }

  static stop = () => {
    clearInterval(TealiumModalMonitor._interval)
  }

  static _checkForModal = () => {
    const modal = document.getElementById('__tealiumGDPRecModal')
    if (modal) {
      const close = modal.getElementsByClassName('js-closeLink')
      const submit = modal.getElementsByClassName('MMM--btn')
      
      if (close.length > 0 && submit.length > 0) {
        clearInterval(TealiumModalMonitor._interval)

        close[0].addEventListener('click', TealiumModalMonitor._onModalFinished)
        submit[0].addEventListener('click', TealiumModalMonitor._onModalFinished)
      }
    }
  }

  static _onModalFinished = () => {
    const root = document.getElementById('root')
    if (!root) {
      console.warn('TealiumModalMonitor onModalFinished called, but no root element found!')
      return
    }

    root.className = root.className.replace('MMM--backdrop', '').replace('MMM--isActive', '')
  }
}