import PhysicalBasedMaterial from './PhysicalBasedMaterial'
import THREE from '../../three/threeWithExtensions';

class BrushedSteel extends PhysicalBasedMaterial {
	constructor(materialOptions?:any) {
		super(
			'assets/textures/brushed-steel/TexturesCom_Metal_SteelBrushed_512_albedo.png',
			'assets/textures/brushed-steel/TexturesCom_Metal_SteelBrushed_1K_normal.png',
			'assets/textures/brushed-steel/TexturesCom_Metal_SteelBrushed_512_roughness.png',
			'assets/textures/brushed-steel/TexturesCom_Metal_SteelBrushed_512_metallic.png',
			null,
			{
				name: 'BrushedSteel',
				...materialOptions
			}
		)
	}
}

export default BrushedSteel