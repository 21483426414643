import * as React from 'react'
import cx from 'classnames'
import { animated, useSpring, config as springConfig } from 'react-spring'
import { remap } from '../../helpers/math'
import SceneMode from '../../../../../core/SceneMode'

interface Props {
  className?: string
  sceneMode: SceneMode
  icon: any
  onClick: (MouseEvent) => void
  desktop?: boolean
  forceHover?: boolean
}

const OptionCategoryButton = React.forwardRef(
  (props:Props, ref) => {
    const {
      desktop = true,
      forceHover = false
    } = props
    
    let size = desktop ? 110 : 70
    let [_hovered, setHovered] = React.useState(false)
    let hovered = forceHover || _hovered
    let hoverSpring = useSpring({
      v: hovered ? 1 : 0,
      config: springConfig.stiff
    })

    return (
      <div
        className={cx('option-category-button', props.className)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <button
          className="no-focus"
          onClick={props.onClick}
        >
          <svg width={size} height={size} ref={ref as any}>
            <animated.circle
              cx={size / 2}
              cy={size / 2}
              r={hoverSpring.v.to((v:number) =>
                remap(0, 1, size / 3, size / 2 - 3, v)
              )}
              fillOpacity={hoverSpring.v}
              fill="white"
              stroke="none"
            />
            <circle
              cx={size / 2}
              cy={size / 2}
              r={size / 2 - 3}
              fill="none"
              stroke={props.sceneMode === SceneMode.Nighttime ? 'white' : 'black' }
              strokeWidth="1"
            />
            <circle
              cx={size / 2}
              cy={size / 2}
              r={size / 2 - 11}
              fill="none"
              stroke="red"
              strokeWidth="2"
            />
          </svg>
          <div
            className={cx('option-category-button--icon-wrapper')}
            style={{
              transform: `translate(-50%, -50%) scale(${desktop ? 0.8 : 0.55})`,
              color: (hovered || props.sceneMode === SceneMode.Daytime) ? 'black' : 'white'
            }}
          >
            {props.icon}
          </div>
        </button>
      </div>
    )
  }
)

export default OptionCategoryButton