import * as core from 'core-js'
import * as ES6Promise from 'es6-promise'
import prepend from './prependPolyfill'

export default {
  apply: ():void => {
    ES6Promise.polyfill() // <enable Promise globally> So that we don't have to import everywhere.
    prepend.apply()

    console.log('core.js version=' + core.version)
  }
}