import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import LogoIcon from '../../components/icons/Logo'
import HeadlineIcon from '../../components/icons/Headline'
import TranslationComponent from '../TranslationComponent'
import BaseView from './BaseView'

interface OwnProps { 

}
interface ReduxProps {
  isPreloading: boolean,
  loadingMessage: string,
  loadingError: string
}
interface DispatchProps { }

interface State {
  cssClass: string
}

class PreloadingView extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      cssClass: ''
    }
  }

  componentDidUpdate = (prevProps, prevState, prevContext) => {
    if (!prevProps.isPreloading && this.props.isPreloading) {
      this.setState({ cssClass: 'loading' })
    }
    else if (prevProps.isPreloading && !this.props.isPreloading) {  
      this.setState({ cssClass: '' })
    }
  }

  render() {
    const message = this.props.loadingError || this.props.loadingMessage
    return (
      <BaseView className={cx('view-preload', this.state.cssClass)}>
        <div className="intro-logo">
          <LogoIcon />
        </div>
        <div className="intro-headline">
          <h1 className="landmarkless">
            <HeadlineIcon animate={!this.props.loadingError} />
            <strong><TranslationComponent localizationKey='global.app-title-bold' /></strong>&nbsp;
            <TranslationComponent localizationKey='global.app-title-regular' />
          </h1>
        </div>
        <p className={cx("intro-caption", "text-center", this.props.loadingError && "intro-caption--error")}>
          {message /* This value is already translated, so no need to translate it here */}
        </p>
      </BaseView>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      isPreloading: selectors.isPreloading(state),
      loadingMessage: selectors.getLoadingMessage(state),
      loadingError: selectors.getLoadingErrorMessage(state)
    }
  },
  {

  }
)(PreloadingView)