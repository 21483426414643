import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import { Maybe } from '../../../../../core/Maybe'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'
import Tint from '../../../../../core/tints/Tint'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../core/wraps/Wrap'
import { WrapArea } from '../../../../../core/wraps/WrapMetaData'
import removeAccentWrap from '../../actionCreators/accents/removeAccentWrap'
import setEditorPanelState from '../../actionCreators/editorPanels/setEditorPanelState'
import removePaintProtectionFilm from '../../actionCreators/films/removePaintProtectionFilm'
import openPanelGroup, { OpenPanelGroupFunction } from '../../actionCreators/openPanelGroup'
import removeAllVehicleSelections from '../../actionCreators/removeAllVehicleSelections'
import selectWrapForPanelGroup from '../../actionCreators/selectWrapForPanelGroup'
import removeTint from '../../actionCreators/tints/removeTint'
import ShieldFactory from '../../components/icons/shields/ShieldFactory'
import { remap } from '../../helpers/math'
import AppState from '../../store/models/AppState'
import EditorPanelState from '../../store/models/EditorPanelState'
import selectors from '../../store/selectors'
import TranslationComponent from '../TranslationComponent'
import ArrowIcon from '../icons/Arrow'
import CancelIcon from '../icons/Cancel'
import EditIcon from '../icons/Edit'
import PwfIcon from '../icons/PwfIcon'
import Scrim from './Scrim'

interface OwnProps {}

interface ReduxProps {
  desktop: boolean
  editorState: EditorPanelState
  selectedTint: Maybe<Tint>
  selectedAccent: Maybe<Wrap>
  panelGroups: PanelGroup[]
  selectedPaintProtectionFilm: Maybe<PaintProtectionFilm>
  getSelectedWrapForPanelGroup: (panelGroup: PanelGroup) => Maybe<Wrap>
  translate: (key: string) => string
}

interface DispatchProps {
  setEditorPanelState: (panelState: EditorPanelState) => void
  removeTint: () => void
  removeAccentWrap: () => void
  selectWrapForPanelGroup: (panelGroup:PanelGroup, wrap:Maybe<Wrap>) => void
  openPanelGroup: OpenPanelGroupFunction
  removeAllVehicleSelections: () => void
  removePaintProtectionFilm: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function YourDesignSelector(props: Props) {
  let isOpen = props.editorState === EditorPanelState.YourDesignOpen
  let openSpring = useSpring({ value: isOpen ? 1 : 0 })

  let designItems = designItemsFromProps(props)

  return (
    <React.Fragment>
      <Scrim
        show={isOpen}
        openSpring={openSpring.value}
        onClick={() => {
          props.setEditorPanelState(EditorPanelState.Closed)
        }}
      />

      <animated.div
        className='your-design'
        style={{
          pointerEvents: isOpen ? 'all' : 'none',
          opacity: openSpring.value.to((v:number) =>
            remap(0.5, 1, 0, 1, v)
          ),
          transform: openSpring.value.to(
            (v:number) => `translate(${remap(0.5, 1, 10, 0, v)}%, -50%)`
          )
        }}
      >
        <button 
          className='your-design--back' 
          onClick={() => { props.setEditorPanelState(EditorPanelState.Closed) }}
        >
          <ArrowIcon direction='left' width={30} />
        </button>

        {designItems.length <= 0 && (
          <div className='no-selections'>
            <TranslationComponent localizationKey='editor.no-selections' />
          </div>
        )}
        
        {designItems.length > 0 && (
          <React.Fragment>
            <ul className='v-list'>
              {designItems.map((props, index) => (
                <DesignItem key={index} {...props} />
              ))}
            </ul>
            <button
              onClick={props.removeAllVehicleSelections}
              className='red-on-hover clear-all'
            >
              <TranslationComponent localizationKey={'editor.clear-all'} />
            </button>
          </React.Fragment>
        )}
      </animated.div>

      <svg className='scrim-circle scrim-circle--right'>
        <animated.circle
          cx='100%'
          cy={window.innerHeight / 2}
          r={openSpring.value.to((v:number) => v * 768)}
          fill='white'
        />
        <animated.circle
          cx='100%'
          cy={window.innerHeight / 2}
          r={openSpring.value.to((v:number) => v * 746)}
          fill='none'
          stroke='#ddd'
        />
      </svg>
    </React.Fragment>
  )
}

function Swatch(props: { color: string; finishGroup: string }) {
  return (
    <div
      className={cx('yd-swatch', 'image-' + props.finishGroup)}
      style={{
        backgroundColor: props.color
      }}
    />
  )
}

type DesignItemProps = {
  name: string
  groupName: string
  groupKey: string
  swatch?: {
    color: string,
    finishGroup: string
  },
  icon?: any,
  onEditClick: () => void
  onRemoveClick: () => void
  desktop: boolean
}
function DesignItem(props: DesignItemProps) {
  return (
    <li className='your-design--item'>
      {(props.swatch && !props.icon) && (
        <Swatch color={props.swatch.color} finishGroup={props.swatch.finishGroup} />
      )}

      {props.icon && (
        <div
          className='yd-icon'
          style={{
            backgroundColor: props.swatch ? props.swatch.color : undefined
          }}
        >
          {props.icon}
        </div>
      )}

      <div className={cx('your-design--item-info', props.groupKey)}>
        <div className='your-design--item-info__group'>{props.groupName}</div>
        <div className='your-design--item-info__name' title={props.name}>
          {props.name}
        </div>
      </div>

      <div className='your-design--item-actions'>
        <button onClick={props.onRemoveClick}>
          <CancelIcon />
        </button>
        <button
          onClick={props.onEditClick}
          className='pencil'
        >
          <EditIcon />
        </button>
      </div>
    </li>
  )
}

function designItemsFromProps(props: Props) {
  let designItems: Array<DesignItemProps> = []
  props.panelGroups.forEach((panelGroup) => {
    const wrap = props.getSelectedWrapForPanelGroup(panelGroup)
    if (!wrap) {
      return
    }

    designItems.push({
      swatch: {
        color: wrap.meta.swatchColor,
        finishGroup: props.translate(wrap.meta.finishGroup)
      },
      groupName: props.translate(panelGroup.localizationKey),
      groupKey: panelGroup.key+'-group',
      icon: wrap.meta.area === WrapArea.ProtectionWrapFilm ? <PwfIcon /> : undefined,
      name: wrap.meta.code + ' ' + props.translate(wrap.meta.name),
      onEditClick: () => {
        props.openPanelGroup(
          panelGroup,
          wrap.meta.area === WrapArea.ProtectionWrapFilm ? EditorPanelState.SelectPwfOpen : EditorPanelState.SelectMaterialOpen
        )
      },
      onRemoveClick: () => {
        props.selectWrapForPanelGroup(panelGroup, null)
      },
      desktop: props.desktop
    })
  })

  if (props.selectedTint) {
    designItems.push({
      swatch: {
        color: props.selectedTint.swatchColor,
        finishGroup: 'Gloss'
      },
      groupName: props.translate('editor.tints.title-singular'),
      groupKey: 'window-group',
      name: props.selectedTint.code + ' ' + props.translate(props.selectedTint.name),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectTintOpen)
      },
      onRemoveClick: props.removeTint,
      desktop: props.desktop
    })
  }
  
  if (props.selectedAccent) {
    designItems.push({
      swatch: {
        color: props.selectedAccent.meta.swatchColor,
        finishGroup: props.selectedAccent.meta.finishGroup,
      },
      groupName: props.translate('editor.accents.title-singular'),
      groupKey: 'accent-group',
      name: props.selectedAccent.meta.code + ' ' + props.translate(props.selectedAccent.meta.name),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectAccentOpen)
      },
      onRemoveClick: props.removeAccentWrap,
      desktop: props.desktop
    })
  }

  if (props.selectedPaintProtectionFilm) {
    designItems.push({
      icon: ShieldFactory.tryToGetIcon(props.selectedPaintProtectionFilm.icon, { width: 30 }),
      groupName: props.translate('editor.films.title'),
      groupKey: 'films',
      name: props.translate(props.selectedPaintProtectionFilm.name) + ' ' + props.translate(props.selectedPaintProtectionFilm.finish),
      onEditClick: () => {
        props.setEditorPanelState(EditorPanelState.SelectPpfOpen)
      },
      onRemoveClick: props.removePaintProtectionFilm,
      desktop: props.desktop
    })
  }

  return designItems
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      desktop: selectors.isDesktopViewport(state),
      editorState: selectors.getEditorPanelState(state),
      selectedTint: selectors.getSelectedTint(state),
      selectedAccent: selectors.getSelectedAccentWrap(state),
      selectedPaintProtectionFilm: selectors.getSelectedPaintProtectionFilm(state),
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      getSelectedWrapForPanelGroup: (panelGroup: PanelGroup):Maybe<Wrap> => {
        return selectors.getSelectedWrapForPanelGroup(state, panelGroup)
      },
      translate: selectors.getTranslator(state)
    }
  },
  {
    setEditorPanelState,
    removeTint,
    removeAccentWrap,
    selectWrapForPanelGroup,
    openPanelGroup,
    removeAllVehicleSelections,
    removePaintProtectionFilm
  }
)(YourDesignSelector)
