import * as _ from 'underscore'
import AssetLibrary from '../AssetLibrary'
import PaintProtectionFilm from './PaintProtectionFilm'
import FilmFactory from './FilmFactory'

class FilmsConfigLoader {
	load = async (url:string):Promise<Array<PaintProtectionFilm>> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		if (!data.films) {
			throw new Error('Loaded JSON did not have a `films` element.')
		}

		let loaded = []
		data.films.forEach( (json) => {
			const film = FilmFactory.fromConfig(json)
			if (film) {
				loaded.push(film)
			}
		})

		// Make sure all of the shareIdentifiers are unique
		loaded.forEach( (film) => {
			const matches = loaded.filter( (otherFilm) => {
				return otherFilm.shareIdentifier === film.shareIdentifier
			})

			if (matches.length !== 1) {
				throw new Error('Loaded films JSON had multiple items with the same shareIdentifier key `' + film.shareIdentifier + '`!')
			}
		})

		loaded = _.sortBy(loaded, 'sortOrder')

		return loaded
	}
}

export default FilmsConfigLoader