import Dispatch from './Dispatch'
import GetState from './GetState'
import SessionManager from '../helpers/SessionManager'
import resetExperience from './resetExperience'
import showLanguageSelectionIfNecessary from './showLanguageSelectionIfNecessary'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    await dispatch(resetExperience())
    SessionManager.clearSavedLanguageSelection()
    setTimeout(() => dispatch(showLanguageSelectionIfNecessary()), 750) // let VehicleSelection view load before language modal appears
  }
}