import Dispatch from './Dispatch'
import GetState from './GetState'
import updateRenderingStatus from './updateRenderingStatus'
import selectors from '../store/selectors'
import closeAllModals from './modals/closeAllModals'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    if (!selectors.canGoBack(state)) {
      console.warn('navigateBack called, but no view to go back to! Ignoring.')
      return
    }

    const backView = selectors.getBackView(state)
    if (!backView) {
      console.warn('navigateBack called and allowed, but no view was found! This should never happen. Figure out why.')
      return
    }

    dispatch(closeAllModals())
    dispatch({ type: 'BACK' })

    // Need to remember to update the rendering status! If the view changed, we may need to pause/unpause rendering!
    dispatch(updateRenderingStatus())
  }
}