class PlatformDetector {
  static isIOS = ():boolean => {
    return PlatformDetector.isIPad() || PlatformDetector.isIPhone()
  }

  static isIPad = ():boolean => {
    if (window.navigator && window.navigator.platform === 'iPad') {
      return true
    }

    return false
  }

  static isIPhone = ():boolean => {
    if (window.navigator && window.navigator.platform === 'iPhone') {
      return true
    }

    return false
  }
}

export default PlatformDetector