import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import PanelGroup from '../../../../../../../core/vehicles/PanelGroup'
import setEditorPanelState from '../../../../actionCreators/editorPanels/setEditorPanelState'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import ArrowIcon from '../../../icons/Arrow'
import DesktopFilterMenu from '../DesktopFilterMenu'

export interface HeaderSelectionOptions {
  title: string
  values: string[]
  selectedValue: Maybe<string>
  onHeaderOptionSelected: (option:Maybe<string>) => any
}

interface OwnProps {
  className?: string
  title: string
  titleIcon: any // Sorry. Some react component class Couldn't figure out how to type this.
  headerOptions?: HeaderSelectionOptions[]
  bodyStyles?:any
  bodyClassName?: string
  footerText?:string
  children: any
}

interface ReduxProps {}

interface DispatchProps {
  setEditorPanelState: (state:EditorPanelState) => any
  selectPanelGroup: (panelGroup:Maybe<PanelGroup>) => any
}

class DesktopAssetSelectorPanel extends React.Component<OwnProps & ReduxProps & DispatchProps> {
  render() {
    return (
      <div className={cx('DesktopAssetSelectorPanel', this.props.className, 'panel-group-selector')}>
        <button className='panel-group-selector__back' onClick={this.onBackSelected}>
          <ArrowIcon direction='left' width={44} />
        </button>

        <div className='panel-group-selector__title'>
          {this.props.titleIcon}
          {this.props.title}
        </div>

        <div className='panel-group-selector__header'>
          {this.props.headerOptions && this.props.headerOptions.map(
            (headerOption:HeaderSelectionOptions, index:number) => {
              return (
                <DesktopFilterMenu
                  key={index}
                  allLabel={headerOption.title}
                  items={headerOption.values.map((value) => {
                    return { label: value, value: value }
                  })}
                  selected={headerOption.selectedValue}
                  onSelect={(value) => {
                    headerOption.onHeaderOptionSelected(value)
                  }}
                  right={(this.props.headerOptions!.length - index - 1) * 200}
                />
              )
            }
          )}
        </div>
        
        <div
          className={cx(this.props.bodyClassName, 'body')}
          style={{...this.props.bodyStyles}}
        >
          {this.props.children}
        </div>
        {this.props.footerText && (
          <p className='panel-group-selector__footer'>
            {this.props.footerText}
          </p>
        )}
      </div>
    )
  }

  onBackSelected = () => {
    this.props.selectPanelGroup(null) // Yeah, this is a little strange to have in here, since it's not generic. But It's better than prop-barfing callbacks through the chain.
    this.props.setEditorPanelState(EditorPanelState.Closed)
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return { }
  },
  { 
    setEditorPanelState,
    selectPanelGroup
  }
)(DesktopAssetSelectorPanel)