
import * as React from 'react'

function useSlideCanvasUpIf(up: boolean) {
  React.useEffect(() => {
    document.getElementById('canvas').style.transition =
      'transform .3s ease-out'

      return () => { // Component will unmount
        document.getElementById('canvas').style.transition = ''
      }
  }, [
    /* only run once */
  ])

  React.useEffect(() => {
    document.getElementById('canvas').style.transform = up
      ? 'translateY(-12%)'
      : 'none'

    return () => { // Component will unmount
      document.getElementById('canvas').style.transform = 'none'
    }
  }, [up])
}

export default useSlideCanvasUpIf