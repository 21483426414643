import * as React from 'react'

let styles = {
  path: {
    fill: 'none',
    stroke: 'currentColor'
    // FIXME: these were giving me typescript errors…
    // strokeLinecap: 'round',
    // strokeLinejoin: 'round'
  }
}

export default function(): React.ReactElement<{}> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.05 11.74"
      width="52"
      height="24"
    >
      <path
        style={styles.path}
        d="M14.82,4.08H4.08C.5,4.08.5.5.5.5H17.05s8.5,0,8.5,10.74"
      />
      <path style={styles.path} d="M5,4.08S5,7.66,8.55,7.66h9" />
      <path style={styles.path} d="M9.45,7.66s0,3.58,3.58,3.58h7.6" />
    </svg>
  )
}
