import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class Lights extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: 'Lights',
			color: new THREE.Color('#ffffff'),
			emissive: new THREE.Color('#ffffff'),
			emissiveIntensity: 10.0,
			envMapIntensity: 0,
			metalness: 0,
			roughness: 1
    }), false)
	}
}

export default Lights
