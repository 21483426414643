import * as React from 'react'

const WIDTH = 43.31
const HEIGHT = 43.31
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?:number
}

export default function Facebook({ width=43 }:Props) {
	const height = width * ASPECT_RATIO
  return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 43.31 43.31"
			width={width} height={height}
		>
			<g>
				<path fill="currentColor" d="M21.66,0A21.66,21.66,0,1,0,43.31,21.66,21.68,21.68,0,0,0,21.66,0Zm5.89,20.78H24v12H18.78v-12H15.34V17.34h3.44v-2c0-1.89.61-4.85,4.56-4.85h4.05v4H25a1,1,0,0,0-1,1.12v1.76h4Z"/>
				<path fill='white' d="M24,15.58a1,1,0,0,1,1-1.12h2.42v-4H23.34c-3.95,0-4.56,3-4.56,4.85v2H15.34v3.44h3.44v12H24v-12h3.6L28,17.34H24Z"/>
			</g>
		</svg>
  )
}
