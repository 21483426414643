import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import startupRemaining from './startupRemaining'
import SharingUtility from '../../../../core/SharingUtility'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    let state = getState()

    const currentVehicle = selectors.getSelectedVehicle(state)
    if (currentVehicle && currentVehicle.isLoading()) {
      console.warn('selectVehicle called, but we were already loading a vehicle. Ignoring.')
      return
    }

    const vehicle = selectors.getTemporarilySelectedVehicle(state)
    if (!vehicle) {
      console.warn('selectVehicle called, but no vehicle was temporarily selected! Ignoring.')
      return
    }

    dispatch({
      type: 'SELECT_VEHICLE',
      vehicle: vehicle
    })

    state = getState()
    const selections = selectors.getAllUserSelections(state)
    const shareCode = SharingUtility.createShareCode(selections)
    dispatch(startupRemaining(shareCode))
  }
}