import * as React from 'react'

const WIDTH = 119.41
const HEIGHT = 124.83
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?: number
	className?: string
	styles?: any
}

export default function Icon(props:Props) {
	const width = props.width || WIDTH
	const height = width * ASPECT_RATIO
  return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px" y="0px"
			viewBox="0 0 119.41 124.83"
			width={props.width}
			height={height}
			style={props.styles}
			className={props.className}
		>
			<g>
				<path fill="currentColor" d="M24.92,64.2V30.32h-6V64.2c0,11.36,6.33,22.33,18.83,32.6l3.81-4.63C30.52,83.09,24.92,73.68,24.92,64.2z" />
				<path fill="currentColor" d="M119.41,29.6c0-16.32-13.28-29.6-29.6-29.6c-7.84,0-14.97,3.07-20.27,8.06C62.39,6.68,55.38,4.3,48.67,0.94
					l-1.34-0.67l-1.34,0.67C23.06,12.4,3.76,8.92,3.57,8.88L0,8.19v60.46c0,14.75,8.05,29.08,23.27,41.45
					c11.27,9.16,22.4,14.01,22.87,14.22l1.19,0.51l1.19-0.51c0.47-0.2,11.59-5.05,22.87-14.22C86.6,97.73,94.65,83.39,94.65,68.65V58.8
					C108.68,56.49,119.41,44.28,119.41,29.6z M47.32,118.26C40.43,114.96,6,97.04,6,68.65V15.23c6.87,0.59,22.81,0.63,41.32-8.26
					c5.77,2.76,11.74,4.86,17.82,6.29c-3.12,4.69-4.94,10.31-4.94,16.35c0,15.93,12.66,28.96,28.45,29.57v9.47
					C88.65,97.04,54.22,114.96,47.32,118.26z M89.81,53.21c-13.01,0-23.6-10.59-23.6-23.6S76.79,6,89.81,6s23.6,10.59,23.6,23.6
					S102.82,53.21,89.81,53.21z" />
				<path fill="currentColor" d="M98.37,21.33L86.75,32.95l-5.51-5.51c-1.17-1.17-3.07-1.17-4.24,0c-1.17,1.17-1.17,3.07,0,4.24l9.75,9.75
					l15.87-15.87c1.17-1.17,1.17-3.07,0-4.24C101.45,20.16,99.55,20.16,98.37,21.33z" />
			</g>
		</svg>
  )
}
