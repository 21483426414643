import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'
import { GuiFieldName, prepareColorFieldTHREE, onColorChangeTHREE } from '../../utils/onColorChange'

class HeadlightGlass extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "HeadlightGlass",
      color: new THREE.Color("#12221c"),
      roughness: 0.01,
      transparent: true,
      opacity: 0.1
    }), false)
  }
}

export default HeadlightGlass