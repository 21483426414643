import SharingUtility from '../SharingUtility'

class PaintProtectionFilm {
  shareIdentifier:string
  name:string
  code:string
  finish:string
  icon:string
  isGlossy:boolean = false

  constructor(shareIdentifier:string, name:string, code:string, finish:string, icon:string, isGlossy:boolean) {
    if (!shareIdentifier) {
      throw new Error('shareIdentifier is required')
    }
    if (shareIdentifier.length !== SharingUtility.PaintProtectionFilmIdentifierLength) {
      throw new Error('shareIdentifier must have a length of ' + SharingUtility.PaintProtectionFilmIdentifierLength)
    }
    this.shareIdentifier = shareIdentifier

    if (!name) {
      throw new Error('Name is required')
    }
    this.name = name

    if (!code) {
      throw new Error('Code is required')
    }
    this.code = code

    if (!finish) {
      throw new Error('finish is required')
    }
    this.finish = finish

    if (!icon) {
      throw new Error('icon is required')
    }
    this.icon = icon

    this.isGlossy = isGlossy
  }

  isLoading = ():boolean => {
    return false
  }

  isLoaded = ():boolean => {
    return true
  }

  serialize = () => {
    return {
      shareIdentifier: this.shareIdentifier,
      name: this.name,
      code: this.code
    }
  }
}

export default PaintProtectionFilm