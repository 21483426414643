
import * as React from 'react'
import cx from 'classnames'
import BackButton from '../../../BackButton'

type PanelHeaderProps = {
  title: string
  icon: any
  type: string
  iconClassName?: string
  onBackClick: () => void
}

function MobilePanelHeader(props: PanelHeaderProps) {
  return (
    <div className='MobilePanelHeader'>
      <BackButton
        className='MobilePanelHeader--back'
        onClick={props.onBackClick}
      />

      <div className='MobilePanelHeader--icon'>
        <div className={cx(props.type, props.iconClassName)}>
          {props.icon}
        </div>
        {props.title}
      </div>
    </div>
  )
}

export default MobilePanelHeader