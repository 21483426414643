import CameraControls from 'camera-controls'
import THREE from '../../../../../../core/three/threeWithExtensions'

const START_TIME = new Date()
const ROTATION_SPEED = 0.015
const ROTATION_TIMEOUT_IN_MILLISECONDS = 10000

class AutoRotationController {
	_cameraControls:CameraControls
	_userControllingCamera:boolean
	_isUserInteracting:boolean
	_lastUserActionTimestamp:Date
	_paused:boolean

	constructor(cameraControls) {
		this._cameraControls = cameraControls
		this._cameraControls.addEventListener('controlstart', this._onCameraControlStart)
    this._cameraControls.addEventListener('controlend', this._onCameraContorlEnd)
		
		this._userControllingCamera = false
		this._isUserInteracting = false
		this._lastUserActionTimestamp = START_TIME
		this._paused = false
	}

	start = ():void => {
		this._update() // Kick off the animation frame loop
	}

	pause = ():void => {
		this._paused = true
	}

	unpause = ():void => {
		this._paused = false
	}

	setUserIsInteracting = (isInteracting:boolean):void => {
		this._isUserInteracting = isInteracting
	}

	recordUserInteraction = ():void => {
		this._lastUserActionTimestamp = new Date()
	}

	_onCameraControlStart = (evt) => {
		this._userControllingCamera = true
	}

	_onCameraContorlEnd = (evt) => {
		this._userControllingCamera = false
	}

	_update = ():void => {
		this._maybeAutoRotateCamera()
		requestAnimationFrame(this._update)
	}

	_maybeAutoRotateCamera = () => {
		if (this._paused || this._userControllingCamera || this._isUserInteracting) {
			this.recordUserInteraction()
			return
		}

		if (this._lastUserActionTimestamp !== START_TIME) {
			const now = new Date()
			const timeSinceLastAction = now.getTime() - this._lastUserActionTimestamp.getTime()
			if (timeSinceLastAction <= ROTATION_TIMEOUT_IN_MILLISECONDS) {
				return
			}
		}

		this._cameraControls.rotate(THREE.MathUtils.degToRad(ROTATION_SPEED * -1), 0.0, false)
	}
}

export default AutoRotationController