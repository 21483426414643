import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import PaintProtectionFilm from '../../../../../../../core/films/PaintProtectionFilm'
import { Maybe } from '../../../../../../../core/Maybe'
import SceneMode from '../../../../../../../core/SceneMode'
import openAccentPanel from '../../../../actionCreators/editorPanels/openAccentPanel'
import openChangeVehiclePanel from '../../../../actionCreators/editorPanels/openChangeVehiclePanel'
import openPanelGroupSelector from '../../../../actionCreators/editorPanels/openPanelGroupSelector'
import openPpfPanel from '../../../../actionCreators/editorPanels/openPpfPanel'
import openPwfPanel from '../../../../actionCreators/editorPanels/openPwfPanel'
import openWindowTintPanel from '../../../../actionCreators/editorPanels/openWindowTintPanel'
import openWrapsPanel from '../../../../actionCreators/editorPanels/openWrapsPanel'
import openYourDesignPanel from '../../../../actionCreators/editorPanels/openYourDesignPanel'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import selectors from '../../../../store/selectors'
import AccentsIcon from '../../../icons/Accents'
import MaterialsIcon from '../../../icons/Materials'
import PwfIcon from '../../../icons/PwfIcon'
import ShieldActive from '../../../icons/shields/ShieldActive'
import ShieldInactive from '../../../icons/shields/ShieldInactive'
import TintsIcon from '../../../icons/Tints'
import TranslationComponent from '../../../TranslationComponent'
import OptionCategoryButton from '../../OptionCategoryButton'

interface OwnProps { }

interface ReduxProps {
  sceneMode:SceneMode
  editorPanelState: EditorPanelState
  selectedFilm: Maybe<PaintProtectionFilm>
  paintProtectionFilmsEnabed: boolean
  protectionWrapFilmsEnabled: boolean
}

interface DispatchProps {
  openWrapsPanel: () => void
  openWindowTintPanel: () => void
  openAccentPanel: () => void
  openPpfPanel: () => void
  openPwfPanel: () => void
  openChangeVehiclePanel: () => void
  openYourDesignPanel: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

export function MobileLowerButtons(props: Props) {
  const visible = props.editorPanelState === EditorPanelState.Closed
  let { v } = useSpring({ v: visible ? 1 : 0 })
  return (
    <animated.div
      className='MobileLowerButtons'
      style={{
        transform: v.to((v:number) => `translateY(${(1 - v) * 10}px)`),
        opacity: v,
      }}
    >
      <div className='MobileLowerButtons--icons'>
        <OptionCategoryButton
          icon={<MaterialsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openWrapsPanel}
        />
        
        <OptionCategoryButton
          icon={<AccentsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openAccentPanel}
        />

        <OptionCategoryButton
          icon={<TintsIcon />}
          desktop={false}
          sceneMode={props.sceneMode}
          onClick={props.openWindowTintPanel}
        />
        
        {props.paintProtectionFilmsEnabed && (
          <OptionCategoryButton
            className={cx(props.selectedFilm && 'option-category-button--selected')}
            icon={props.selectedFilm ? <ShieldActive width={40} /> : <ShieldInactive width={30} />}
            desktop={false}
            sceneMode={props.sceneMode}
            onClick={props.openPpfPanel}
          />
        )}

        {props.protectionWrapFilmsEnabled && (
          <OptionCategoryButton
            icon={<PwfIcon />}
            desktop={false}
            sceneMode={props.sceneMode}
            onClick={props.openPwfPanel}
          />
        )}
      </div>
      <div className='MobileLowerButtons--links'>
        <div>
          <button onClick={props.openChangeVehiclePanel}>
            <TranslationComponent localizationKey='editor.change-vehicle' />
          </button>
        </div>
        <div>
          <button onClick={props.openYourDesignPanel}>
            <TranslationComponent localizationKey='editor.your-design' />
          </button>
        </div>
      </div>
    </animated.div>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      paintProtectionFilmsEnabed: selectors.isPaintProtectionFilmsEnabled(state),
      protectionWrapFilmsEnabled: selectors.isProtectionWrapFilmsEnabled(state)
    }
  },
  {
    openPanelGroupSelector,
    openWindowTintPanel,
    openWrapsPanel,
    openAccentPanel,
    openPpfPanel,
    openPwfPanel,
    openChangeVehiclePanel,
    openYourDesignPanel,
    selectPanelGroup
  }
)(MobileLowerButtons)