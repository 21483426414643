import * as React from 'react'

const WIDTH = 38.03
const HEIGHT = 50.05
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?:number
}

export default function Icon({ width=38.03 }:Props) {
	const height = width * ASPECT_RATIO
  return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px" y="0px"
			viewBox="0 0 38.03 50.05"
			width={width}
			height={height}
		>
			<g>
				<path fill="currentColor" d="M19.01,50.05l-0.48-0.21C17.78,49.51,0,41.67,0,27.47V3.18l1.44,0.28c0.08,0.01,7.83,1.41,17.04-3.19L19.01,0
	l0.54,0.27c9.21,4.6,16.97,3.2,17.04,3.19l1.43-0.27l0,24.29c0,14.2-17.78,22.04-18.54,22.37L19.01,50.05z M2.41,6.01v21.46
	c0,11.41,13.83,18.61,16.6,19.93c2.77-1.33,16.6-8.53,16.6-19.93V6.01c-2.76,0.23-9.16,0.25-16.6-3.32
	C11.57,6.26,5.17,6.24,2.41,6.01z"/>
				<path fill="currentColor" d="M11.05,14.18v13.29c0,3.8,4.18,7.59,7.97,10.06c3.79-2.47,7.97-6.26,7.97-10.06V14.18
	c-2.43-0.4-5.12-1.06-7.97-2.12C16.17,13.12,13.48,13.78,11.05,14.18z"/>
			</g>
		</svg>
  )
}