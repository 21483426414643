import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class LightsOrange extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: 'LightsOrange',
			color: new THREE.Color('#e7d800'),
			emissive: new THREE.Color('#e7d800'),
			emissiveIntensity: 10.0,
			envMapIntensity: 0,
			metalness: 0,
			roughness: 1
    }), false)
	}
}

export default LightsOrange
