import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import waitFor from '../../../../core/utils/waitFor'

export default () => {
  return async (dispatch: Dispatch, getState: GetState):Promise<void> => {
    let state = getState()

    // If we already have a share image, then we're done.
    let shareImage = selectors.getShareImageURL(state)
    if (shareImage) {
      return
    }

    const wasRenderingPaused = selectors.isRenderingPaused(state)
    if (wasRenderingPaused) {
      dispatch({ type: 'UNPAUSE_RENDERING' })
    }
    
    dispatch({ type: 'REQUEST_SHARE_IMAGE_DATA' })
    
    while(!shareImage) {
      await waitFor(1000)
      state = getState()
      shareImage = selectors.getShareImageURL(state)
    }
    
    if (wasRenderingPaused) {
      dispatch({ type: 'PAUSE_RENDERING' })
    }
  }
}