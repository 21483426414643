import PanelGroup from '../../../../core/vehicles/PanelGroup'
import EditorPanelState from '../store/models/EditorPanelState'
import Dispatch from './Dispatch'
import setEditorPanelState from './editorPanels/setEditorPanelState'
import GetState from './GetState'
import selectPanelGroup from './wraps/selectPanelGroup'

export type OpenPanelGroupFunction = (panelGroup:PanelGroup, panelState:EditorPanelState) => void

const openPanelGroup:OpenPanelGroupFunction = (panelGroup:PanelGroup, panelState:EditorPanelState) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!panelGroup) {
      console.warn('selectWrapForPanelGroup called, but no panel group specified! Ignoring.')
      return
    }

    dispatch(selectPanelGroup(panelGroup))
    dispatch(setEditorPanelState(panelState))
  }
}

export default openPanelGroup