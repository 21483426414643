import * as React from 'react'
import cx from 'classnames'

interface OwnProps { 
  className?: string
  style?: React.CSSProperties
}
interface State { }

class BaseView extends React.Component<OwnProps, State> {
  render() {
    return (
      <div className={cx('view', this.props.className)} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

export default BaseView