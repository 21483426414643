import * as React from 'react'
import { connect } from 'react-redux'
import EnvironmentConfig from '../../../../../../../core/EnvironmentConfig'
import AbstractWrapMaterial from '../../../../../../../core/materials/wraps/AbstractWrapMaterial'
import Maybe from '../../../../../../../core/Maybe'
import SceneMode from '../../../../../../../core/SceneMode'
import PanelGroup from '../../../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../../../core/wraps/Wrap'
import WrapMetaData, { WrapArea } from '../../../../../../../core/wraps/WrapMetaData'
import selectFinishGroup from '../../../../actionCreators/selectFinishGroup'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import AppState from '../../../../store/models/AppState'
import selectors from '../../../../store/selectors'
import DesktopAssetSelectorPanel, { HeaderSelectionOptions } from '../DesktopAssetSelectorPanel'
import DesktopColorSelector from '../DesktopColorSelector'
import DesktopWrapSelectionTitle from '../DesktopWrapSelectionTitle'

const NO_WRAP_CAPTION:string = 'NO_WRAP_CAPTION'
const NO_WRAP_TITLE:string = 'NO_WRAP_TITLE'

const noWrap = new Wrap(
  new WrapMetaData(
    'xx',
    NO_WRAP_CAPTION,
    NO_WRAP_TITLE,
    'productFamily',
    'colorFamily',
    'Matte',
    '#ddd',
    '',
    null
  ),
  new AbstractWrapMaterial()
)

export type DesktopMaterialSelectorType = 'materials' | 'accents' | 'pwf'

interface OwnProps {
  type: DesktopMaterialSelectorType
  title: string
  icon: React.ReactElement
  selectedWrap: Maybe<Wrap>
  onBackClick: () => void
  onWrapSelected: (material:Maybe<Wrap>) => void
  showPanelGroupFilter: boolean
}

interface ReduxProps {
  environmentConfig: EnvironmentConfig
  sceneMode: SceneMode
  finishGroups: string[]
  selectedFinishGroup: Maybe<string>
  wraps: Wrap[]
  panelGroups: PanelGroup[]
  selectedPanelGroup: Maybe<PanelGroup>
  translate: (key: string) => string
}

interface DispatchProps {
  selectFinishGroup: (group:Maybe<string>) => any
  selectPanelGroup: (panelGroup:Maybe<PanelGroup>) => any
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State {
  hoveredWrap: Maybe<Wrap>
}

class DesktopMaterialSelector extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      hoveredWrap: noWrap
    }
  }

  onPanelGroupSelected = (panelGroupName:Maybe<string>) => {
    const match = this.props.panelGroups.find( (panelGroup) => {
      const title = this.props.translate(panelGroup.localizationKey)
      return title === panelGroupName
    })

    this.props.selectPanelGroup(match)
  }

  onFinishGroupSelected = (finishGroupName:Maybe<string>) => {
    this.props.selectFinishGroup(finishGroupName)
  }

  render() {
    // The icon prop will be a class, so make a binding with an upcased first
    // letter, so we can render it e.g. <Icon />.
    let { icon: Icon } = this.props

    // <this> may seem like an unnecessary step but remember that PWFs are also wraps, and we don't want PWFs appearing in the regular wrap section.
    const selectedWrap:Maybe<Wrap> = this.props.wraps.find( (wrap:Wrap):boolean => {
      return wrap === this.props.selectedWrap
    })
    // </this>

    const headerOptions:HeaderSelectionOptions[] = []
    if (this.props.type === 'materials' || this.props.type === 'pwf') {
      headerOptions.push({
        title: this.props.translate('editor.materials.entire-vehicle'),
        values: this.props.panelGroups.map((group) => {
          return this.props.translate(group.localizationKey)
        }),
        selectedValue: this.props.selectedPanelGroup ? this.props.translate(this.props.selectedPanelGroup.localizationKey) : null,
        onHeaderOptionSelected: this.onPanelGroupSelected
      })
    }

    headerOptions.push({
      title: this.props.translate('editor.materials.all'),
      values: this.props.finishGroups,
      selectedValue: this.props.selectedFinishGroup,
      onHeaderOptionSelected: this.onFinishGroupSelected
    })

    const onMouseLeave = () => {
      this.setState({ hoveredWrap:null })
    }

    const filteredWraps:Wrap[] = this.props.wraps.filter( (wrap:Wrap):boolean => {
      if (this.props.type === 'pwf') {
        return wrap.meta.area === WrapArea.ProtectionWrapFilm
      }

      return wrap.meta.area === WrapArea.Wraps
    })

    return (
      <DesktopAssetSelectorPanel
        className='DesktopMaterialSelector'
        bodyClassName='DesktopMaterialSelector--body'
        title={this.props.title}
        titleIcon={Icon}
        headerOptions={headerOptions}
      >
        <React.Fragment>
          <DesktopWrapSelectionTitle
            type={this.props.type}
            noWrap={noWrap}
            wrap={this.state.hoveredWrap || selectedWrap || noWrap}
          />

          <div
            className='DesktopMaterialSelector--inner'
            onMouseLeave={onMouseLeave}
          >
            <DesktopColorSelector
              basePath={this.props.environmentConfig.getAssetBasePath()}
              wraps={filteredWraps}
              selectedWrap={selectedWrap}
              temporaryWrap={selectedWrap}
              onHoverWrap={(wrap:Maybe<Wrap>) => {
                this.setState({ hoveredWrap: wrap })
              }}
              onSelectWrap={(wrap:Maybe<Wrap>) => {
                this.props.onWrapSelected(wrap)
              }}
            />
          </div>
        </React.Fragment>
      </DesktopAssetSelectorPanel>
    )
  }
}

export default connect(
  (state: AppState, ownProps:OwnProps): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state)!, // By the time this loads, we will have a config.
      finishGroups: (
        ownProps.type === 'pwf'
        ? selectors.getPwfFinishGroups(state)
        : selectors.getWrapFinishGroups(state)
      ),
      panelGroups: selectors.getSelectedVehiclePanelGroups(state),
      sceneMode: selectors.getSelectedSceneMode(state),
      selectedFinishGroup: selectors.getSelectedFinishGroup(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      wraps: (
        ownProps.type === 'pwf'
        ? selectors.getPwfsInSelectedFinishGroup(state)
        : selectors.getWrapsInSelectedFinishGroup(state)
      ),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectFinishGroup,
    selectPanelGroup
  }
)(DesktopMaterialSelector)