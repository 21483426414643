import THREE from '../three/threeWithExtensions'

class MaterialExportImportTranslator {
  static showExportUI = (data:any) => {

    const wrapper = document.createElement('div')
    wrapper.className = 'modal'
    document.body.appendChild(wrapper)

    const textArea = document.createElement('textarea')
    textArea.value = MaterialExportImportTranslator._serializeData(data)
    wrapper.appendChild(textArea)

    const button = document.createElement('button')
    button.textContent = 'Close'
    button.onclick = () => {
      wrapper.remove()
    }
    wrapper.appendChild(button)
  }

  static showImportUI = ():Promise<any> => {
    return new Promise( (resolve, reject) => {
      const wrapper = document.createElement('div')
      wrapper.className = 'modal'
      document.body.appendChild(wrapper)

      const textArea = document.createElement('textarea')
      wrapper.appendChild(textArea)

      const closeButton = document.createElement('button')
      closeButton.textContent = 'Close'
      closeButton.onclick = () => {
        reject()
        wrapper.remove()
      }
      wrapper.appendChild(closeButton)

      const importButton = document.createElement('button')
      importButton.textContent = 'Import'
      importButton.onclick = () => {
        const data = JSON.parse(textArea.value)
        resolve(data)
        wrapper.remove()
      }
      wrapper.appendChild(importButton)
    })
  }

  static exportNumberField = (field:any):any => {
    if (!field.type || field.type !== 'f') {
      throw new Error('Must supply a number field instance!')
    }

    return field.value
  }

  static exportColorField = (field:any):any => {
    if (!field.type || field.type !== 'c') {
      throw new Error('Must supply a color field instance!')
    }

    return '#' + field.value.getHexString()
  }

  static _serializeData = (data:any):string => {
    const serializedData = JSON.stringify(data, null, '\t')
    return serializedData
  }
}

export default MaterialExportImportTranslator