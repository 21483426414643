import cx from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { Vector2 } from 'three'
import useEventListener from '../../helpers/useEventListener'

interface Props {
  className?: string
}

const Mouse = (props:Props) => {
  const [mouseIsDown, setMouseIsDown] = useState<boolean>(false)
  const [mouseAt, setMouseAt] = useState<Vector2>(new Vector2(0, 0))

  useEventListener('mousemove', (event) => {
    setMouseAt(new Vector2(event.clientX, event.clientY))
  })

  useEventListener('mousedown', () => {
    setMouseIsDown(true)
  })
  useEventListener('mouseup', () => {
    setMouseIsDown(false)
  })

  useEffect(() => {
    document.body.style.cursor = 'none'

    return () => {
      document.body.style.cursor = 'auto'
    }
  }, [])

  return (
    <Fragment>
      <div
        className={cx('Mouse--dot', mouseIsDown && 'Mouse--down')}
        style={{
          transform: `translate(${mouseAt.x}px, ${mouseAt.y}px)`
        }}
      />
      <div
        className={cx('Mouse--ring', mouseIsDown && 'Mouse--down')}
        style={{
          left: mouseAt.x - 16,
          top: mouseAt.y - 16,
        }}
      />
    </Fragment>
  )
}

export default Mouse