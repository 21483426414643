import * as React from 'react'
import { connect } from 'react-redux'
import * as _ from 'underscore'
import selectLanguage from '../../actionCreators/selectLanguage'
import AppState from '../../store/models/AppState'
import SelectOption from '../../store/models/SelectOption'
import selectors from '../../store/selectors'
import TranslationComponent from '../TranslationComponent'
import cx from 'classnames'
import confirmLanguageSelection from '../../actionCreators/confirmLanguageSelection'

interface OwnProps { }

interface ReduxProps {
  currentLocale: string
  options: Array<SelectOption>
}

interface DispatchProps {
  selectLanguage: (language:string) => Promise<void>
  confirmLanguageSelection: () => Promise<void>
}

interface State { }

class LanguageSelectionModal extends React.Component<OwnProps & ReduxProps & DispatchProps, State> {
  render() {
    return (
      <div className='languageSelectionModal'>
        <div className='languageSelectionModal--inner'>
          <div className='languageSelectionModal--content'>
            <h2 className='languageSelectionModal--title'>
              <TranslationComponent localizationKey='global.localization.selectionTitle' />
            </h2>

            <div className='languageSelectionModal--languageOptions'>
              {_.map(this.props.options, (option:SelectOption) => {
                return (
                  <button
                    className={cx('languageSelectionModal--languageButton', option.key === this.props.currentLocale && 'languageSelectionModal--languageButtonSelected')}
                    onClick={() => {
                      this.props.selectLanguage(option.key)
                    }}
                  >
                    {option.value}
                  </button>
                )
              })}
            </div>
          </div>

          <button 
            className='languageSelectionModal--continueButton'
            onClick={this.onContinueSelected}
          >
            <TranslationComponent localizationKey='global.localization.selectionContinue' />
          </button>
        </div>
      </div>
    )
  }

  onContinueSelected = () => {
    this.props.confirmLanguageSelection()
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      currentLocale: selectors.getLanguage(state),
      options: selectors.getLocaleSelectionOptions(state)
    }
  },
  {
    selectLanguage, confirmLanguageSelection
  }
)(LanguageSelectionModal)