import * as React from 'react'

export default function Exhaust() {
  return (
		<div className="smoke-group">
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
			<div className="smoke"></div>
		</div>
  )
}