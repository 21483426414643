import Vehicle from './vehicles/Vehicle'
import Tint from './tints/Tint'
import Wrap from './wraps/Wrap'
import IMaterial from './materials/IMaterial'
import partsInstances from './materials/parts/PartsInstances'

class DefaultsFinder {
  static getDefaultVehicle = (vehicles:Array<Vehicle>):Vehicle => {
    return vehicles[0]
  }

  static getDefaultTint = (tints:Array<Tint>):IMaterial => {
    return partsInstances.windowGlass
  }

  static getDefaultWrap = (wraps:Array<Wrap>):IMaterial => {
    return partsInstances.primerGrey
  }
}

export default DefaultsFinder