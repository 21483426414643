// @ts-ignore For some reason, tsc can't find the `underscore` types. Probably because we're in a lifted folder.
import * as _ from 'underscore'
import AssetLibrary from "../AssetLibrary"
import Maybe from '../Maybe'
import Tint from './Tint'
import TintFactory from './TintFactory'

class TintsConfigLoader {
	load = async (url:string):Promise<Maybe<Tint[]>> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		if (!data.tints) {
      console.error('Loaded JSON did not have a `tints` element.')
			return null
		}

		let loaded:Tint[] = []
		data.tints.forEach( (json:any) => {
			const tint = TintFactory.fromConfig(json)
			if (tint) {
				loaded.push(tint)
			}
		})


		// Make sure all of the shareIdentifiers are unique
		loaded.forEach( (tint:Tint) => {
			const matches = loaded.filter( (otherTint) => {
				return otherTint.shareIdentifier === tint.shareIdentifier
			})

			if (matches.length !== 1) {
				throw new Error('Loaded tints JSON had multiple items with the same shareIdentifier key `' + tint.shareIdentifier + '`!')
			}
		})

		loaded = _.sortBy(loaded, 'sortOrder')

		return loaded
	}
}

export default TintsConfigLoader