// @ts-ignore For some reason, tsc can't find the `underscore` types. Probably because we're in a lifted folder.
import * as _ from 'underscore'
import AssetLibrary from "../AssetLibrary"
import Maybe from '../Maybe'
import SharingUtility from "../SharingUtility"
import THREE from '../three/threeWithExtensions'
import PanelGroup from "./PanelGroup"
import Vehicle from './Vehicle'

class VehiclesConfigLoader {
	load = async (url:string):Promise<Maybe<Vehicle[]>> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
    }

		if (!data.vehicles) {
      console.error('Loaded JSON did not have a `vehicles` element.')
      return null
		}

    let loaded:Vehicle[] = []
		data.vehicles.forEach( (entry:any) => {
			const vehicle = this.tryToParseVehicleFromConfigEntry(entry)
			if (vehicle) {
				loaded.push(vehicle)
			}
    })

    // Make sure all of the shareIdentifiers are unique
    for (const vehicle of loaded) {
			const matches = loaded.filter( (otherVehicle) => {
				return otherVehicle.shareIdentifier === vehicle.shareIdentifier
			})

			if (matches.length !== 1) {
        console.error('Loaded vehicles JSON had multiple items with the same shareIdentifier key `' + vehicle.shareIdentifier + '`!')
        return null
			}
    }

    loaded = _.sortBy(loaded, 'sortOrder')

		return loaded
	}

  tryToParseVehicleFromConfigEntry = (entry:any):Maybe<Vehicle> => {
    if (!entry) { // Really, this should never happen as you can't make blank things in JSON... but here for safety
      console.warn('Vehicle config entry was blank!')
      return null
    }

    if (!entry.shareIdentifier) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `shareIdentifier` value! Ignoring entry.')
      return null
    }
    if (entry.shareIdentifier.length !== SharingUtility.VehicleIdentifierLength) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` `shareIdentifier` value was not of length ' + SharingUtility.VehicleIdentifierLength + '! Ignoring entry.')
      return null
    }

    if (!entry.nameLocalizationKey) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `nameLocalizationKey` value! Ignoring entry.')
      return null
    }

    if (!entry.captionLocalizationKey) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `captionLocalizationKey` value! Ignoring entry.')
      return null
    }

    if (!entry.selectCssClassName) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `selectCssClassName` value! Ignoring entry.')
      return null
    }

    if (!entry.selectImageURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `selectImageURL` value! Ignoring entry.')
      return null
    }

    if (!entry.selectImageMobileURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `selectImageMobileURL` value! Ignoring entry.')
      return null
    }

    if (!entry.highlightImageURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `highlightImageURL` value! Ignoring entry.')
      return null
    }

    if (!entry.highlightImageMobileURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `highlightImageMobileURL` value! Ignoring entry.')
      return null
    }

    if (!entry.previewImageURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `previewImageURL` value! Ignoring entry.')
      return null
    }

    if (!entry.modelURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `modelURL` value! Ignoring entry.')
      return null
    }

    if (!entry.paintProtectionFilmModelURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `paintProtectionFilmModelURL` value! Ignoring entry.')
      return null
    }

    if (!entry.paintProtectionFilmMeshNamesSilver) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `paintProtectionFilmMeshNamesSilver` value! Ignoring entry.')
      return null
    }

    if (!entry.paintProtectionFilmMeshNamesGold) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `paintProtectionFilmMeshNamesGold` value! Ignoring entry.')
      return null
    }

    if (!entry.paintProtectionFilmMeshNamesPlatinum) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `paintProtectionFilmMeshNamesPlatinum` value! Ignoring entry.')
      return null
    }

    if (!entry.colorMapTextureURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `colorMapTextureURL` value! Ignoring entry.')
      return null
    }

    if (!entry.shadowTextureURL) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `shadowTextureURL` value! Ignoring entry.')
      return null
    }

    if (!entry.panelGroups) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` was missing a `panelGroups` value! Ignoring entry.')
      return null
    }
    const panelGroups = this.tryToParsePanelGroups(entry.panelGroups)
    if (!panelGroups) {
      console.warn('Vehicle config `' + JSON.stringify(entry) + '` had an invalid `panelGroups` value! Ignoring entry.')
      return null
    }

    const cameraRange = new THREE.Vector2(250, 650)
    const cameraRangePortrait = new THREE.Vector2(250, 850)
    const cameraStartAt = new THREE.Vector3(372, 354, 396)
    const screenshotPosition = new THREE.Vector3(224.20246684691912, 290.69422051037157, 369.6351228192792)
    const screenshotLookAtOffset = new THREE.Vector3(-40.0, 20.0, -10.0)
    const screenshotPositionPortrait = new THREE.Vector3(224.20246684691912, 290.69422051037157, 369.6351228192792)
    if (entry.camera) {
      if (entry.camera.range) {
        cameraRange.x = entry.camera.range.minimum
        cameraRange.y = entry.camera.range.maximum
      }
      if (entry.camera.rangePortrait) {
        cameraRangePortrait.x = entry.camera.rangePortrait.minimum
        cameraRangePortrait.y = entry.camera.rangePortrait.maximum
      }

      if (entry.camera.startAt) {
        cameraStartAt.set(
          entry.camera.startAt.x,
          entry.camera.startAt.y,
          entry.camera.startAt.z
        )
      }
      if (entry.camera.screenshotPosition) {
        screenshotPosition.set(
          entry.camera.screenshotPosition.x,
          entry.camera.screenshotPosition.y,
          entry.camera.screenshotPosition.z
        )
      }
      if ( entry.camera.screenshotLookAtOffset) {
        screenshotLookAtOffset.set(
          entry.camera.screenshotLookAtOffset.x,
          entry.camera.screenshotLookAtOffset.y,
          entry.camera.screenshotLookAtOffset.z
        )
      }
      if ( entry.camera.screenshotPositionPortrait) {
        screenshotPositionPortrait.set(
          entry.camera.screenshotPositionPortrait.x,
          entry.camera.screenshotPositionPortrait.y,
          entry.camera.screenshotPositionPortrait.z
        )
      }
    }

    let vehicle = null
    try {
      vehicle = new Vehicle(
        entry.shareIdentifier,
        entry.sortOrder || 0,
        entry.nameLocalizationKey, entry.captionLocalizationKey,
        entry.selectCssClassName,
        entry.selectImageURL, entry.selectImageMobileURL,
        entry.highlightImageURL, entry.highlightImageMobileURL,
        entry.previewImageURL,
        entry.modelURL,
        entry.paintProtectionFilmModelURL,
        entry.paintProtectionFilmMeshNamesSilver,
        entry.paintProtectionFilmMeshNamesGold,
        entry.paintProtectionFilmMeshNamesPlatinum,
        entry.colorMapTextureURL,
        entry.shadowTextureURL,
        panelGroups,
        cameraRange,
        cameraRangePortrait,
        cameraStartAt,
        screenshotPosition,
        screenshotLookAtOffset,
        screenshotPositionPortrait
      )
    }
    catch(e) {
      console.warn('Exception creating Vehicle entry from JSON `' + JSON.stringify(entry) + '`.')
    }

    return vehicle
  }

  tryToParsePanelGroups = (list:Array<any>):Maybe<PanelGroup[]> => {
    if (!list || list.length <= 0) {
      return null
    }

    const groups:PanelGroup[] = []
    list.forEach( (item) => {
      let group = null
      try {
        let position:THREE.Vector3 = new THREE.Vector3(item.cameraPosition.x, item.cameraPosition.y, item.cameraPosition.z)
        group = new PanelGroup(item.key, item.localizationKey, item.meshNames, item.extraMeshNames, position)
        groups.push(group)
      }
      catch(e)
      {
        console.warn('Invalid PanelGroup entry `' + JSON.stringify(item) + '`. Skipping. Error=' + e)
      }
    })

    return groups
  }
}

export default VehiclesConfigLoader