import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class LightsRed extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: 'LightsRed',
			color: new THREE.Color('#ff0000'),
			emissive: new THREE.Color('#ff0000'),
			emissiveIntensity: 10.0,
			envMapIntensity: 0,
			metalness: 0,
			roughness: 1
    }), false)
	}
}

export default LightsRed
