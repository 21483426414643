import EditorPanelState from '../store/models/EditorPanelState'
import View from '../store/models/View'
import selectors from '../store/selectors'
import Dispatch from './Dispatch'
import setEditorPanelState from './editorPanels/setEditorPanelState'
import GetState from './GetState'
import closeAllModals from './modals/closeAllModals'
import requestShareImageData from './requestShareImageData'
import updateRenderingStatus from './updateRenderingStatus'

export default (view:View, clearHistory:boolean=false) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    if (!view) {
      console.warn('navigateTo called, but no view was supplied! Ignoring.')
      return
    }
    
    dispatch(closeAllModals())

    if (view !== View.VehicleEdit) {
      dispatch(setEditorPanelState(EditorPanelState.Closed))
    }

    switch(view) {
      case View.Finale: // Since the finale view needs the sharing download image, we need to kick this off.
        const state = getState()
        const selectedVehicle = selectors.getSelectedVehicle(state)
        if (selectedVehicle) {
          dispatch(requestShareImageData())
        }
        break
    }

		dispatch({
			type: 'SET_VIEW',
      view: view,
      clearHistory: clearHistory
    })

    // Need to remember to update the rendering status! If the view changed, we may need to pause/unpause rendering!
    dispatch(updateRenderingStatus())
  }
}