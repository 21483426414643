import * as React from 'react'
import { connect } from 'react-redux'
import AppState from '../store/models/AppState'
import navigateTo from '../actionCreators/navigateTo'
import openModal from '../actionCreators/modals/openModal'
import resetExperienceAndLanguage from '../actionCreators/resetExperienceAndLanguage'
import selectLanguage from '../actionCreators/selectLanguage'
import selectors from '../store/selectors/index'
import View from '../store/models/View'
import LogoIcon from '../components/icons/Logo'
import ShareIcon from '../components/icons/Share'
import LocationIcon from '../components/icons/Location'
import TranslationComponent from '../components/TranslationComponent'
import EnvironmentConfig from '../../../../core/EnvironmentConfig'
import Modal from '../store/models/Modal'

interface OwnProps {}

interface ReduxProps {
  environmentConfig: EnvironmentConfig,
  hasLocalizationConfigured: boolean,
  isOnVehicleSelectionView: boolean,
  isOnVehicleEditView: boolean,
  isOnFinaleView: boolean,
  hasMadeAnySelections: boolean,
  translate: (key: string) => string
}

interface DispatchProps {
  navigateTo: (view:View, clearHistory:boolean) => void,
  openModal: (modal:Modal) => void,
  resetExperienceAndLanguage: () => void,
  selectLanguage: (language: string) => void
}

interface State {
  hamburgerOpen: boolean
}

class Header extends React.Component<
  OwnProps & ReduxProps & DispatchProps,
  State
> {
  constructor(props) {
    super(props)
    this.state = {hamburgerOpen: false}
  }

  onLogoClick = () => {
    if (!this.props.isOnVehicleSelectionView) {
      this.props.resetExperienceAndLanguage()
    }
  }

  onShareSelected = () => {
    this.setState({hamburgerOpen: false})
    this.props.openModal(Modal.Share)
  }

  onDoneSelected = () => {
    this.setState({hamburgerOpen: false})
    this.props.navigateTo(View.Finale, false)
  }

  onStartOverSelected = () => {
    this.props.resetExperienceAndLanguage()
  }

  render() {
    return (
      <header className="header">
        <div className="header-left">
          {!this.props.isOnFinaleView &&
            <a onClick={this.onLogoClick}>
              <LogoIcon />
            </a>
          }
          {(this.props.isOnVehicleSelectionView || this.props.isOnVehicleEditView) && this.props.hasLocalizationConfigured &&
            <a href={this.props.translate('global.localization.url')} className="region-nav">
              <TranslationComponent localizationKey="global.localization.title" />
            </a>
          }
        </div>

        <div className="header-right">
          <div className="header-right--row-one">
            {(this.props.isOnVehicleSelectionView || this.props.isOnVehicleEditView) && (<React.Fragment>
              <a href={this.props.translate('global.restyler-url')} className='btn btn--outline btn--no-padding hidden-lap-and-up 3m_btn_FindRestyler_mobile'><LocationIcon /></a>
              <a href={this.props.translate('global.restyler-url')} className='btn btn--outline btn--tight hidden-below-lap 3m_btn_FindRestyler'>
                <TranslationComponent localizationKey="global.contact-restyler" />
              </a>
            </React.Fragment>)}
            {(!this.props.isOnVehicleSelectionView && this.props.isOnVehicleEditView) && (<React.Fragment>
              <a onClick={this.onStartOverSelected} className='btn btn--outline btn--start-over btn--tight hidden-below-lap 3m_btn_FindRestyler'>
                <TranslationComponent localizationKey="global.start-over" />
              </a>
            </React.Fragment>)}
            {(this.props.isOnVehicleSelectionView) && (<React.Fragment>
              <a onClick={this.onShareSelected} className='btn btn--outline btn--no-padding hidden-lap-and-up 3m_btn_Share_mobile'><ShareIcon /></a>
              <a onClick={this.onShareSelected} className='btn btn--outline btn--tight hidden-below-lap 3m_btn_Share'>
                <TranslationComponent localizationKey="global.share" />
              </a>
            </React.Fragment>)}
            {this.props.hasMadeAnySelections && !this.props.isOnFinaleView && (
              <a onClick={this.onDoneSelected} className='btn btn--done 3m_btn_Done'>
                <TranslationComponent localizationKey="global.finished-cta" />
              </a>
            )}
          </div>
          <div className="header-right--row-two">
            {(!this.props.isOnVehicleSelectionView && this.props.isOnVehicleEditView) && (<React.Fragment>
              <a onClick={this.onStartOverSelected} className='btn btn--outline btn--start-over btn--tight hidden-below-lap 3m_btn_FindRestyler'>
                <TranslationComponent localizationKey="global.start-over" />
              </a>
            </React.Fragment>)}
            {this.props.hasMadeAnySelections && !this.props.isOnFinaleView && (
              <a onClick={this.onDoneSelected} className='btn btn--done hidden-below-lap 3m_btn_Done'>
                <TranslationComponent localizationKey="global.finished-cta" />
              </a>
            )}
          </div>
        </div>
    </header>)
  }

  toggleMobileNav = () => {
    this.setState({
      hamburgerOpen: !this.state.hamburgerOpen
    })
  }
}

export default connect(
  (state: AppState): ReduxProps => ({
    environmentConfig: selectors.getEnvironmentConfig(state),
    hasLocalizationConfigured: selectors.hasLocalizationConfigured(state),
    isOnVehicleSelectionView: selectors.isOnVehicleSelectionView(state),
    isOnVehicleEditView: selectors.isOnVehicleEditView(state),
    isOnFinaleView: selectors.isOnFinaleView(state),
    hasMadeAnySelections: selectors.hasMadeAnySelectionsForVehicle(state),
    translate: selectors.getTranslator(state)
  }),
  {
    navigateTo,
    resetExperienceAndLanguage,
    openModal,
    selectLanguage
  }
)(Header)