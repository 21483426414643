import cx from 'classnames'
import * as React from 'react'
import Maybe from '../../../../../../../core/Maybe'
import ArrowIcon from '../../../icons/Arrow'

type FilterMenuItem<V> = {
  label: string
  value: V
}

type FilterMenuProps<V> = {
  allLabel: string
  items: Array<FilterMenuItem<V>>
  selected: V
  onSelect: (value:Maybe<V>) => void
  right: number
}

function DesktopFilterMenu<V>(props:FilterMenuProps<V>) {
  const [open, setOpen] = React.useState<boolean>(false)
  const selectedItem:Maybe<FilterMenuItem<V>> = props.items.find((item:FilterMenuItem<V>) => {
    return item.value === props.selected
  })

  return (
    <div
      className={cx('DesktopFilterMenu', 'filter-menu', open && 'DesktopFilterMenu--open')}
      style={{ right: props.right }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <ul className='v-list'>
        {props.items.map((item) => {
          return (
            <li
              key={item.label + item.value}
              className={cx(
                selectedItem && selectedItem.label === item.label && 'selected'
              )}
            >
              <button
                className={cx(selectedItem && selectedItem.label === item.label && 'selected')}
                onClick={() => props.onSelect(item.value)}
              >
                {item.label}
              </button>
            </li>
          )
        })}
        <li>
          {open ? (
            <button
              onClick={() => props.onSelect(null)}
              className={cx(selectedItem && 'selected')}
            >
              {props.allLabel}
            </button>
          ) : (
            <button className='selected'>
              {selectedItem && !open ? selectedItem.label : props.allLabel}
            </button>
          )}
        </li>
      </ul>
      <div className='DesktopFilterMenu--icon'>
        <ArrowIcon direction={open ? 'down' : 'up'} width={14} />
      </div>
    </div>
  )
}

export default DesktopFilterMenu