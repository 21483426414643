
import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import PanelGroup from '../../../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../../../core/wraps/Wrap'
import selectFinishGroup from '../../../../actionCreators/selectFinishGroup'
import AppState from '../../../../store/models/AppState'
import selectors from '../../../../store/selectors'
import CancelIcon from '../../../icons/Cancel'
import MobileAssetSelectorPanel from '../MobileAssetSelectorPanel'
import MobileSelectedItemInfo from '../MobileSelectedItemInfo'
import { WrapArea } from '../../../../../../../core/wraps/WrapMetaData'

export type MobileWrapSelectorType = 'materials' | 'accents' | 'pwf'

interface OwnProps {
  title: string
  type: MobileWrapSelectorType
  icon: any
  selectedWrap: Maybe<Wrap>
  panelGroups?: Maybe<PanelGroup[]>
  selectedPanelGroup?: Maybe<PanelGroup>
  onSelectWrap: (wrap:Maybe<Wrap>) => void
  selectPanelGroup?: (group:Maybe<PanelGroup>) => void
}

interface ReduxProps {
  wraps: Wrap[]
  finishGroups: string[]
  selectedFinishGroup: Maybe<string>
  translate: (key:string) => string
}

interface DispatchProps {
  selectFinishGroup: (group:Maybe<string>) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function MobileWrapSelector(props: Props) {
  const headerOptions:any[] = []

  if (props.type === 'materials' || props.type === 'pwf') {
    headerOptions.push({
      title: props.translate('editor.materials.entire-vehicle'),
      values: props.panelGroups?.map((group) => {
        return props.translate(group.localizationKey)
      }),
      selectedValue: props.selectedPanelGroup ? props.translate(props.selectedPanelGroup.localizationKey) : '',
      onHeaderOptionSelected: (option:string) => {
        const match:Maybe<PanelGroup> = props.panelGroups?.find( (panelGroup) => {
          return props.translate(panelGroup.localizationKey) === option
        })
        props.selectPanelGroup?.(match)
      }
    })
  }
  
  headerOptions.push(
    {
      title: props.translate('editor.materials.all'),
      values: props.finishGroups.map((finish) => {
        return finish
      }),
      selectedValue: props.selectedFinishGroup,
      onHeaderOptionSelected: (finish:string) => {
        props.selectFinishGroup(finish)
      }
    }
  )

  // <this> may seem like an unnecessary step but remember that PWFs are also wraps, and we don't want PWFs appearing in the regular wrap section.
  const selectedWrap:Maybe<Wrap> = props.wraps.find( (wrap:Wrap):boolean => {
    return wrap === props.selectedWrap
  })
  // </this>

  return (
    <MobileAssetSelectorPanel
      title={props.title}
      icon={props.icon}
      iconClassName={props.type}
      headerOptions={headerOptions}
    >
      <MobileSelectedItemInfo
        name={selectedWrap ? props.translate(selectedWrap.meta.name) : props.translate('editor.no-selection.title')}
        code={selectedWrap ? selectedWrap.meta.code : props.translate('editor.no-selection.captionMobile')}
      />

      <div className='mobile-swatch-grid'>
        <button
          className={cx(
            'mobile-swatch',
            !selectedWrap && 'selected'
          )}
          style={{ color: '#aaa' }}
          onClick={(event) => {
            event.preventDefault()

            if (!selectedWrap) { // If there is no selected wrap, then there's no need to change anything.
              return
            }
            props.onSelectWrap(null)
          }}
        >
          <CancelIcon />
        </button>
        {props.wraps.map( (wrap:Wrap) => {
          return (
            <button
              key={wrap.meta.code}
              onClick={(event) => {
                event.preventDefault()
                props.onSelectWrap(wrap)
              }}
              className={cx({
                'mobile-swatch': true,
                selected: selectedWrap === wrap
              })}
              style={{ background: wrap.meta.swatchColor }}
            />
          )
        })}
      </div>
    </MobileAssetSelectorPanel>
  )
}

export default connect(
  (state: AppState, ownProps:OwnProps):ReduxProps => {
    return {
      wraps: (
        ownProps.type === 'pwf'
        ? selectors.getPwfsInSelectedFinishGroup(state)
        : selectors.getWrapsInSelectedFinishGroup(state)
      ),
      finishGroups: (
        ownProps.type === 'pwf'
        ? selectors.getPwfFinishGroups(state)
        : selectors.getWrapFinishGroups(state)
      ),
      selectedFinishGroup: selectors.getSelectedFinishGroup(state),
      translate: selectors.getTranslator(state),
    }
  },
  {
    selectFinishGroup
  }
)(MobileWrapSelector)