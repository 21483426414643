import { useRect } from '@reach/rect'
import * as React from 'react'
import Maybe from '../../../../../../../core/Maybe'
import Wrap from '../../../../../../../core/wraps/Wrap'
import DesktopPager from '../DesktopPager/DesktopPager'

interface Props {
  basePath: string
  wraps: Wrap[]
  selectedWrap: Maybe<Wrap>
  temporaryWrap: Maybe<Wrap>
  onHoverWrap: (wrap:Maybe<Wrap>) => void
  onSelectWrap: (wrap:Maybe<Wrap>) => void
}

const DesktopColorSelector = (props:Props) => {
  const ref = React.useRef(null)
  const rect:Maybe<DOMRect> = useRect(ref)
  const width:number = (rect ? Math.ceil(rect.width) : 0) // Round up to the nearest whole number to prevent gaps.

  return (
    <div ref={ref} className='DesktopColorSelector'>
      {rect && (
        <DesktopPager
          basePath={props.basePath}
          key={props.wraps.length + '-wraps'}
          wraps={props.wraps}
          width={width}
          selectedWrap={props.selectedWrap}
          temporaryWrap={props.temporaryWrap}
          onHoverWrap={props.onHoverWrap}
          onSelectWrap={props.onSelectWrap}
        />
      )}
    </div>
  )
}

export default DesktopColorSelector
