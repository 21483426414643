class StringTaker {
	_remaining:string

	constructor(value:string) {
		this._remaining = value
	}

	take = (count:number):string => {
		if (count > this._remaining.length) {
			throw new Error('There are not ' + count + ' characters remaining!')
		}

		const value = this._remaining.substr(0, count)
		this._remaining = this._remaining.substr(count)
		return value
	}

	maybeTake = (count:number):string|null => {
		let result = null
		if (this._remaining.length < count) {
			return null
		}

		return this.take(count)
	}
}

export default StringTaker