import * as React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import THREE from '../../../../../../core/three/threeWithExtensions'
import AppState from '../../../store/models/AppState'
import selectors from '../../../store/selectors'
import ThreeController from './ThreeController'
import UserSelections from '../../../../../../core/UserSelections'
import PanelGroup from '../../../../../../core/vehicles/PanelGroup'
import selectPanelGroup from '../../../actionCreators/wraps/selectPanelGroup'
import deselectPanelGroup from '../../../actionCreators/wraps/deselectPanelGroup'
import openWindowTintPanel from '../../../actionCreators/editorPanels/openWindowTintPanel'
import closeWindowTintPanel from '../../../actionCreators/editorPanels/closeWindowTintPanel'
import openAccentPanel from '../../../actionCreators/editorPanels/openAccentPanel'
import closeAccentPanel from '../../../actionCreators/editorPanels/closeAccentPanel'
import EditorPanelState from '../../../store/models/EditorPanelState'
import setShareImageData from '../../../actionCreators/setShareImageData'
import recordFrameDuration from '../../../actionCreators/recordFrameDuration'
import recordRenderError from '../../../actionCreators/recordRenderError'
import IMaterial from '../../../../../../core/materials/IMaterial'
import EnvironmentConfig from '../../../../../../core/EnvironmentConfig'

interface OwnProps { }
interface ReduxProps {
  environmentConfig: EnvironmentConfig,
  temporarySelections: UserSelections,
  selections: UserSelections,
  selectedPanelGroup: PanelGroup,
  editorPanelState: EditorPanelState,
  defaultWindowTint: IMaterial,
  defaultWrap: IMaterial,
  needsShareImageData: boolean,
  renderingPaused: boolean
  isPortrait: boolean
}
interface DispatchProps {
  selectPanelGroup: (group:PanelGroup) => void,
  deselectPanelGroup: () => void,
  openAccentPanel: () => void,
  closeAccentPanel: () => void,
  openWindowTintPanel: () => void,
  closeWindowTintPanel: () => void,
  setShareImageData: (data:any, previewData:any) => void
  recordFrameDuration: (durationInMilliseconds:number) => void
  recordRenderError: (error:any) => void
}

// We keep this outside the component because we want to re-use it. Don't create a new one every time the component loads/unloads.
const _controller:ThreeController = new ThreeController()

type Props = OwnProps & ReduxProps & DispatchProps

// This class exists to spoonfeed the ThreeController a handle on the latest user scene selections,
// and also listen for events that come from the rendering.
class ThreeRenderer extends React.Component<Props, {}> {

	componentDidMount = () => {
    _controller.setup({
      selectPanelGroup: this.props.selectPanelGroup,
      deselectPanelGroup: this.props.deselectPanelGroup,
      openWindowTintPanel: this.props.openWindowTintPanel,
      closeWindowTintPanel: this.props.closeWindowTintPanel,
      openAccentPanel: this.props.openAccentPanel,
      closeAccentPanel: this.props.closeWindowTintPanel,
      setShareImageData: this.props.setShareImageData,
      recordFrameDuration: this.props.recordFrameDuration,
      recordRenderError: this.props.recordRenderError
    })
    _controller.start()
	}

	componentWillUnmount = () => {
		_controller.pause()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.renderingPaused) {
      _controller.pause()
    } else {
      _controller.unpause()
    }
  }

  render = () => {
    if (!this.props.temporarySelections.hasEnoughToRender() || !this.props.selections.hasEnoughToRender()) {
      return null
    }
    
    const props = {
      environmentConfig: this.props.environmentConfig,
      selections: this.props.selections,
      temporarySelections: this.props.temporarySelections,
      selectedPanelGroup: this.props.selectedPanelGroup,
      editorPanelState: this.props.editorPanelState,
      needsShareImageData: this.props.needsShareImageData,
      defaultWrap: this.props.defaultWrap,
      defaultTint: this.props.defaultWindowTint,
      isPortrait: this.props.isPortrait
    }
    _controller.setProperties(props)

    return <React.Fragment></React.Fragment>
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      environmentConfig: selectors.getEnvironmentConfig(state),
      temporarySelections: selectors.getAllTemporarySelections(state),
      selections: selectors.getAllUserSelections(state),
      selectedPanelGroup: selectors.getSelectedPanelGroup(state),
      editorPanelState: selectors.getEditorPanelState(state),
      defaultWindowTint: selectors.getDefaultWindowTint(state),
      defaultWrap: selectors.getDefaultWrap(state),
      needsShareImageData: selectors.needsShareImageData(state),
      renderingPaused: selectors.isRenderingPaused(state),
      isPortrait: selectors.isPortrait(state)
    }
  },
  {
    selectPanelGroup, deselectPanelGroup, openWindowTintPanel, closeWindowTintPanel, openAccentPanel, closeAccentPanel, setShareImageData, recordFrameDuration, recordRenderError
  }
)(ThreeRenderer)