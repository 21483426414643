import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import SharingUtility from '../../../../core/SharingUtility'
import Axios from 'axios'
import FetchState from '../store/models/FetchState'
import waitFor from '../../../../core/utils/waitFor'

export default (imageData:string, previewData:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {

    if (!imageData) {
      console.warn('setShareImageData called, but no image data supplied! Ignoring.')
      return
    }

    dispatch({
      type: 'SET_SHARE_IMAGE_STATE',
      state: FetchState.Loading
    })

    const state = getState()
    const selections = selectors.getAllUserSelections(state)
    const postUrl = selectors.getEnvironmentConfig(state).getShareImageUploadUrl()

    const shareCode = SharingUtility.createShareCode(selections)
    const previewShareCode = SharingUtility.createShareCode(selections, true)

    const fileName = shareCode + '.jpg'
    const previewFileName = previewShareCode + '.jpg'

    const shareImageUrl = selectors.getEnvironmentConfig(state).getShareImageUrl(fileName)
    const previewShareImageUrl = selectors.getEnvironmentConfig(state).getShareImageUrl(previewFileName)

    console.log('postUrl: ', postUrl)
    console.log('shareImageUrl: ', shareImageUrl, previewShareImageUrl)

    Promise.all([
      tryToUploadShareImage(postUrl, fileName, imageData),
      tryToUploadShareImage(postUrl, previewFileName, previewData)
    ])
    .catch(() => {
      dispatch({
        type: 'SET_SHARE_IMAGE_STATE',
        state: FetchState.Failure
      })
    })
    .then( async () => {
      await waitFor(100) // Give the server a little time to relax before downloading the image. Probably not necessary, but we were seeing some weird caching and this seemed to help.
      dispatch({
        type: 'SET_SHARE_IMAGE_DATA',
        url: applyCacheBusting(shareImageUrl), 
        previewUrl: applyCacheBusting(previewShareImageUrl)
      })
    })
  }
}

const applyCacheBusting = (url:string):string => {
  // Ran into some weird caching bugs where old images were getting served. Tried handling it on the server-side, and that didn't fix, so we additionally ALSO cache-bust on the client-side.
  return url + "?cacheBust=" + encodeURIComponent(new Date().getTime().toString())
}

const tryToUploadShareImage = async (postURL:string, fileName:string, imageData:string):Promise<any> => {
  return new Promise((resolve, reject) => {
    const POST_CONFIG = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }

    const data = new FormData()
    data.append('filename', fileName)
    data.append('file', imageData)

    Axios.post(postURL, data, POST_CONFIG)
    .then( (response) => {
      if (!response.data.file_uploaded ) {
        reject()
      }

      resolve(undefined)
    })
    .catch( () => {
      reject()
    })
  })
  
}