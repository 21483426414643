import Maybe from '../../../../../core/Maybe'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import Wrap from '../../../../../core/wraps/Wrap'
import AppState from '../../store/models/AppState'
import Modal from '../../store/models/Modal'
import selectors from '../../store/selectors'
import checkSelectionsForLoading from '../checkSelectionsForLoading'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import openModal from '../modals/openModal'
import temporarilySelectWrap from '../wraps/temporarilySelectWrap'

const selectWrap = (wrap:Maybe<Wrap>) => {
  return async (dispatch:Dispatch, getState:GetState):Promise<void> => {
		const state:AppState = getState()
		const panelGroup:Maybe<PanelGroup> = selectors.getSelectedPanelGroup(state)

    if (!wrap) { // If the user is deselecting the wrap, then no need to check for conflicts.
      dispatch({ type:'SELECT_WRAP', panelGroup:panelGroup, wrap:null })
      dispatch(checkSelectionsForLoading())
      return
    }

    let hasConflicts:boolean = false
    if (!panelGroup) { // Then ENTIRE_CAR is selected. We need to check all of the other selections to see if anything conflicts.
      const wraps:Wrap[] = selectors.getAllSelectedWraps(state)
      const conflicts:Wrap[] = wraps.filter((existing:Wrap):boolean => {
        return existing.meta.area !== wrap.meta.area
      })
      hasConflicts = conflicts.length > 0
    }
    else {
      const existing:Maybe<Wrap> = selectors.getSelectedWrapForPanelGroup(state, panelGroup)
      if (existing && wrap) {
        if (existing.meta.area !== wrap.meta.area) {
          hasConflicts = true
        }
      }
    }

    if (hasConflicts) {
      dispatch(temporarilySelectWrap(wrap))
      dispatch(openModal(Modal.ConfirmWrap))
      return
    }

		dispatch({ type:'SELECT_WRAP', panelGroup:panelGroup, wrap:wrap })
		dispatch(checkSelectionsForLoading())
  }
}

export default selectWrap