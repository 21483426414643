import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import setEditorPanelState from '../../../../actionCreators/editorPanels/setEditorPanelState'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import MobileFilterMenu from '../MobileFilterMenu'
import MobilePanelHeader from '../MobilePanelHeader'

interface HeaderSelectionOptions {
  title: string
  values: Array<string>
  selectedValue: string
  onHeaderOptionSelected: (option:Maybe<string>) => any
}

interface OwnProps {
  className?: string
  title: string
  iconClassName: string
  icon: any
  headerOptions?:Array<HeaderSelectionOptions>
  footerText?:string
  children: any
}

interface ReduxProps {
  
}

interface DispatchProps {
  setEditorPanelState: (state: EditorPanelState) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

interface State {}

class MobileAssetSelectorPanel extends React.Component<Props, State> {
  onBackSelected = () => {
    this.props.setEditorPanelState(EditorPanelState.Closed)
  }

  render() {
    return (
      <div className={cx('MobileAssetSelectorPanel', this.props.className)}>
        <MobilePanelHeader
          type={this.props.iconClassName}
          title={this.props.title}
          icon={this.props.icon}
          onBackClick={this.onBackSelected}
        />

        <div className="MobileAssetSelectorPanel--inner">
          {this.props.headerOptions && (
            <div className='MobileAssetSelectorPanel--dropdowns'>
              {this.props.headerOptions.map( (headerOption, index) => {
                const left = index === 0 ? '0' : '50%'
                return (
                  <MobileFilterMenu
                    allLabel={headerOption.title}
                    items={headerOption.values.map( (value) => {
                      return { label: value, value: value }
                    })}
                    selected={headerOption.selectedValue}
                    onSelect={headerOption.onHeaderOptionSelected}
                    left={left}
                  />
                )
              })}
            </div>
          )}
              
          {this.props.children}

          {this.props.footerText && (
            <div className='MobileAssetSelectorPanel--footer panel-group-selector--mobile--footer'>
              {this.props.footerText}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  (state: AppState): ReduxProps => {
    return {}
  },
  {
    setEditorPanelState
  }
)(MobileAssetSelectorPanel)