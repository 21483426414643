import * as React from 'react'

let styles = {
  stroke: {
    fill: 'none',
    stroke: 'currentColor'
  }
}

export default function(): React.ReactElement<{}> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      width="27"
      height="27"
    >
      <path
        style={styles.stroke}
        d="M18.5,11.5a7,7,0,0,0-7-7h0a7,7,0,0,0-7,7h0a7,7,0,0,0,7,7h0a7,7,0,0,0,7-7Z"
      />
      <line style={styles.stroke} x1="11.5" y1="20.5" x2="11.5" y2="22.5" />
      <line style={styles.stroke} x1="20.5" y1="11.5" x2="22.5" y2="11.5" />
      <line style={styles.stroke} x1="11.5" y1="2.5" x2="11.5" y2="0.5" />
      <line style={styles.stroke} x1="2.5" y1="11.5" x2="0.5" y2="11.5" />
      <line style={styles.stroke} x1="5.14" y1="17.86" x2="3.72" y2="19.28" />
      <line style={styles.stroke} x1="17.86" y1="17.86" x2="19.28" y2="19.28" />
      <line style={styles.stroke} x1="17.86" y1="5.14" x2="19.28" y2="3.72" />
      <line style={styles.stroke} x1="5.14" y1="5.14" x2="3.72" y2="3.72" />
    </svg>
  )
}
