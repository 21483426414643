import PhysicalBasedMaterial from './PhysicalBasedMaterial'
import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class RubberTire extends BakedMaterial {
	constructor() {
		super(new THREE.MeshBasicMaterial({
      name: 'RubberTire',
			color: new THREE.Color('#cccccc')
    }), true)
	}
	
	configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
		// Nope. Not using the environment map.
	}
}

export default RubberTire