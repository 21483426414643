// copied from https://github.com/donavon/use-event-listener/blob/a5127d0e7155f6a77acd922d3e4e83fb93bece9f/src/index.js
// modified a little because of TS
import { useRef, useEffect } from 'react'

const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef(handler)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export default useEventListener
