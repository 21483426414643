import { useRect } from '@reach/rect'
import cx from 'classnames'
import React, { Fragment, RefObject, useRef, useState } from 'react'
import DesktopScrollBar from '../DesktopScrollBar/DesktopScrollBar'
import Maybe from '../../../../../../../core/Maybe'

interface Props {
  className?: string
  children: React.ReactNode
}

const DesktopScrollArea = (props:Props) => {
  const containerRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  const containerArea:Maybe<DOMRect> = useRect(containerRef)
  const containerWidth:number = (containerArea ? Math.ceil(containerArea.width) : 0)

  const childRef:RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  const childArea:Maybe<DOMRect> = useRect(childRef)
  const childWidth:number = (childArea ? Math.ceil(childArea.width) : 0)
  
  const [scrollTo, setScrollTo] = useState<number>(0)
  const [childX, setChildX] = useState<number>(0)

  const onScrollBarScroll = (x:number) => {
    const width:number = childWidth - containerWidth
    const childX:number = x * width * -1
    
    setScrollTo(x)
    setChildX(childX)
  }

  const canScroll:boolean = childWidth > containerWidth
  const handleWidth:number = (containerWidth / childWidth) * containerWidth

  return (
    <div
      ref={containerRef}
      className={cx(
        'DesktopScrollArea',
        props.className,
        !canScroll && 'DesktopScrollArea--noScroll',
        scrollTo <= 0 && 'DesktopScrollArea--min',
        scrollTo >= 1 && 'DesktopScrollArea--max'
      )}
      data-width={containerWidth}
      data-handle-width={handleWidth}
      data-child-width={childWidth}
    >
      <div
        ref={childRef}
        className='DesktopScrollArea--children'
        style={{ transform:'translateX(' + childX + 'px)'}}
      >
        {props.children}
      </div>

      {canScroll && (
        <Fragment>
          <div className='DesktopScrollArea--leftFade' />
          <div className='DesktopScrollArea--rightFade' />

          <div className='DesktopScrollArea--scrollBar'>
            <DesktopScrollBar
              width={containerWidth}
              handleWidth={handleWidth}
              onScroll={onScrollBarScroll}
            />
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default DesktopScrollArea