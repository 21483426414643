import Dispatch from './Dispatch'
import GetState from './GetState'
import removeAllVehicleSelections from './removeAllVehicleSelections'
import View from '../store/models/View'
import navigateTo from './navigateTo'
import SessionManager from '../helpers/SessionManager'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(removeAllVehicleSelections())
    dispatch(navigateTo(View.VehicleSelection, true))
    SessionManager.clearSavedSelectionCode() // VERY important that this goes AFTER all of the previous dispatches. Otherwise, the other actions could reset the saved selection code!
  }
}