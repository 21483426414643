import * as React from 'react'
import { ForwardedRef, Fragment, useEffect } from 'react'
import { animated, config as springConfig, useSpring } from 'react-spring'
import { clamp } from '../../../../helpers/math'
import useGesture from '../../../../helpers/react-use-gesture'

interface OwnProps {
  /**
   * How wide the entire bar should be
   */
  width: number

  /**
   * How wide the handle bar should be.
   */
  handleWidth: number

  /**
   * Called when the user changes the scroll value.
   */
  onScroll: (value:number) => any
}

const DesktopScrollBar = React.forwardRef(
  (props:OwnProps, ref:ForwardedRef<any>) => {
    const [hovered, setHovered] = React.useState(false)
    const [dragging, setDragging] = React.useState(false)

    const [{ x }, setX] = useSpring(() => ({
      x: 0,
      config: springConfig.stiff
    }))

    if (ref) {
      ;(ref as any).current = {
        setX: (x) => {
          setX({ x:x })
        }
      }
    }

    const containerRef = React.useRef(null)

    const bind = (useGesture as any)({
      onDown: () => setDragging(true),
      onUp: () => {
        setDragging(false)

        if (x.get() < 0.1) {
          setX({ x: 0 })
          props.onScroll(0)
        } else if (x.get() > 0.9) {
          let newX = 1
          setX({ x: newX })
          props.onScroll(newX)
        }
      },
      onAction: ({ first, down, delta: [deltaX], temp = 0, initial }) => {
        if (first && containerRef.current && containerRef.current.getBoundingClientRect) {
          let initialPx =
            initial[0] -
            containerRef.current.getBoundingClientRect().left - (props.handleWidth / 2)
          let x = clamp(0, 1, initialPx / (props.width - props.handleWidth))
          setX({ x, immediate: false })
          props.onScroll(x)
          return initialPx
        }

        let x = clamp(0, 1, (temp + deltaX) / (props.width - props.handleWidth))
        setX({ x, immediate: down })
        props.onScroll(x)
        return temp
      }
    })

    useEffect(
      () => {
        setX({ x:0, immediate:true })
      },
      [props.width]
    )

    let handleTransform = x.to(
      (x:number) => {
        return `translate(${x * (props.width - props.handleWidth)}px, -50%)`
      }
    )

    return (
      <div
        ref={containerRef}
        className='DesktopScrollBar'
        style={{ width: props.width }}
        {...bind()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className='DesktopScrollBar--inner' />
        
        {props.handleWidth > 0 && (
          <Fragment>
            <animated.div
              key='handle'
              className='DesktopScrollBar--handle'
              style={{
                transform: handleTransform,
                width: props.handleWidth,
              }}
            />
            <animated.div
              key="handle-display"
              className='DesktopScrollbar--handleDisplay'
              style={{
                transform: handleTransform,
                height: dragging || hovered ? 8 : 4,
                width: props.handleWidth,
                background: dragging ? 'red' : 'black',
              }}
            />
          </Fragment>
        )}
      </div>
    )
  }
)

export default DesktopScrollBar