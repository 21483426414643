import Maybe from '../../../../core/Maybe'
import AppState from '../store/models/AppState'
import selectors from '../store/selectors'
import Dispatch from './Dispatch'
import GetState from './GetState'

export default (finishGroup:Maybe<string>) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		if (!finishGroup) { // None is a valid value, as that defaults us to All Materials
			dispatch({ type:'SELECT_FINISH_GROUP', finishGroup:null })
			return
		}

		const state:AppState = getState()
		const finishGroups:string[] = selectors.getWrapFinishGroups(state)
		if (finishGroups.indexOf(finishGroup) < 0) {
			console.warn('selectFinishGroup called, but supplied finishGroup `' + finishGroup + '` was not in the list. Ignoring.')
			return
		}

    dispatch({ type:'SELECT_FINISH_GROUP', finishGroup:finishGroup })
  }
}