import { useRect } from '@reach/rect'
import cx from 'classnames'
import * as React from 'react'
import { animated, Interpolation, SpringValue, useSpring } from 'react-spring'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import { clamp, remap } from '../../helpers/math'
import useGesture from '../../helpers/react-use-gesture'
import TranslationComponent from '../TranslationComponent'
import ArrowIcon from '../icons/Arrow'

const DEGREES_BETWEEN:number = 32

export default function VehicleWheel(props: {
  basePath: string,
  vehicles: Array<Vehicle>
  selectedVehicle: Vehicle
  onSelect: (vehicle: Vehicle) => void
  onClose: () => void
  openSpring: SpringValue<number>
  desktop: boolean
  isiOS: boolean
}) {
  const selectedIndex:number = props.vehicles.findIndex(
    (v) => v === props.selectedVehicle
  )
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(selectedIndex)
  const [wheelSpring, setWheelSpring] = useSpring(() => ({
    deg: selectedIndex / (props.vehicles.length - 1)
  }))
  const performingGesture = React.useRef<boolean>(false)

  const settleSpring = (index:number) => {
    if (!isNaN(index) && index !== highlightedIndex) {
      setHighlightedIndex(index)
    }
    setWheelSpring({
      deg:
        (!isNaN(index) ? index : highlightedIndex) /
        (props.vehicles.length - 1),
      immediate: false
    })
  }

  /*
    I really want to combine these functions, like:

      let onGesture = ...
      let onEnd = ...
      useGesture({
        onWheel: onGesture,
        onWheelEnd: onEnd,
        onDrag: onGesture,
        onDragEnd: onEnd
      })

    However, every time I do this, it seems to break everything.
    If you're braver than I was, try it—but you've been warned.
  */
  let bindGesture = (useGesture as any)({
    wheel: props.desktop,
    drag: !props.desktop,
    onWheel: ({
      delta: [_, deltaY],
      temp = wheelSpring.deg.get(),
      first
    }) => {
      if (first) {
        if (performingGesture.current) return
        performingGesture.current = true
      }
      let deg = clamp(0, 1, temp + deltaY / 200)
      let highlightedIndex = clamp(
        0,
        props.vehicles.length - 1,
        Math.floor(deg * props.vehicles.length)
      )
      setHighlightedIndex(highlightedIndex)
      setWheelSpring({ deg, immediate: true })
      return temp
    },
    onDrag: ({
      delta: [_, deltaY],
      temp = wheelSpring.deg.get(),
      first
    }) => {
      if ( props.isiOS ) return

      if (first) {
        if (performingGesture.current) return
        performingGesture.current = true
      }
      let deg = clamp(0, 1, temp + deltaY / -200)
      let highlightedIndex = clamp(
        0,
        props.vehicles.length - 1,
        Math.floor(deg * props.vehicles.length)
      )
      setHighlightedIndex(highlightedIndex)
      setWheelSpring({ deg, immediate: true })
      return temp
    },
    onDragEnd: () => {
      if ( props.isiOS ) return
      
      if (performingGesture.current) {
        settleSpring(NaN)
        performingGesture.current = false
      }
    },
    onWheelEnd: () => {
      if (performingGesture.current) {
        settleSpring(NaN)
        performingGesture.current = false
      }
    }
  })

  let rotationStart = 0
  let rotationStop = DEGREES_BETWEEN * (props.vehicles.length - 1)

  return (
    <div
      className='change-vehicle-wheel'
      {...bindGesture()}
    >
      <button 
          className='change-vehicle-wheel--back' 
          onClick={() => { 
            props.onClose()
          }}
        >
          <ArrowIcon direction='left' width={30} />
        </button>

      <svg className='scrim-circle scrim-circle--left'>
        <animated.circle
          cx='-100'
          cy={window.innerHeight / 2}
          r={props.openSpring.to((v:number) => clamp(0, Infinity, v * 768))}
          fill='white'
          style={{ pointerEvents: 'auto' }}
        />
        <animated.circle
          cx='-100'
          cy={window.innerHeight / 2}
          r={props.openSpring.to((v:number) => clamp(0, Infinity, v * 746))}
          fill='none'
          stroke='#ddd'
          style={{ pointerEvents: 'auto' }}
        />
      </svg>

      {props.vehicles.map((vehicle:Vehicle, index:number) => {
        const deg = index * DEGREES_BETWEEN
        const highlighted = (highlightedIndex === index)

        return (
          <Spoke
            key={vehicle.shareIdentifier} 
            basePath={props.basePath}
            desktop={props.desktop}
            onClick={
              highlighted
                ? () => {
                    if (!performingGesture.current) {
                      props.onSelect(vehicle)
                    }
                  }
                : () => {
                    if (!performingGesture.current) {
                      settleSpring(index)
                    }
                  }
            }
            vehicle={vehicle}
            highlighted={highlighted}
            transform={wheelSpring.deg.to((v) => {
              let wheelRotation = remap(0, 1, rotationStart, rotationStop, v)
              let degrees = deg - wheelRotation
              return `translate(-100px, 0%) rotate(${degrees}deg) translateX(100px)`
            })}
            number={index + 1}
          />
        )
      })}
    </div>
  )
}

function Spoke(props: {
  basePath: string
  desktop: boolean
  vehicle: Vehicle
  transform: Interpolation<number, string>
  number: number
  highlighted: boolean
  onClick: () => void
}) {
  const spring = useSpring({ v: props.highlighted ? 1 : 0 })
  const labelRef = React.useRef(null)
  const labelRect = useRect(labelRef)
  const redLineX1:number = labelRect ? labelRect.width : 0

  return (
    <animated.div
      className={cx('change-vehicle-wheel--spoke', props.highlighted && 'highlighted')}
      style={{ transform: props.transform }}
      onClick={props.onClick}
    >
      <animated.div
        style={{
          opacity: spring.v,
          transform: spring.v.to(
            (v:number) => `translateX(${(1 - v) * -15}px)`
          )
        }}
      >
        <div className='change-vehicle-wheel--spoke__number'>
          {'0' + props.number}
        </div>

        <div className='change-vehicle-wheel--spoke__name' ref={labelRef}>
          <TranslationComponent localizationKey={props.vehicle.nameLocalizationKey} />
        </div>
      </animated.div>

      <img
        className='change-vehicle-wheel--spoke__image'
        src={props.basePath+props.vehicle.previewImageURL}
      />

      <svg className='change-vehicle-wheel--spoke__line'>
        <animated.line
          x1={redLineX1}
          x2={spring.v.to((v:number) =>
            remap(0.5, 1, redLineX1, 576, clamp(0.5, 1, v))
          )}
          y1='0'
          y2='0'
          stroke='red'
          strokeWidth='2'
        />
      </svg>

      <svg height='34' width='34'>
        <animated.circle
          cx='17'
          cy='17'
          r={spring.v.to((v:number) => remap(0, 1, 0, 16.5, v))}
          fill='white'
          stroke='#e1e1e1'
        />
        <animated.circle
          cx='17'
          cy='17'
          r={spring.v.to((v:number) => remap(0, 1, 0, 7, v))}
          fill='red'
        />
      </svg>
    </animated.div>
  )
}
