import * as React from 'react'
import { animated, SpringValue } from 'react-spring'

export default function Scrim(props: {
  show: boolean
  openSpring: SpringValue<number>
  onClick: () => void
}) {
  return (
    <animated.div
      onClick={props.onClick}
      className="scrim"
      style={{
        opacity: props.openSpring,
        pointerEvents: props.show ? 'all' : 'none'
      }}
    />
  )
}