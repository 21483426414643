import AssetLibrary from "./AssetLibrary"
import EnvironmentConfig from "./EnvironmentConfig"
import * as _ from 'underscore'

export enum Environment {
	Development = 'development',
	Staging = 'staging',
	Production = 'production'
}

export const determineEnvironment = ():Environment => {
	if (!window || !window.location || !window.location.hostname) {
		// It's safest to assume we're running in production. That way if things go wrong, we won't accidentally point the production environment to a development or staging configuration.
		return Environment.Production
	}

	const hostname = window.location.hostname.toLowerCase()
	let environment = Environment.Production
	switch(hostname) {
		case 'localhost':
		case 'fuzeauth.3m.com':
			environment = Environment.Development
			break
    case 'restyling-studio-2024-dev-8eea82c0c4ed.herokuapp.com':
			environment = Environment.Staging
			break
	}

	console.log('Environment for `' + hostname + '` set to `' + environment + '`')
	return environment
}

class EnvironmentConfigLoader {
	load = async (url:string):Promise<EnvironmentConfig> => {
		const data = await AssetLibrary.loadJson(url)
		if (!data) {
			return null
		}

		const environment = determineEnvironment()
		if (!data[environment]) {
			throw new Error('Loaded JSON did not have a `'+ environment +'` element.')
		}

		return new EnvironmentConfig(data[environment])
	}
}

export default EnvironmentConfigLoader