

import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'
import UserSelectionsLoader from '../../../../core/UserSelectionsLoader'
import waitFor from '../../../../core/utils/waitFor'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
		const selections = selectors.getAllUserSelections(state)
		
		const defaultTint = selectors.getDefaultWindowTint(state)
		const defaultWrap = selectors.getDefaultWrap(state)

		const environmentConfig = selectors.getEnvironmentConfig(state)
		
		let done = false
		UserSelectionsLoader.load(
			environmentConfig.getAssetBasePath(), 
			selections, 
			defaultTint,
			defaultWrap
		).then( () =>{
			done = true
		})

		while(!done) {
			dispatch({ type: 'CHECK_SELECTIONS_FOR_LOADED' })
			await waitFor(100)
		}

		dispatch({ type: 'CHECK_SELECTIONS_FOR_LOADED' })
  }
}