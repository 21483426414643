import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'

export default (durationInMilliseconds:number) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'RECORD_FRAME_DURATION',
      durationInMilliseconds: durationInMilliseconds
    })
  }
}