import cx from 'classnames'
import * as React from 'react'
import { animated } from 'react-spring'
import TranslationComponent from '../../../TranslationComponent'
import ArrowIcon from '../../../icons/Arrow'

interface Props {
  onClick: () => void
  side: 'left' | 'right'
  i18nKey: string
}

const DesktopOpenButton = (props:Props) => {
  const [hovered, setHovered] = React.useState(false)
  
  const Text = (
    <span className={cx('DesktopOpenButton--text cvs-open-button__text', props.side === 'right' && 'yd')}>
      <TranslationComponent localizationKey={props.i18nKey} />
    </span>
  )

  const Arrow = (
    <div className={cx('DesktopOpenButton--arrow cvs-open-button__button__svg-wrapper')}>
      <ArrowIcon
        direction={props.side === 'left' ? 'right' : 'left'}
        forceWidth={23}
        forceHeight={23}
      />
    </div>
  )

  const renderFirstChild = () => {
    if (props.side === 'right') {
      return Text
    }

    return Arrow
  }

  const renderLastChild = () => {
    if (props.side === 'right') {
      return Arrow
    }

    return Text
  }

  return (
    <div
      className={cx(
        'DesktopOpenButton cvs-open-button',
        'DesktopOpenButton--' + props.side,
        hovered && 'DesktopOpenButton--hover'
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <button
        className='cvs-open-button__button'
        onClick={props.onClick}
      >
        {renderFirstChild()}
        {renderLastChild()}
      </button>
    </div>
  )
}

export default DesktopOpenButton