import Dispatch from './Dispatch'
import GetState from './GetState'
import { type i18n } from 'i18next'

export default (instance:i18n, languageKey:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    instance.changeLanguage(languageKey)
		dispatch({
			type: 'SET_LANGUAGE',
			language: instance
		})
  }
}