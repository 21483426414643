import * as React from 'react'
import cx from 'classnames'

import ArrowIcon from './icons/Arrow'

type Props = {
  className?:string,
  onClick: () => void
  width?:number
}

export default function({ onClick, className, width=44, ...rest }:Props) {
  const height = width // Button should always be square dimensions

  return (
    <div 
      className={cx("btn--back", className)}
      style={{
        width: width,
        height: height,
        borderRadius: width / 2
      }}
      {...rest}
    >
      <button 
        onClick={onClick} 
        style={{
          width: width,
          height: height
        }}
        {...rest}
      >
        <span>
          <ArrowIcon direction="left" width={width * 0.35} />
        </span>
      </button>
    </div>
  )
}



