import cx from 'classnames'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import * as _ from 'underscore'
import EnvironmentConfig from '../../../core/EnvironmentConfig'
import Maybe from '../../../core/Maybe'
import checkViewportSize from './actionCreators/checkViewportSize'
import startup from './actionCreators/startup'
import ThreeRenderer from './components/editor/rendering/ThreeRenderer'
import Footer from './components/Footer'
import Header from './components/Header'
import ConfirmWrapModal from './components/modals/ConfirmWrapModal'
import LanguageSelectionModal from './components/modals/LanguageSelectionModal'
import ShareView from './components/modals/ShareModal'
import FinaleView from './components/views/FinaleView'
import PreloadingView from './components/views/PreloadingView'
import VehicleEditView from './components/views/VehicleEditView'
import VehicleSelectionView from './components/views/VehicleSelectionView'
import TealiumModalMonitor from './helpers/TealiumModalMonitor'
import AppState from './store/models/AppState'
import Modal from './store/models/Modal'
import View from './store/models/View'
import selectors from './store/selectors/index'

interface OwnProps {}

interface ReduxProps {
  currentView: View,
  modals: Array<Modal>,
  translate: (key: string) => string,
  isOnVehicleSelectionView: boolean,
  isDayMode: boolean,
  isiOS: boolean
  environmentConfig: Maybe<EnvironmentConfig>
  hasLanguageLoaded: boolean
}

interface DispatchProps {
  startup: () => Promise<void>
  checkViewportSize: () => void
}

interface State {}

class App extends React.Component<
  OwnProps & ReduxProps & DispatchProps,
  State
> {

  componentDidMount() {
    this.props.startup()

    this._onWindowResize()
    window.onresize = this._onWindowResize

    TealiumModalMonitor.start()
  }

  _onWindowResize = () => {
    this.props.checkViewportSize()
  }

  render() {
    if (!this.props.hasLanguageLoaded) {
      /*
        Do not render ANYTHING until the language has been loaded.
        All of our components rely on translations being available.
      */
      return null
    }

    const view = this._getComponentForView(this.props.currentView)
    const modals = this._getModalComponents()
    
    return (
      <div className={cx(
        'app',
        this.props.isDayMode && 'day-mode',
        this.props.isiOS && 'iOS',
        this.props.currentView === View.VehicleEdit && 'app--vehicleEditView',
        this.props.translate('global.useLongWordLayout') === 'true' && 'app--long-word-layout'
      )}>
        {this.props.environmentConfig && (
          <Helmet>
            <link rel="stylesheet" href={this.props.environmentConfig.getAssetFullPath('assets/css/application.css')} />
          </Helmet>
        )}
        
        <canvas id="canvas" />
        <ThreeRenderer />
        {view && (
          <React.Fragment>
            {view}
            <Header />
            {this.props.isOnVehicleSelectionView && <Footer />}
          </React.Fragment>
        )}
        {modals}
        <PreloadingView />
      </div>
    )
  }

  _getComponentForView(view:View) {
    switch (view) {
      case View.VehicleSelection:
        return <VehicleSelectionView />
      case View.VehicleEdit:
        return <VehicleEditView />
      case View.Finale:
        return <FinaleView />
      default:
        return null
    }
  }

  _getModalComponents() {
    if (!this.props.modals || this.props.modals.length <= 0) {
      return []
    }

    return _.map(this.props.modals, (modal) => {
      const modalComponent = this._getComponentForModal(modal)
      if (!modalComponent) {
        console.warn('App _getComponentForModal returned null! Unrecognized modal `' + modal + '`')
        return null
      }

      return modalComponent
    })
  }

  _getComponentForModal(modal:Modal) {
    switch(modal) {
      case Modal.Share:
        return <ShareView />
      case Modal.LanguageSelection:
        return <LanguageSelectionModal />
      case Modal.ConfirmWrap:
        return <ConfirmWrapModal />
      default:
        console.warn('App recieved an unknown Modal `' + modal + '`!')
        return null
    }
  }
}

export default connect(
  (state: AppState): ReduxProps => ({
    currentView: selectors.getCurrentView(state),
    modals: selectors.getAllOpenModals(state),
    translate: selectors.getTranslator(state),
    hasLanguageLoaded: selectors.hasLanguageLoaded(state),
    isOnVehicleSelectionView: selectors.isOnVehicleSelectionView(state),
    isDayMode: selectors.isOnDayMode(state),
    isiOS: selectors.isiOS(state),
    environmentConfig: selectors.getEnvironmentConfig(state)
  }),
  {
    startup,
    checkViewportSize
  }
)(App)