import cx from 'classnames'
import * as React from 'react'
import Maybe from '../../../../../../../core/Maybe'
import ArrowIcon from '../../../icons/Arrow'

type MobileFilterMenuItem<V> = {
  label: string
  value: V
}

type MobileFilterMenuProps<V> = {
  allLabel: string
  items: Array<MobileFilterMenuItem<V>>
  selected: V
  onSelect: (value:Maybe<V>) => void
  left: string
}

function MobileFilterMenu<V>(props: MobileFilterMenuProps<V>) {
  const [hovered, setHovered] = React.useState<boolean>(false)
  const selectedItem:Maybe<MobileFilterMenuItem<V>> = props.items.find((item:MobileFilterMenuItem<V>) => {
    return item.value === props.selected
  })

  return (
    <div
      className={cx('MobileFilterMenu', hovered && 'MobileFilterMenu--hovered')}
      style={{ left: props.left }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ul className={cx('v-list')}>
        <li className='MobileFilterMenu--title'>
          {hovered ? (
            <button
              className={cx(selectedItem && 'MobileFilterMenu--selected')}
              onClick={() => {
                props.onSelect(null)
                setHovered(false)
              }}
            >
              {props.allLabel}
            </button>
          ) : (
            <button
              className='MobileFilterMenu--selected'
              onClick={() => {
                setHovered(true)
              }}
            >
              {selectedItem && !hovered ? selectedItem.label : props.allLabel}
            </button>
          )}
            <span className='MobileFilterMenu--icon'>
              <ArrowIcon direction={hovered ? 'up' : 'down'} width={14} />
            </span>
        </li>
        {props.items.map((item) => {
          return (
            <li
              key={item.label + item.value}
              className={cx(
                'MobileFilterMenu--entry',
                selectedItem && selectedItem.label === item.label && 'MobileFilterMenu--selected'
              )}
            >
              <button
                className={cx(selectedItem && selectedItem.label === item.label && 'MobileFilterMenu--selected')}
                onClick={() => {
                  props.onSelect(item.value)
                  setHovered(false)
                }}
              >
                {item.label}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MobileFilterMenu