import * as React from 'react'

const WIDTH = 43.31
const HEIGHT = 43.31
const ASPECT_RATIO = HEIGHT / WIDTH

interface Props {
	width?:number
}

export default function Twitter({ width=43 }:Props) {
	const height = width * ASPECT_RATIO
  return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 43.31 43.31"
			width={width} height={height}
		>
			<g>
				<path fill="currentColor" d="M21.66,0A21.66,21.66,0,1,0,43.31,21.66,21.68,21.68,0,0,0,21.66,0ZM32,17.9c0,.2,0,.41,0,.62A13.62,13.62,0,0,1,11.06,30a9.63,9.63,0,0,0,7.08-2,4.82,4.82,0,0,1-4.47-3.33,4.77,4.77,0,0,0,2.16-.08A4.79,4.79,0,0,1,12,19.91v-.07a4.73,4.73,0,0,0,2.17.6,4.79,4.79,0,0,1-1.48-6.39,13.58,13.58,0,0,0,9.87,5A4.92,4.92,0,0,1,22.43,18a4.78,4.78,0,0,1,8.28-3.27c1.09-.22,3.55-.49,3.55-.49C33.61,15.16,32.94,17.22,32,17.9Z"/>
				<path fill='white' d="M27.21,13.17A4.79,4.79,0,0,0,22.43,18a4.92,4.92,0,0,0,.12,1.09,13.58,13.58,0,0,1-9.87-5,4.79,4.79,0,0,0,1.48,6.39,4.73,4.73,0,0,1-2.17-.6v.07a4.79,4.79,0,0,0,3.84,4.69,4.77,4.77,0,0,1-2.16.08A4.82,4.82,0,0,0,18.14,28a9.63,9.63,0,0,1-7.08,2A13.62,13.62,0,0,0,32,18.52c0-.21,0-.42,0-.62.94-.68,1.61-2.74,2.26-3.7,0,0-2.46.27-3.55.49A4.77,4.77,0,0,0,27.21,13.17Z"/>
			</g>
		</svg>
  )
}
