import { getGPUTier, TierResult } from 'detect-gpu'
import DefaultsFinder from '../../../../core/DefaultsFinder'
import { determineEnvironment, Environment } from '../../../../core/EnvironmentConfigLoader'
import environmentInstance from '../../../../core/environments/EnvironmentInstance'
import FilmsConfigLoader from '../../../../core/films/FilmsConfigLoader'
import PaintProtectionFilm from '../../../../core/films/PaintProtectionFilm'
import IMaterial from '../../../../core/materials/IMaterial'
import partsInstances from '../../../../core/materials/parts/PartsInstances'
import Maybe from '../../../../core/Maybe'
import SharingUtility from '../../../../core/SharingUtility'
import THREE from '../../../../core/three/threeWithExtensions'
import Tint from '../../../../core/tints/Tint'
import TintsConfigLoader from '../../../../core/tints/TintsConfigLoader'
import UserSelections from '../../../../core/UserSelections'
import UserSelectionsLoader from '../../../../core/UserSelectionsLoader'
import doesUrlExist from '../../../../core/utils/doesUrlExist'
import VoidPromise from '../../../../core/utils/VoidPromise'
import Vehicle from '../../../../core/vehicles/Vehicle'
import Wrap from '../../../../core/wraps/Wrap'
import WrapsConfigLoader from '../../../../core/wraps/WrapsConfigLoader'
import View from '../store/models/View'
import selectors from '../store/selectors'
import Dispatch from './Dispatch'
import GetState from './GetState'
import navigateTo from './navigateTo'
import setLoadingError from './setLoadingError'
import setLoadingMessage from './setLoadingMessage'
import showLanguageSelectionIfNecessary from './showLanguageSelectionIfNecessary'

export default (shareCode:string) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()
    const environmentConfig = selectors.getEnvironmentConfig(state)

    dispatch({ type:'SET_IS_PRELOADING', value:true })

    if (determineEnvironment() !== Environment.Development) { // Chrome fails the GPU test when run in Responsive mode. So we don't check GPU in dev.
      dispatch(setLoadingMessage(selectors.translate(state, 'loading.webgl-test')))
      if (!THREE.WebGL.isWebGLAvailable()) {
        dispatch(setLoadingError(selectors.translate(state, 'errors.wegbl')))
        return
      }

      const GPUTier:TierResult = await getGPUTier()
      const performanceBlacklist:string[] = environmentConfig.getPerformanceBlacklist()
      console.log('performanceBlacklist:', performanceBlacklist)
      console.log('GPUTier:', GPUTier)

      const formattedTier:string = GPUTier.isMobile ? 'GPU_MOBILE_TIER_' + GPUTier.tier : 'GPU_DESKTOP_TIER_' + GPUTier.tier
      if (performanceBlacklist.indexOf(formattedTier) >= 0) {
        dispatch(setLoadingError(selectors.translate(state, 'errors.performance')))
        return
      }
    }

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.wraps')))
    const wrapsLoader:WrapsConfigLoader = new WrapsConfigLoader()
    const selectedLanguage:string = selectors.getLanguage(state)
    let wrapsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/wraps.json')
    if (!await doesUrlExist(wrapsPath)) {
      wrapsPath = environmentConfig.getAssetFullPath('assets/js/wraps.json')
    }

		let wraps:Wrap[]|null = null
		try {
			wraps = await wrapsLoader.load(wrapsPath)
		}
		catch(e) {
      console.warn('Unable to load wraps json file from `' + wrapsPath + '`!', e)
    }

    if (!wraps) {
      console.warn('Unable to load wraps! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.wraps')))
			return
    }

    // <preload wrap swatches>
    const swatchPromises:any = []
    for(let i=0; i<wraps.length; i++) {
      const wrap = wraps[i]
      swatchPromises.push(wrap.loadSwatchImages(environmentConfig.getAssetBasePath()))
    }
    VoidPromise.all(swatchPromises)
    // </preload wrap swatches>

		dispatch({ type:'SET_WRAPS', wraps:wraps })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.tints')))
    let tintsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/tints.json')
    if (!await doesUrlExist(tintsPath)) {
      tintsPath = environmentConfig.getAssetFullPath('assets/js/tints.json')
    }

    let tints:Tint[]|null = null
    try {
      const tintsLoader = new TintsConfigLoader()
      tints = await tintsLoader.load(tintsPath)
    }
    catch(e) {
      console.warn('Unable to load tints json file from `' + tintsPath + '`!', e)
    }

    if (!tints) {
      console.warn('Unable to load tints! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.tints')))
			return
    }

    dispatch({ type:'SET_TINTS', tints:tints })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.films')))

    let filmsPath = environmentConfig.getAssetFullPath('assets/js/locales/' + selectedLanguage + '/films.json')
    if (!await doesUrlExist(filmsPath)) {
      filmsPath = environmentConfig.getAssetFullPath('assets/js/films.json')
    }

    let films:PaintProtectionFilm[]|null = null
    try {
      const filmsLoader = new FilmsConfigLoader()
      films = await filmsLoader.load(filmsPath)
    }
    catch(e) {
      console.warn('Unable to load films json file from `' + filmsPath + '`!', e)
    }

    if (!films) {
      console.warn('Unable to load films! Aborting.')
      dispatch(setLoadingError(selectors.translate(state, 'error.films')))
			return
    }

    dispatch({ type:'SET_FILMS', films:films })

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.environment')))
    try {
      await environmentInstance.load(environmentConfig.getAssetBasePath())
    }
    catch(e) {
      console.warn('Unable to load environment! Aborting.', e)
      dispatch(setLoadingError(selectors.translate(state, 'errors.environment')))
      return
    }

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.parts')))
    try {
      await partsInstances.load(environmentConfig.getAssetBasePath())
    }
    catch(e) {
      console.warn('Unable to load environment! Aborting.', e)
      dispatch(setLoadingError(selectors.translate(state, 'errors.parts')))
      return
    }

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.performance-testing')))

    const vehicles:Vehicle[] = selectors.getVehicles(state)
    const defaultTint:IMaterial = DefaultsFinder.getDefaultTint(tints)
    const defaultWrap:IMaterial = DefaultsFinder.getDefaultWrap(wraps)

    let	selections:Maybe<UserSelections> = SharingUtility.selectionsFromCode(shareCode.toString(), vehicles, tints, wraps, films)
		if (!selections) {
      console.warn('Unable to load selections from code `' + shareCode + '`. Reverting to normal path.')
      donePreloading(dispatch, View.VehicleSelection)
			return
		}

    dispatch(setLoadingMessage(selectors.translate(state, 'loading.selections')))
    try {
      await UserSelectionsLoader.load(environmentConfig.getAssetBasePath(), selections, defaultTint, defaultWrap)
      dispatch({ type:'SET_SELECTIONS', selections:selections })
      donePreloading(dispatch, View.VehicleEdit)
    }
    catch(e) {
      console.warn('Unable to load selections from code `' + shareCode + '`. Reverting to normal path.')
      donePreloading(dispatch, View.VehicleSelection)
    }

    dispatch(showLanguageSelectionIfNecessary())
  }
}

const donePreloading = (dispatch: Dispatch, view:any):void => {
  dispatch(navigateTo(view, false))

  // Needed a little delay to give the frames a chance to completely render. This prevents some flickering while the scene loads.
  setTimeout(() => {
    dispatch({ type:'SET_IS_PRELOADING', value:false })
  }, 500)
}