import * as _ from "underscore"
import Maybe from './Maybe'

interface LanguageConfiguration {
  identifier: string
  localizationKey: string
  redirectURLs: string[]
}

interface SharingConfiguration {
  imagePostFullUrl: string
  imageGetBaseUrl: string
}

class EnvironmentConfig {
  private performanceBlacklist: string[]
  private sharing: SharingConfiguration
  private assetBasePath: string
  private languages: LanguageConfiguration[]

  private enablePaintProtectionFilms: boolean
  private enableProtectionWrapFilms: boolean

  constructor(options: any) {
    if (!options.performanceBlacklist) {
      throw new Error("performanceBlacklist not found on environment config!")
    }
    this.performanceBlacklist = options.performanceBlacklist

    if (!options.assetBasePath) {
      throw new Error("assetBasePath not found on environment config!")
    }
    this.assetBasePath = options.assetBasePath

    if (!options.sharing) {
      throw new Error("sharing options not found on environment config!")
    }
    this.sharing = options.sharing

    if (!options.languages) {
      throw new Error("languages options not found on environment config!")
    }
    this.languages = options.languages

    this.enablePaintProtectionFilms = !!options.enablePaintProtectionFilms
    this.enableProtectionWrapFilms = !!options.enableProtectionWrapFilms
  }

  getPerformanceBlacklist = ():string[] => {
    return [...this.performanceBlacklist]
  }

  isPaintProtectionFilmsEnabled = ():boolean => {
    return this.enablePaintProtectionFilms
  }

  isProtectionWrapFilmsEnabled = ():boolean => {
    return this.enableProtectionWrapFilms
  }

  // <AssetPathing>
  getAssetFullPath = (url:string):string => {
    return this.assetBasePath + url
  }

  getAssetBasePath = ():string => {
    return this.assetBasePath
  }
  // </AssetPathing>

  // <Sharing>
  getShareImageUploadUrl = ():string => {
    return this.sharing.imagePostFullUrl
  }

  getShareImageUrl = (shareFilename:string) => {
    return this.sharing.imageGetBaseUrl + shareFilename
  }
  // </Sharing>

  // <Languages>
  getDefaultLanguageConfig = ():Maybe<LanguageConfiguration> => {
    // console.log(this.languages)
    let key:string = this.getDefaultLanguageKey()
    for (var i = 0; i < this.languages.length; i++) {
      if (this.languages[i].identifier === key) {
        return this.languages[i]
      }
    }
    
    if (this.languages.length > 0) {
      return this.languages[0]
    }

    return null
  }

  getLanguageKeys = ():string[] => {
    return _.pluck(this.languages, "identifier")
  }

  getLanguageLocalizationKey = (language:string):string|null => {
    for (var i = 0; i < this.languages.length; i++) {
      const locale = this.languages[i]
      if (locale.identifier === language) {
        return locale.localizationKey
      }
    }

    return null
  }

  getLanguageRedirectURLs = (language:string):string[]|null => {
    for (var i = 0; i < this.languages.length; i++) {
      const locale = this.languages[i]
      if (locale.identifier === language) {
        return locale.redirectURLs
      }
    }

    return null
  }

  getDefaultLanguageKey = ():string => {
    if (window["SETTINGS"] && window["SETTINGS"]["LOCALE"]) {
      return window["SETTINGS"]["LOCALE"]
    }

    return this.languages[0].identifier
  }

  getLanguages = ():LanguageConfiguration[] => {
    return this.languages
  }
  // </Languages>
}

export default EnvironmentConfig
