import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class ClearGlass extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "ClearGlass",
      color: new THREE.Color("#12221c"),
      roughness: 0.25,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide
    }), true)
	}
}

export default ClearGlass