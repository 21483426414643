import AssetLibrary from '../AssetLibrary'
import PaintProtectionFilm from '../films/PaintProtectionFilm'
import Maybe from '../Maybe'
import SharingUtility from '../SharingUtility'
import THREE from '../three/threeWithExtensions'
import PlatformDetector from '../utils/PlatformDetector'
import VoidPromise from '../utils/VoidPromise'
import PanelGroup from './PanelGroup'

class Vehicle {
  _loader = null

  shareIdentifier:string
  nameLocalizationKey:string // The localization key to pull the vehicle name from
  captionLocalizationKey:string // The localization key to pull the vehicle caption from
  sortOrder:number // The order to display the vehicle in both the selection screen & the left wheel selector.

  selectCssClassName: string
  selectImageURL:string // For use in the vehicle selection screen
  selectImageMobileURL:string
  highlightImageURL:string // The overlay to add when the car is selected.
  highlightImageMobileURL:string
  previewImageURL:string // For use in the left wheel selector

  modelURL:string
  model:THREE.Object3D

  paintProtectionFilmModelURL:string
  paintProtectionFilmModel:THREE.Object3D
  paintProtectionFilmMeshNamesSilver:Array<string>
  paintProtectionFilmMeshNamesGold:Array<string>
  paintProtectionFilmMeshNamesPlatinum:Array<string>

  colorMapTextureURL:string
  colorMapTexture:THREE.Texture

  shadowTextureURL:string
  shadowTexture:THREE.Texture
  shadowTextureMaterial:THREE.Material

  panelGroups:Array<PanelGroup>
  _loadPromise:Maybe<Promise<void>>

  cameraDistance:THREE.Vector2
  cameraDistancePortrait:THREE.Vector2

  cameraStartAt:THREE.Vector3

  screenshotPosition:THREE.Vector3
  screenshotLookAtOffset:THREE.Vector3
  screenshotPositionPortrait:THREE.Vector3

  constructor(
    shareIdentifier:string,
    sortOrder:number,
    nameLocalizationKey:string, captionLocalizationKey:string,

    selectCssClassName:string,
    selectImageURL:string,
    selectImageMobileURL:string,
    highlightImageURL:string,
    highlightImageMobileURL:string,
    previewImageURL:string,

    modelURL:string,

    paintProtectionFilmModelURL:string,
    paintProtectionFilmMeshNamesSilver:Array<string>,
    paintProtectionFilmMeshNamesGold:Array<string>,
    paintProtectionFilmMeshNamesPlatinum:Array<string>,

    colorMapTextureURL:string,
    shadowTextureURL:string,
    panelGroups: Array<PanelGroup>,

    cameraDistance:THREE.Vector2,
    cameraDistancePortrait:THREE.Vector2,

    cameraStartAt:THREE.Vector3,
    screenshotPosition:THREE.Vector3,
    screenshotLookAtOffset:THREE.Vector3,
    screenshotPositionPortrait:THREE.Vector3
  ) {
    if (!shareIdentifier) {
      throw new Error('shareIdentifier is required')
    }
    if (shareIdentifier.length !== SharingUtility.VehicleIdentifierLength) {
      throw new Error('shareIdentifier must be of length ' + SharingUtility.VehicleIdentifierLength)
    }
    this.shareIdentifier = shareIdentifier

    this.sortOrder = sortOrder

    this.nameLocalizationKey = nameLocalizationKey
    this.captionLocalizationKey = captionLocalizationKey

    this.selectCssClassName = selectCssClassName
    this.selectImageURL = selectImageURL
    this.selectImageMobileURL = selectImageMobileURL
    this.highlightImageURL = highlightImageURL
    this.highlightImageMobileURL = highlightImageMobileURL
    this.previewImageURL = previewImageURL

    this.screenshotLookAtOffset = screenshotLookAtOffset
    this.screenshotPositionPortrait = screenshotPositionPortrait

    this.modelURL = modelURL
    this.paintProtectionFilmModelURL = paintProtectionFilmModelURL
    this.paintProtectionFilmMeshNamesSilver = paintProtectionFilmMeshNamesSilver
    this.paintProtectionFilmMeshNamesGold = paintProtectionFilmMeshNamesGold
    this.paintProtectionFilmMeshNamesPlatinum = paintProtectionFilmMeshNamesPlatinum

    this.colorMapTextureURL = colorMapTextureURL
    this.shadowTextureURL = shadowTextureURL
    this.panelGroups = panelGroups

    this.cameraDistance = cameraDistance
    this.cameraDistancePortrait = cameraDistancePortrait

    this.cameraStartAt = cameraStartAt
    this.screenshotPosition = screenshotPosition
  }

  isLoaded = () => {
    return !!this.model && !!this.paintProtectionFilmModel
  }

  isLoading = ():boolean => {
    return (
      this._loadPromise
      && (!this.model || !this.paintProtectionFilmModel)
    )
  }

  load = (basePath:string):Promise<void> => {
    if (this._loadPromise) {
      return this._loadPromise
    }

    const modelPromise = AssetLibrary.loadFbxModel(basePath+this.modelURL)
    modelPromise.then( (model:THREE.Object3D) => {
      this.model = model

      if (PlatformDetector.isIOS()) { // Noticed a bunch of z-fighting on iOS on the accent mesh. Solve was to adjust the accent meshes to be further away from the rest of the vehicle meshes.
        const accentMeshes = this.getAccentMeshes()
        for (var i=0; i<accentMeshes.length; i++) {
          const mesh = accentMeshes[i]
          const adjustment = mesh.position.clone().normalize().multiplyScalar(0.05)
          mesh.position.add(adjustment)
        }
      }

      this.model.traverse( (child:any) => {
        if (child.isMesh) {
          if (child.material.name) {
            child.originalMaterialName = child.material.name
          }
          else {
            child.originalMaterialName = 'No Material'
          }
        }
      })
    })

    const ppfPromise = AssetLibrary.loadFbxModel(basePath + this.paintProtectionFilmModelURL)
    ppfPromise.then( (model:THREE.Object3D) => {
      this.paintProtectionFilmModel = model
    })

    const colorPromise = AssetLibrary.loadTexture(basePath+this.colorMapTextureURL).then( (texture:THREE.Texture) => {
      this.colorMapTexture = texture
    })

    const shadowTexturePromise = AssetLibrary.loadTexture(basePath+this.shadowTextureURL).then( (texture:THREE.Texture) => {
      this.shadowTexture = texture
      this.shadowTextureMaterial = new THREE.MeshBasicMaterial( { map: this.shadowTexture, wireframe: false,  transparent: true } )
    })

    this._loadPromise = VoidPromise.all([modelPromise, ppfPromise, shadowTexturePromise, colorPromise])
    return this._loadPromise
  }

  setWrapMaterial = (material:THREE.Material):void => {
    if (!this.isLoaded()) {
      throw new Error('Must be loaded first!')
    }

    this.model.traverse( (child:any) => {
      if (child.isMesh && child.material.name === 'carpaint') {
        child.material = material
        child.material.name = 'carpaint'
      }
    })
  }

  getPanelGroup = (panelGroupKey:string):Maybe<PanelGroup> => {
    return this.panelGroups.find( (group) => {
      return group.key === panelGroupKey
    })
  }

  getMainWrapPanelGroups = ():PanelGroup[] => {
    return this.panelGroups.filter( (group) => {
      return PanelGroup.Keys.indexOf(group.key) >= 0
    })
  }

  getPaintablePanelGroupForMeshName = (meshName:string):Maybe<PanelGroup> => {
    return this.panelGroups.find( (group) => {
      return group.hasPaintableMeshNamed(meshName)
    })
  }

  getPanelGroupForMeshName = (meshName:string):Maybe<PanelGroup> => {
    return this.panelGroups.find( (group) => {
      return group.hasMeshNamed(meshName)
    })
  }

  getPanelGroupMeshesForHighlighting = (panelGroup:PanelGroup):THREE.Mesh[] => {
    if (!panelGroup || !this.model) {
      return []
    }

    const meshes:THREE.Mesh[] = []
    this.model.traverse( (child:any) => {
      if (child.isMesh && panelGroup.hasMeshNamed(child.name)) {
        meshes.push(child)
      }
    })
    return meshes
  }

  getWindowMeshes = ():THREE.Mesh[] => {
    const panel = this.getPanelGroup(PanelGroup.WindowKey)
    if (!panel) {
      return []
    }

    return this._findMeshesNamed(this.model, panel.meshNames)
  }

  isWindowMeshName = (meshName:string):boolean => {
    const panel = this.getPanelGroup(PanelGroup.WindowKey)
    return !!panel && panel.meshNames.indexOf(meshName) >= 0
  }

  getAccentMeshes = ():THREE.Mesh[] => {
    const panel:Maybe<PanelGroup> = this.getPanelGroup(PanelGroup.AccentKey)
    if (!panel) {
      return []
    }

    return this._findMeshesNamed(this.model, panel.meshNames)
  }

  isAccentMeshName = (meshName:string):boolean => {
    const panel:Maybe<PanelGroup> = this.getPanelGroup(PanelGroup.AccentKey)
    return !!panel && panel.meshNames.indexOf(meshName) >= 0
  }

  getAllPaintProtectionFilmMeshes = ():THREE.Mesh[] => {
    const meshes:THREE.Mesh[] = []
    this.paintProtectionFilmModel.traverse( (child:any) => {
      if (child.isMesh) {
        meshes.push(child)
      }
    })
    return meshes
  }

  getPaintProtectionFilmMeshes = (film:Maybe<PaintProtectionFilm>):THREE.Mesh[] => {
    const meshes:THREE.Mesh[] = []
    if (!film) {
      return meshes
    }

    this.paintProtectionFilmModel.traverse( (child:any) => {
      if (child.isMesh) {
        console.log('PPF Mesh name=', child.name)
        switch(film.name) {
          case 'films.coverage.platinum': // While it seems bad that these names are hardcoded, realistically, the app would have to re-build anyway if they ever changed since they're defined this way in the translations files & component code. So they are unlikely to change.
            if (this.paintProtectionFilmMeshNamesPlatinum.indexOf(child.name) >= 0) {
              meshes.push(child)
            }
            break;
          case 'films.coverage.gold':
            if (this.paintProtectionFilmMeshNamesGold.indexOf(child.name) >= 0) {
              meshes.push(child)
            }
            break
          case 'films.coverage.silver':
            if (this.paintProtectionFilmMeshNamesSilver.indexOf(child.name) >= 0) {
              meshes.push(child)
            }
            break
        }
      }
    })
    return meshes
  }

  _findMeshesNamed = (model:THREE.Object3D, meshNames:Array<string>):THREE.Mesh[] => {
    if (!model) {
      return []
    }

    const meshes:THREE.Mesh = []
    model.traverse( (child:any) => {
      if (child && child.isMesh && meshNames.indexOf(child.name) >= 0) {
        meshes.push(child)
      }
    })

    return meshes
  }
}

export default Vehicle
