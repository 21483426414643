import * as React from 'react'
import ShieldQuarter from './ShieldQuarter'
import ShieldHalf from './ShieldHalf'
import ShieldFull from './ShieldFull'

export default class ShieldIconFactory {
  static tryToGetIcon = (key:string, props:any):any|null => {
    if (!key) {
      console.warn('ShieldFactory tryToGetIcon passed an empty key!')
      return null
    }

    switch(key.toLowerCase()) {
      case 'shield-quarter': 
        return <ShieldQuarter {...props} />
      case 'shield-half':
        return <ShieldHalf {...props} />
      case 'shield-full':
        return <ShieldFull {...props} />
    }

    return null
  }
}