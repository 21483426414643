import Maybe from '../../../../../core/Maybe'
import Tint from '../../../../../core/tints/Tint'
import checkSelectionsForLoading from '../checkSelectionsForLoading'
import Dispatch from '../Dispatch'
import GetState from '../GetState'

export default (tint:Maybe<Tint>) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({ type:'SELECT_TINT', tint:tint })
    dispatch(checkSelectionsForLoading())
  }
}