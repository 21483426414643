import EnvironmentConfig from '../../../../../core/EnvironmentConfig'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'
import { Maybe } from '../../../../../core/Maybe'
import SharingUtility from '../../../../../core/SharingUtility'
import Tint from '../../../../../core/tints/Tint'
import UserSelections from '../../../../../core/UserSelections'
import Size from '../../../../../core/utils/Size'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import Wrap from '../../../../../core/wraps/Wrap'
import AnyAction from '../../actions/AnyAction'
import SessionManager from '../../helpers/SessionManager'
import EditorPanelState from '../models/EditorPanelState'
import FetchState from '../models/FetchState'
import Modal from '../models/Modal'
import TrackingMeta from '../models/TrackingMeta'
import Views from '../models/View'
import { type i18n } from 'i18next'

export interface UIState {
  trackingMeta: Maybe<TrackingMeta>

  isPreloading: boolean
  isDesktop: boolean
  viewportSize:Size

  language: Maybe<i18n>
  translator: Maybe<(key:string) => string>

  environmentConfig: Maybe<EnvironmentConfig>
  viewHistory: Views[]
  currentModals: Modal[]
  vehicles: Vehicle[]
  wraps: Wrap[]
  tints: Tint[]
  films: PaintProtectionFilm[]

  loadingMessage: string
  loadingErrorMessage: string

  editorPanelState: EditorPanelState
  selectedPanelGroup: Maybe<PanelGroup> // Using null to mean "everything" on the car.
  selectedWrapFinishGroup: Maybe<string> // Uses null to denote no selection made

  userSelections: UserSelections
  temporarySelections: UserSelections
  selectionsCheckTime: Date

  needsShareImageData: boolean
  shareImageState: FetchState
  shareImageURL: Maybe<string>
  sharePreviewURL: Maybe<string>
  shareWrapsDescription: string[]
  shareTintDescription: Maybe<string>

  frameDurationHistory: number[]
  renderErrors: Array<any>
  renderingPaused: boolean
}

const initialState:UIState = {
  trackingMeta: null,

  isPreloading: false,
  isDesktop: false,
  viewportSize: new Size(window.innerWidth, window.innerHeight),

  language: null,
  translator: null,

  environmentConfig: null,
  viewHistory: [Views.Preloading],
  currentModals: [],
  vehicles: [],
  tints: [],
  wraps: [],
  films: [],

  loadingMessage: '',
  loadingErrorMessage: '',

  editorPanelState: EditorPanelState.Closed,
  selectedPanelGroup: null,
  selectedWrapFinishGroup: null,

  userSelections: new UserSelections(),
  temporarySelections: new UserSelections(),
  selectionsCheckTime: new Date(),

  needsShareImageData: false,
  shareImageState: FetchState.NotAsked,
  shareImageURL: null,
  sharePreviewURL: null,
  shareWrapsDescription: [],
  shareTintDescription: null,

  frameDurationHistory: [],
  renderErrors: [],
  renderingPaused: false
}

const returnStateAndSaveSharingCode = (state: UIState): any => {
  const shareCode = SharingUtility.createShareCode(state?.userSelections)
  if (shareCode) {
    SessionManager.saveSelectionsCode(shareCode)
  }

  return state
}

const reducer = (state: UIState = initialState, action: AnyAction): UIState => {
  switch (action.type) {
    case 'INIT':
      return initialState

    case 'BACK':
      if (state.viewHistory.length <= 1) { // Checking for 1 because the Preload view should always be there and can't be navigated back to!
        throw new Error('Uh oh! Really should not be going back when there is nothing to go back to.')
      }
      
      return {
        ...state,
        // @ts-ignore Not sure why TS can't figure this out.
        viewHistory: [].concat(state.viewHistory.slice(0, state.viewHistory.length - 1))
      }

    case 'SET_TRACKING_META':
      return {
        ...state,
        trackingMeta: action.meta
      }

    case 'SET_IS_PRELOADING':
      return {
        ...state,
        isPreloading: action.value
      }

    case 'SET_VIEWPORT_SIZE':
      return {
        ...state,
        viewportSize: action.size
      }

    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language,
        translator: (action.language ? action.language.t.bind(action.language) : null) // The weird binding is necessary, or the `this` reference in i18n is lost.
      }

    case 'SET_LOADING_MESSAGE':
      return {
        ...state,
        loadingMessage: action.message
      }

    case 'SET_LOADING_ERROR':
      return {
        ...state,
        loadingErrorMessage: action.message
      }

    case 'SET_VIEW':
      
      const remainingViews = action.clearHistory ? 
        [Views.Preloading, action.view] // Important that we keep the preloading view first, as that's the "normal" way the list is made.
        // @ts-ignore Not sure why TS can't figure this out.
        : [].concat(...state.viewHistory, action.view)

      return {
        ...state,
        viewHistory: remainingViews
      }
    
    case 'OPEN_MODAL':
      return {
        ...state,
        currentModals: state.currentModals.concat([action.modal])
      }

    case 'CLOSE_MODAL':
      return {
        ...state,
        currentModals: state.currentModals.filter( (modal:Modal):boolean => { return modal !== action.modal })
      }

    case 'SET_ENVIRONMENT_CONFIG':
      return {
        ...state,
        environmentConfig: action.environmentConfig
      }

    case 'SET_VEHICLES':
      return {
        ...state,
        vehicles: action.vehicles
      }

    case 'SET_WRAPS':
      return {
        ...state,
        wraps: action.wraps
      }

    case 'SET_TINTS':
      return {
        ...state,
        tints: action.tints
      }

    case 'SET_FILMS':
      return {
        ...state,
        films: action.films
      }

    case 'SET_SELECTIONS': {
      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: action.selections,
        temporarySelections: action.selections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'RESET_TEMPORARY_SELECTIONS': {
      return {
        ...state,
        temporarySelections: state.userSelections.clone()
      }
    }

    case 'SET_TEMPORARY_VEHICLE': {
      const newSelections = state.temporarySelections.clone()
      newSelections.setVehicle(action.vehicle)

      return {
        ...state,
        temporarySelections: newSelections
      }
    }

    case 'SELECT_VEHICLE': {
      const newSelections = state.userSelections.clone()
      newSelections.setVehicle(action.vehicle)

      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        // <fix> Really shouldn't have to set these here, but fixes a really weird bug with materials not updating after changing vehicles.
        selectedPanelGroup: null,
        selectedWrapFinishGroup: null,
        // </fix>
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'SELECT_SCENE_MODE': {
      const newSelections = state.userSelections.clone()
      newSelections.setSceneMode(action.sceneMode)

      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone()
      })
    }

    case 'SET_EDITOR_PANEL_STATE': {
      let newProps = { editorPanelState: action.panelState }
      if (action.panelState === EditorPanelState.Closed) {
        // If the user went back, we want to nuke their temporary selections.
        newProps['temporarySelections'] = state.userSelections.clone()
        newProps['selectedPanelGroup'] = null
        newProps['selectedWrapFinishGroup'] = null
      }

      return {
        ...state,
        ...newProps
      }
    }

    case 'SELECT_PANEL_GROUP':
      return {
        ...state,
        selectedPanelGroup: action.panelGroup,
        temporarySelections: state.userSelections.clone() // When the user switches panel groups, we also want to nuke any temporary selections they've made.
      }

    case 'SELECT_FINISH_GROUP':
      return {
        ...state,
        selectedWrapFinishGroup: action.finishGroup
      }

    case 'TEMPORARILY_SELECT_WRAP': {
      const newSelections = state.temporarySelections.clone()
      newSelections.setPanelGroupWrap(action.panelGroup, action.wrap)

      return {
        ...state,
        temporarySelections: newSelections
      }
    }

    case 'SELECT_WRAP': {
      const newSelections = state.userSelections.clone()
      newSelections.setPanelGroupWrap(action.panelGroup, action.wrap)

      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'TEMPORARILY_SELECT_ACCENT_WRAP': {
      const newSelections = state.temporarySelections.clone()
      newSelections.setAccent(action.wrap)

      return {
        ...state,
        temporarySelections: newSelections
      }
    }

    case 'SELECT_ACCENT_WRAP': {
      const newSelections = state.userSelections.clone()
      newSelections.setAccent(action.wrap)

      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'TEMPORARILY_SELECT_TINT': {
      const newSelections = state.temporarySelections.clone()
      newSelections.setTint(action.tint)

      return {
        ...state,
        temporarySelections: newSelections
      }
    }

    case 'SELECT_TINT': {
      const newSelections = state.userSelections.clone()
      newSelections.setTint(action.tint)

      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'SELECT_PAINT_PROTECTION_FINISH': {
      const newSelections = state.userSelections.clone()
      newSelections.setPaintProtectionFilmFinish(action.finish)
      
      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'SELECT_PAINT_PROTECTION_FILM': {
      const newSelections = state.userSelections.clone()
      newSelections.setPaintProtectionFilm(action.film)
      
      return returnStateAndSaveSharingCode({
        ...state,
        userSelections: newSelections,
        temporarySelections: newSelections.clone(),
        shareImageURL: null,
        sharePreviewURL: null,
        shareImageState: FetchState.NotAsked
      })
    }

    case 'REQUEST_SHARE_IMAGE_DATA': {
      return {
        ...state,
        shareImageURL: null,
        sharePreviewURL: null,
        needsShareImageData: true
      }
    }

    case 'SET_SHARE_IMAGE_STATE':
      return {
        ...state,
        needsShareImageData: false,
        shareImageState: action.state
      }

    case 'SET_SHARE_IMAGE_DATA':
      return {
        ...state,
        shareImageURL: action.url,
        sharePreviewURL: action.previewUrl,
        needsShareImageData: false,
        shareImageState: FetchState.Success
      }

    case 'RECORD_FRAME_DURATION': {
      let list = state.frameDurationHistory.slice(
        Math.max(0, state.frameDurationHistory.length - 99)
      ) // Only record 100 rolling frames of data.
      list.push(action.durationInMilliseconds)

      return {
        ...state,
        frameDurationHistory: list
      }
    }

    case 'RECORD_RENDER_ERROR': {
      let list = state.frameDurationHistory.slice()
      list.push(action.error)

      return {
        ...state,
        renderErrors: list
      }
    }

    case 'PAUSE_RENDERING': {
      return {
        ...state,
        renderingPaused: true
      }
    }

    case 'UNPAUSE_RENDERING': {
      return {
        ...state,
        renderingPaused: false
      }
    }

    case 'CHECK_SELECTIONS_FOR_LOADED': {
      return {
        ...state,
        selectionsCheckTime: new Date()
      }
    }
  }
  return state
}

export default reducer
