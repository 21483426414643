import * as React from 'react'

export default function HeadlineDark() {
  return (
  
    <svg version="1.1" className="headline-dark" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 43.2 43.2" xmlSpace="preserve">
      <circle cx="21.4" cy="21.4" r="20.4"/>
      <path style={{display: 'none'}} className="st0" d="M30.1,13.9l-0.8-0.7c-0.1-0.1-0.2-0.3,0-0.5L32,9.5c0.1-0.1,0.3-0.2,0.5,0l0.8,0.7c0.1,0.1,0.2,0.3,0,0.5 l-2.8,3.2C30.4,14,30.2,14,30.1,13.9z"/>
      <g>
        <g>
          <path className="st0" d="M21.6,43.2C9.7,43.2,0,33.5,0,21.6S9.7,0,21.6,0s21.6,9.7,21.6,21.6S33.5,43.2,21.6,43.2z M21.6,2.3 C11,2.3,2.3,11,2.3,21.6S11,40.9,21.6,40.9s19.3-8.6,19.3-19.3S32.2,2.3,21.6,2.3z"/>
          <circle className="st1" cx="21.6" cy="21.6" r="3.7"/>
          <g>
            <path className="st1" d="M15.3,24.9l17.1-14.3c0.1-0.1,0.3,0.1,0.2,0.2L18.3,28c-0.3,0.3-0.8,0.4-1.1,0l-2-2 C14.9,25.7,14.9,25.2,15.3,24.9z"/>
          </g>
          <path className="st0" d="M22.2,10.4h-1.1c-0.2,0-0.3-0.1-0.3-0.3V5.8c0-0.2,0.1-0.3,0.3-0.3h1.1c0.2,0,0.3,0.1,0.3,0.3v4.3 C22.5,10.3,22.3,10.4,22.2,10.4z"/>
          <path className="st0" d="M12.8,14.1l-3-3c-0.1-0.1-0.1-0.3,0-0.5l0.8-0.8c0.1-0.1,0.3-0.1,0.5,0l3,3c0.1,0.1,0.1,0.3,0,0.5l-0.8,0.8 C13.2,14.2,13,14.2,12.8,14.1z"/>
          <path className="st0" d="M10.1,22.5H5.8c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h4.3c0.2,0,0.3,0.1,0.3,0.3v1.1 C10.4,22.3,10.3,22.5,10.1,22.5z"/>
          <path className="st0" d="M10.6,33.4l-0.8-0.8c-0.1-0.1-0.1-0.3,0-0.5l3-3c0.1-0.1,0.3-0.1,0.5,0l0.8,0.8c0.1,0.1,0.1,0.3,0,0.5l-3,3 C10.9,33.5,10.7,33.5,10.6,33.4z"/>
          <path className="st0" d="M37.4,22.5h-4.3c-0.2,0-0.3-0.1-0.3-0.3v-1.1c0-0.2,0.1-0.3,0.3-0.3h4.3c0.2,0,0.3,0.1,0.3,0.3v1.1 C37.8,22.3,37.6,22.5,37.4,22.5z"/>
          <path className="st0" d="M32.2,33.4l-3-3c-0.1-0.1-0.1-0.3,0-0.5l0.8-0.8c0.1-0.1,0.3-0.1,0.5,0l3,3c0.1,0.1,0.1,0.3,0,0.5l-0.8,0.8 C32.5,33.5,32.3,33.5,32.2,33.4z"/>
        </g>
      </g>
    </svg>

  )
}