import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class ColorMappedGlass extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "GenericGlass",
      roughness: 0.25,
      transparent: true,
      opacity: 0.5,
      side: THREE.DoubleSide
    }), true)
	}
}

export default ColorMappedGlass