import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'
import Maybe from '../../../../../core/Maybe'
import AppState from '../../store/models/AppState'
import selectors from '../../store/selectors'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import selectPaintProtectionFilm from './selectPaintProtectionFilm'

export default (finish:Maybe<string>) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state:AppState = getState()

    const alreadySelectedFinish:Maybe<string> = selectors.getSelectedPaintProtectionFinish(state)
    if (alreadySelectedFinish === finish) {
      return // If the thing is already selected, no need to do anything.
    }

    if (!finish) {
      dispatch({ type:'SELECT_PAINT_PROTECTION_FINISH', finish:null })
      return
    }

    const finishOptions:string[] = selectors.getPaintProtectionFinishOptions(state)
    const index:number = finishOptions.indexOf(finish)
    if (index < 0) {
      console.warn('selectPaintProtectionFilmFinish called, but finish `' + finish + '` was not an available option! Ignoring.')
      return
    }

    dispatch({ type:'SELECT_PAINT_PROTECTION_FINISH', finish:finish })

    const existingFilm:Maybe<PaintProtectionFilm> = selectors.getSelectedPaintProtectionFilm(state)
    if (existingFilm && existingFilm.finish !== finish) {
      const existingNameTranslated:string = selectors.translate(state, existingFilm.name)

      // If the user had previously selected a PPF, we need to find the corresponding one for the new finish and automatically select that.
      const films:PaintProtectionFilm[] = selectors.getAllPaintProtectionFilms(state)
      const match:Maybe<PaintProtectionFilm> = films.find( (film) => {
        const translatedName = selectors.translate(state, film.name)
        const translatedFinish = selectors.translate(state, film.finish)
        return translatedFinish === finish && translatedName === existingNameTranslated
      })

      if (!match) {
        console.warn('selectPaintProtectionFilmFinish - User switched to `' + finish + '`, but no corresponding `' + existingFilm.name + '` PPF was found!')
        // We will let this continue and just nuke the users selection.
      }

      dispatch(selectPaintProtectionFilm(match))
    }
  }
}