import Maybe from '../../../../../core/Maybe'
import Modal from '../../store/models/Modal'
import selectors from '../../store/selectors'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import updateRenderingStatus from '../updateRenderingStatus'

export default (modal:Maybe<Modal>) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    // If the caller didn't specify a modal, try to just grab the current modal
    if (!modal) {
      modal = selectors.getCurrentModal(state)
    }

    // If there's still no modal, then it was a bad call. Ignore it.
    if (!modal) {
      console.warn('closeModal called, but no modal was supplied or open! Ignoring.')
      return
    }

    console.info('Closing modal `' + modal + '`.')
    switch(modal) {
      case Modal.Share:
      case Modal.LanguageSelection:
      case Modal.ConfirmWrap:
        // Nothing here, just prevent the default from occurring.
        break
      default:
        console.warn('closeModal called, but modal supplied "' + modal + '" was not recognized!')
        return
    }

    dispatch({ type:'CLOSE_MODAL', modal:modal })
    dispatch(updateRenderingStatus())
  }
}