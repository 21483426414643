import THREE from '../three/threeWithExtensions'

const vertexShader = `
varying vec4 mvPosition;
varying vec3 worldNormal;
varying vec3 cameraToVertex;
varying vec2 vUv;

void main() {
  mvPosition = modelViewMatrix * vec4( position, 1.0 );
  worldNormal = mat3( modelMatrix[ 0 ].xyz, modelMatrix[ 1 ].xyz, modelMatrix[ 2 ].xyz ) * normal;
  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  cameraToVertex = normalize(worldPosition.xyz - cameraPosition);
  vUv = uv;

  gl_Position = projectionMatrix * mvPosition;
}
`

const fragmentShader = `
uniform sampler2D dayMap;
uniform sampler2D nightMap;
uniform float daytimePercentage;

varying vec2 vUv;
varying vec2 flakeUv;
varying vec3 worldNormal;
varying vec4 mvPosition;
varying vec3 cameraToVertex;

void main() {
  vec3 dayColor = texture2D( dayMap, vUv ).xyz;
  vec3 nightColor = texture2D( nightMap, vUv ).xyz;
  vec3 useColor = mix(nightColor, dayColor, daytimePercentage);
  gl_FragColor = vec4(useColor, 1.0);
}
`

const uniforms = {
  dayMap: { type: "t", value: null },
  nightMap: { type: "t", value: null },
  daytimePercentage: { type: "f", value: 0.0, min: 0.0, max: 1.0 }
}

const material = new THREE.ShaderMaterial({
  uniforms : uniforms,
  vertexShader : vertexShader,
  fragmentShader : fragmentShader
})
material.extensions.derivatives = true

export default material