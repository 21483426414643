import THREE from '../three/threeWithExtensions'

/*
  Usage:

  import { GuiFieldName, prepareColorField, onColorChange } from './onColorChange'

  const uniforms = {
    ...
    someColorProperty: prepareColorField({ type: "c", value: new THREE.Color(0x660059) }),
    ...
  }

  class CustomMaterial extends AbstractMaterial {
    configureGui = (gui:any) => {
      const folder = gui.addFolder('Custom Shader')
      ...
      folder.addColor(this.material.uniforms.someColorProperty, GuiFieldName).name('someColorProperty').onChange( onColorChange(this.material.uniforms.someColorProperty) )
      ...
    }
  }
*/
const GuiFieldName = "guiValue";

const prepareColorField = (object:any) => {
  const value = object['value']
  object[GuiFieldName] = '#' + value.getHexString()
  return object
};

const prepareColorFieldTHREE = (color:THREE.Color) => {
  color[GuiFieldName] = '#' + color.getHexString()
  return color
};

// This one is used by our custom prepareColorField
const onColorChange = (object:any) => {
  return (value:any) => {
    if (!object.value.setHex) { // Sometimes the instance isn't a THREE.Color, it's an array. I think this happens for "native" uniforms in THREE.js.
      object.value = new THREE.Color(value)
    }
    else {
      const hex = value.replace('#', '0x')
      const asInt = hex.toString(16)
      object.value.setHex(asInt)
    }
  }
}

// This one is used just by regular THREE.js definitions
const onColorChangeTHREE = (color:THREE.Color) => {
  return (value:any) => {
    const hex = value.replace('#', '0x')
    const asInt = hex.toString(16)
    color.setHex(asInt)
    color[GuiFieldName] = '#' + color.getHexString()
  }
}

export {
  GuiFieldName,
  prepareColorField,
  prepareColorFieldTHREE,
  onColorChange,
  onColorChangeTHREE
}