import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class Chrome extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: 'Chrome',
      metalness: 1,
      roughness: 0.31
    }), true)
	}

  configureVehicleColorMap = (colorMap:THREE.Texture):void => {
    if (this._shouldUseVehicleColorMap) {
      this._material.map = colorMap
      this._material.emissiveMap = colorMap
    }
  }
}

export default Chrome