import cx from 'classnames'
import * as React from 'react'
import { connect } from 'react-redux'
import PaintProtectionFilm from '../../../../../../../core/films/PaintProtectionFilm'
import Maybe from '../../../../../../../core/Maybe'
import selectPaintProtectionFilm from '../../../../actionCreators/films/selectPaintProtectionFilm'
import selectPaintProtectionFinish from '../../../../actionCreators/films/selectPaintProtectionFilmFinish'
import AppState from '../../../../store/models/AppState'
import selectors from '../../../../store/selectors'
import CancelIcon from '../../../icons/Cancel'
import ShieldIconFactory from '../../../icons/shields/ShieldFactory'
import ShieldInactive from '../../../icons/shields/ShieldInactive'
import MobileAssetSelectorPanel from '../MobileAssetSelectorPanel'
import MobileSelectedItemInfo from '../MobileSelectedItemInfo'

interface OwnProps { }

interface ReduxProps {
  isLargerThanSmallViewport: boolean
  films: Array<PaintProtectionFilm>
  selectedFilm: Maybe<PaintProtectionFilm>
  finishOptions: Array<string>
  selectedFinishOption: Maybe<string>
  translate: (key: string) => string
}

interface DispatchProps {
  selectPaintProtectionFilm: (film:Maybe<PaintProtectionFilm>) => void
  selectPaintProtectionFinish: (finish: string) => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function MobileFilmSelector(props: Props) {
  const headerOptions:any[] = []
    if (props.finishOptions.length > 0) {
      headerOptions.push({
        title: props.translate('editor.films.finish-options-title'),
        values: props.finishOptions,
        selectedValue: props.selectedFinishOption,
        onHeaderOptionSelected: props.selectPaintProtectionFinish
      })
    }

  return (
    <MobileAssetSelectorPanel
      className='MobileFilmSelector'
      title={props.translate('editor.films.title')}
      icon={props.isLargerThanSmallViewport ? <ShieldInactive width={30} /> : <ShieldInactive width={20} /> }
      iconClassName='films'
      headerOptions={headerOptions}
      footerText={props.translate('editor.films.caption')}
    >
      <div className='panel-group-selector--mobile--inner'>
        <MobileSelectedItemInfo
          name={props.selectedFilm ? props.translate(props.selectedFilm.name) : props.translate('editor.no-selection.title')}
          code={props.selectedFilm ? props.translate(props.selectedFilm.finish) : props.translate('editor.no-selection.captionMobile')}
        />

        <div className='panel-group-selector--mobile--list'>
          <button
            className={cx(
              'mobile-swatch mobile-swatch--none',
              !props.selectedFilm && 'selected'
            )}
            onClick={() => props.selectPaintProtectionFilm(null)}
          >
            <CancelIcon />
          </button>
          {props.films.map((film) => (
            <button
              key={film.code}
              className={cx(
                'mobile-swatch',
                props.selectedFilm === film && 'selected'
              )}
              onClick={() => props.selectPaintProtectionFilm(film)}
            >
              {ShieldIconFactory.tryToGetIcon(film.icon, { width:15 })}
            </button>
          ))}
        </div>
      </div>
    </MobileAssetSelectorPanel>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      isLargerThanSmallViewport: selectors.isLargerThanSmallViewport(state),
      films: selectors.getSelectablePaintProtectionFilms(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      finishOptions: selectors.getPaintProtectionFinishOptions(state),
      selectedFinishOption: selectors.getSelectedPaintProtectionFinish(state),
      translate: selectors.getTranslator(state)
    }
  },
  {
    selectPaintProtectionFilm, 
    selectPaintProtectionFinish 
  }
)(MobileFilmSelector)