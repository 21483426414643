import * as React from 'react'
import { connect } from 'react-redux'

import AppState from '../store/models/AppState'
import selectors from '../store/selectors/index'
import TranslationComponent from '../components/TranslationComponent'
import openEloquaCookiePreferences from '../actionCreators/openEloquaCookiePreferences'
import cx from 'classnames'

interface OwnProps {}

interface ReduxProps {
  translate: (key: string) => string
  isEloquaConfigured: boolean
  language: string
}

interface DispatchProps {
  openEloquaCookiePreferences: () => void
}

interface State {}

class Footer extends React.Component<
  OwnProps & ReduxProps & DispatchProps,
  State
> {
  render() {
    return <footer className='site-footer'>
      <ul className='site-footer--mainLinks'>
        <li>
          <a href={this.props.translate('global.protection-wrap-info.url')} className="mmm_homequicklinks" target="_new">
            <TranslationComponent localizationKey='global.protection-wrap-info.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.product-info.url')} className="mmm_homequicklinks" target="_new">
            <TranslationComponent localizationKey='global.product-info.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.window-film-info.url')} className="mmm_homequicklinks" target="_new">
            <TranslationComponent localizationKey='global.window-film-info.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.paint-protection-info.url')} className="mmm_homequicklinks" target="_new">
            <TranslationComponent localizationKey='global.paint-protection-info.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.contact-us.url')} className="mmm_homequicklinks" target="_new">
            <TranslationComponent localizationKey='global.contact-us.title' />
          </a>
        </li>
      </ul>

      <ul className="site-footer--secondaryLinks">
        <li>
          {this.props.isEloquaConfigured && (
            <React.Fragment>
              <a href="#" onClick={this.props.openEloquaCookiePreferences}>
                <TranslationComponent localizationKey="global.cookie-preferences" />
              </a>
            </React.Fragment>
          )}
        </li>
        <li>
          <a href={this.props.translate('global.email-signup.url')} target="_blank">
            <TranslationComponent localizationKey='global.email-signup.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.privacy-policy.url')} target="_blank">
            <TranslationComponent localizationKey='global.privacy-policy.title' />
          </a>
        </li>
        <li>
          <a href={this.props.translate('global.legal.url')} target="_blank">
            <TranslationComponent localizationKey='global.legal.title' />
          </a>
        </li>
      </ul>
    </footer>
  }
}

export default connect(
  (state: AppState): ReduxProps => ({
    translate: selectors.getTranslator(state),
    isEloquaConfigured: selectors.hasEloquaCookiePreferencesConfigured(state),
    language: selectors.getLanguage(state),
  }),
  {
    openEloquaCookiePreferences,
  }
)(Footer)
