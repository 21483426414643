import Modal from '../../store/models/Modal'
import selectors from '../../store/selectors'
import Dispatch from '../Dispatch'
import GetState from '../GetState'
import requestShareImageData from '../requestShareImageData'
import updateRenderingStatus from '../updateRenderingStatus'

export default (modal:Modal) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    // Make sure the supplied modal isn't already open
    const openModals = selectors.getAllOpenModals(state)
    if (openModals.indexOf(modal) >= 0) {
      console.warn('openModal called, but the supplied modal ' + modal + ' is already open! Ignoring.')
      return
    }
    
    switch(modal) {
      case Modal.Share:
        const selectedVehicle = selectors.getSelectedVehicle(state)
        if (selectedVehicle) {
          dispatch(requestShareImageData())
        }
        break
      case Modal.LanguageSelection:
      case Modal.ConfirmWrap:
        // Nothing here, just prevent `default` case from happening.
        break
      default:
        console.warn('openModal called, but supplied modal `' + modal + '` was not recognized! Ignoring.')
        return // Return here, NOT break! We want to abort completely if the modal type was not recognized.
    }

    dispatch({ type:'OPEN_MODAL', modal:modal })
    dispatch(updateRenderingStatus())
  }
}