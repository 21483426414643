import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class ColorMappedMaterial extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "ColorMappedMaterial"
    }), true)
	}
}

export default ColorMappedMaterial