import THREE from '../../three/threeWithExtensions'
import BakedMaterial from './BakedMaterial'

class SunRoof extends BakedMaterial {
	constructor() {
		super(new THREE.MeshStandardMaterial({
      name: "SunRoof",
      color: new THREE.Color(0.1, 0.1, 0.1),
      roughness: 0.015,
      transparent: true,
      opacity: 0.90,
      side: THREE.DoubleSide
    }), false)
	}
}

export default SunRoof