import i18n, { type i18n as I18N } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import Maybe from '../../../core/Maybe'

const initializeLanguages = async (languageKeys:string[], defaultLanguageKey:string, basePath:string):Promise<Maybe<I18N>> => {
  const instance = i18n.createInstance()
  instance.use(HttpBackend)
  instance.use(LanguageDetector)
  instance.use(initReactI18next)
  
  const translateFunction = await instance.init({ // https://www.i18next.com/overview/configuration-options
    fallbackLng: defaultLanguageKey,
    debug: false, // i18n throws a lot of warnings since we're using non-standard languages (`en_US`, instead of `en`). Supress them.
    supportedLngs: languageKeys,
    cache: {
      enabled: false
    },
    interpolation: {
      escapeValue: false // We don't need i18n to escape values because react escapes content by default.
    },
    returnObjects: true,

    // <i18next-http-backend>
    backend: {
      loadPath: basePath + 'assets/js/locales/{{lng}}/translation.json'
    },
    // </i18next-http-backend>
    
    // <i18next-browser-languagedetector>
    detection: {}
    // </i18next-browser-languagedetector>
  })

  return instance
}

export default initializeLanguages