import Cookies from 'js-cookie'

const SELECTIONS_CODE_COOKIE_NAME = 'restylingStudio_selectionsCode'
const LANGUAGE_COOKIE_NAME = 'restylingStudio_defaultLanguage'
const COOKIE_SETTINGS = { expires: 7 }

class SessionManager {
  hasSavedSelectionsCode = ():boolean => {
    const code = this.getSavedSelectionCode()
    return !!code
  }

  saveSelectionsCode = (code:string):void => {
    Cookies.set(SELECTIONS_CODE_COOKIE_NAME, code, COOKIE_SETTINGS)
  }

  clearSavedSelectionCode = ():void => {
    Cookies.remove(SELECTIONS_CODE_COOKIE_NAME, COOKIE_SETTINGS)
  }

  getSavedSelectionCode = ():string => {
    return Cookies.get(SELECTIONS_CODE_COOKIE_NAME)
  }

  hasSavedLanguageSelection = ():boolean => {
    const language = this.getSavedLanguageSelection()
    return !!language
  }

  saveLanguageSelection = (language:string):void => {
    Cookies.set(LANGUAGE_COOKIE_NAME, language, COOKIE_SETTINGS)
  }

  getSavedLanguageSelection = ():string => {
    return Cookies.get(LANGUAGE_COOKIE_NAME)
  }

  clearSavedLanguageSelection = ():void => {
    return Cookies.remove(LANGUAGE_COOKIE_NAME, COOKIE_SETTINGS)
  }
}

export default new SessionManager()
