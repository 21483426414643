import * as React from 'react'
import { createPortal } from 'react-dom'

const Flickity = require('flickity')
const imagesloaded = require('imagesloaded')

type OwnProps = {
  options: any,
  className: string,
  elementType: string,
  children: any,
  onSlideIndexChange: any
  slideIndex:number
}

type State = {}

class FlickityComponent extends React.Component<OwnProps, State> {
  static defaultProps:any
  _root:any
  _flickity:any
  _portal:any
  _lastIndex:number

  constructor(props) {
    super(props)

    this._root = null
    this._flickity = null
    this._portal = null
  }

  componentDidMount() {
    this._reload()
  }

  componentDidUpdate(prevProps, prevState) {
    this._reload()

    if (this._flickity) {
      if (prevProps && prevProps.slideIndex !== this.props.slideIndex) {
        this._flickity.select(this.props.slideIndex)
      }
    }
  }

  componentWillUnmount() {
    //this._flickity.deactivate() // This throws errors. Not sure why.
  }

  _reload = () => {
    if (this._flickity) {
      this._flickity.activate()
      this._flickity.reloadCells()
    }
    else {
      imagesloaded(this._root, this._onImagesLoaded, () => {})
    }
  }

  _onImagesLoaded = () => {
    this.forceUpdate() // Need to trigger a force update to kickstart the DOM. Otherwise the DOM remains empty in mobile on load.
    setTimeout( () => { // For some reason, the resize wouldn't work unless we delayed the callback.
      this._flickity = new Flickity(this._root, this.props.options)
      ;(window as any)['flickity'] = this._flickity;

      this._flickity.reloadCells()
      this._flickity.resize() // Need to resize or flickity doesn't render correctly.
      this._flickity.updateDraggable() // Need to update draggable as well, as apparently the slides aren't rendered initially. By now they are in the DOM.
      if (this.props.slideIndex) {
        this._lastIndex = this.props.slideIndex
        this._flickity.select(this.props.slideIndex, false, true)
      }

      this._flickity.on( 'change', (index) => {
        if (this._lastIndex !== index) { // For some reason, this method is firing ALL the time with the same index.
          this.props.onSlideIndexChange(index)
          this._lastIndex = index
        }
      });

    }, 300)
  }

  render() {
    if (!this._portal) {
      this._portal = this._renderPortal()
    }

    return React.createElement(
      this.props.elementType,
      {
        className: this.props.className,
        ref: (element) => {
          this._root = element;
        },
      },
      this._portal
    );
  }

  _renderPortal() {
    if (!this._root) {
      return null
    }

    const mountNode = this._root.querySelector('.flickity-slider')
    if (mountNode) {
      let portal = createPortal(this.props.children, mountNode)
      return portal
    }
  }
}

FlickityComponent.defaultProps = {
  className: '',
  elementType: 'div',
  options: {
    draggable: true,
    pageDots: false,
    prevNextButtons: false,
    initialIndex: 2,
    wrapAround: true
  },
};

export default FlickityComponent;
