import * as _ from 'underscore'
import DefaultsFinder from '../../../../../core/DefaultsFinder'
import EnvironmentConfig from '../../../../../core/EnvironmentConfig'
import PaintProtectionFilm from '../../../../../core/films/PaintProtectionFilm'
import IMaterial from '../../../../../core/materials/IMaterial'
import Maybe from '../../../../../core/Maybe'
import SceneMode from '../../../../../core/SceneMode'
import SharingUtility from '../../../../../core/SharingUtility'
import Tint from '../../../../../core/tints/Tint'
import UserSelections from '../../../../../core/UserSelections'
import PlatformDetector from '../../../../../core/utils/PlatformDetector'
import Size from '../../../../../core/utils/Size'
import ViewportDetector from '../../../../../core/utils/ViewportDetector'
import PanelGroup from '../../../../../core/vehicles/PanelGroup'
import Vehicle from '../../../../../core/vehicles/Vehicle'
import Wrap from '../../../../../core/wraps/Wrap'
import { WrapArea } from '../../../../../core/wraps/WrapMetaData'
import AppState from '../models/AppState'
import EditorPanelState from '../models/EditorPanelState'
import FetchState from '../models/FetchState'
import Modal from '../models/Modal'
import SelectOption from '../models/SelectOption'
import TrackingMeta from '../models/TrackingMeta'
import View from '../models/View'
import { type i18n } from 'i18next'

const selectors = {
  getViewportSize: (state:AppState):Size => {
    return state.ui.viewportSize
  },

  isSmallViewport: (state:AppState) => {
    return ViewportDetector.isSmallViewport()
  },

  isLargerThanSmallViewport: (state:AppState) => {
    return ViewportDetector.isLargerThanSmallViewport()
  },

  isDesktopViewport: (state: AppState) => {
    return ViewportDetector.isDesktop()
  },

  isiOS: (state:AppState):boolean => {
    return PlatformDetector.isIOS()
  },

  isIPad: (state:AppState):boolean => {
    return PlatformDetector.isIPad()
  },

  isPortrait: (state:AppState):boolean => {
    return window.innerWidth <= window.innerHeight
  },

  isOnDayMode: (state:AppState):boolean => {
    return state.ui.userSelections.getSceneMode() === SceneMode.Daytime
  },

  getTrackingMeta: (state:AppState):Maybe<TrackingMeta> => {
    return state.ui.trackingMeta
  },

  isDonePreloading: (state:AppState):boolean => {
    return state.ui.tints && state.ui.tints.length > 0 // Yeah, not a perfect indicator, but good enough for now.
  },

  getLanguageOptions: (state:AppState):Maybe<any[]> => {
    return state.ui.environmentConfig?.getLanguages()
  },

  /**
   * Gets the i18n reference.
   */
  getLanguageObject: (state:AppState):Maybe<i18n> => {
    return state.ui.language
  },

  /**
   * Returns true if the translations have been loaded.
   */
  hasLanguageLoaded: (state:AppState):boolean => {
    return !!state.ui.language
  },

  /**
   * Returns the current language code. For example, 'en_US'.
   */
  getLanguage: (state:AppState):string => {
    if (!state.ui.language) {
      return ''
    }

    return state.ui.language.language
  },

  getLanguageRedirectURLs: (state:AppState, language:string):string[] => {
    const environmentConfig = selectors.getEnvironmentConfig(state)
    if (!environmentConfig) {
      return []
    }

    const redirectURLs = environmentConfig.getLanguageRedirectURLs(language)
    if (!redirectURLs) {
      return []
    }

    return redirectURLs
  },

  hasLocalizationConfigured: (state:AppState):boolean => {
    const urlKey = 'global.localization.url'
    const titleKey = 'global.localization.title'

    const url = selectors.translate(state, urlKey)
    const title = selectors.translate(state, titleKey)

    return !!(url && title) && (url !== urlKey && title !== titleKey /* Translate does some weird things where if the key isn't found, it returns the string value of the key. Because yeah, that makes a lot of sense. :| */)
  },

  getLocaleSelectionOptions: (state:AppState):Array<SelectOption> => {
    const optionKeys = selectors.translateObject(state, 'global.localization.selectionLocales')
    if (!optionKeys || !optionKeys.length || optionKeys.length <= 0) {
      // No console warn because this is expected behavior.
      return []
    }

    const environmentConfig = selectors.getEnvironmentConfig(state)
    if (!environmentConfig) {
      console.warn('selectors.getLocaleSelectionOptions - no environmentConfig found!')
      return []
    }
    
    let selectOptions = _.map(optionKeys, (key:string):SelectOption|null => {
      const localizationKey = environmentConfig.getLanguageLocalizationKey(key)
      if (!localizationKey) {
        console.warn('selectors.getLocaleSelectionOptions no localizationKey was found in the environment-config.json file for key `' + key + '`!')
        return null
      }

      return {
        key: key,
        value: selectors.translate(state, localizationKey)
      }
    })

    // Incase there were any errors, just strip out the failures. They were already console logged above.
    selectOptions = _.filter(selectOptions, (option) => {
      return !!option
    })

    return selectOptions
  },

  getTranslator: (state:AppState):any => {
    if (!state.ui.translator) { // This happens for a few seconds while the translation files are loading.
      return () => { return '' }
    }

    return state.ui.translator
  },

  translate: (state:AppState, key:string):string => {
    if (!state.ui.language || !state.ui.translator || !key) {
      return ''
    }

    const value:string = state.ui.translator(key)
    if (value === key) { // If the returned translation is the same as the key, then the translation failed because there was no translation text. That's just how the library works. In these cases, we can just return an empty string.
      return ''
    }
    
    return value
  },

  translateObject: (state:AppState, key:string):Maybe<any> => {
    if (!state.ui.language || !state.ui.translator) {
      return null
    }

    return state.ui.translator(key)
  },

  hasEloquaCookiePreferencesConfigured: (state:AppState) => {
    const windowRef = (window as any);
    if (!windowRef || !windowRef.utag || !windowRef.utag.gdpr || !windowRef.utag.gdpr.showConsentPreferences) {
      return false
    }

    return true
  },

  isPreloading: (state:AppState):boolean => {
    return state.ui.isPreloading
  },

  getLoadingMessage: (state:AppState):string => {
    return state.ui.loadingMessage
  },

  getLoadingErrorMessage: (state:AppState):string => {
    return state.ui.loadingErrorMessage
  },

  hasRenderError: (state:AppState):boolean => {
    return state.ui.renderErrors.length >= 1
  },

  getAverageFrameRate: (state:AppState):number => {
    if (state.ui.frameDurationHistory.length <= 0) {
      return 0
    }

    const sum = _.reduce(state.ui.frameDurationHistory, (memo, durationInMilliseconds) => {
      return memo + durationInMilliseconds
    })
    const average = sum / state.ui.frameDurationHistory.length
    return 1000 / average
  },

  getCurrentModal: (state:AppState):Maybe<Modal> => {
    if (state.ui.currentModals.length <= 0) {
      return null
    }

    return state.ui.currentModals[state.ui.currentModals.length - 1] // Grab the topmost modal.
  },

  areAnyModalsOpen: (state:AppState):boolean => {
    return state.ui.currentModals.length >= 1
  },

  getAllOpenModals: (state:AppState):Array<Modal> => {
    return state.ui.currentModals
  },

  canGoBack: (state:AppState):boolean => {
    return state.ui.viewHistory.length >= 3 // Can't go back on 1 (preloading) and can't go back on two (view after preloading). Three is the magic number!
  },

  /// Returns the view that would open if the user selects BACK
  getBackView: (state:AppState):View => {
    return state.ui.viewHistory[state.ui.viewHistory.length - 2]
  },

  getCurrentView: (state:AppState):View => {
    return state.ui.viewHistory[state.ui.viewHistory.length - 1]
  },

  isOnVehicleSelectionView: (state:AppState):boolean => {
    const currentView = selectors.getCurrentView(state)
    return currentView === View.VehicleSelection
  },

  isOnVehicleEditView: (state:AppState):boolean => {
    const currentView = selectors.getCurrentView(state)
    return currentView === View.VehicleEdit
  },

  isOnFinaleView: (state:AppState):boolean => {
    const currentView = selectors.getCurrentView(state)
    return currentView === View.Finale
  },

  getEnvironmentConfig: (state:AppState):Maybe<EnvironmentConfig> => {
    return state.ui.environmentConfig
  },

  getVehicles: (state:AppState):Array<Vehicle> => {
    return state.ui.vehicles
  },

  getTemporarilySelectedVehicle: (state:AppState):Maybe<Vehicle> => {
    return state.ui.temporarySelections.getVehicle()
  },

  getSelectedVehicle: (state:AppState):Maybe<Vehicle> => {
    return state.ui.userSelections.getVehicle()
  },

  areUserSelectionsLoading: (state:AppState):boolean => {
    const selections = selectors.getAllUserSelections(state)
    return selections.isLoading()
  },

  isSelectedVehicleLoading: (state:AppState):boolean => {
    const vehicle = selectors.getSelectedVehicle(state)
    if (!vehicle) {
      return false
    }

    return vehicle.isLoading()
  },

  isTemporaryVehicleLoading: (state:AppState):boolean => {
    const vehicle = selectors.getTemporarilySelectedVehicle(state)
    if (!vehicle) {
      return false
    }

    return vehicle.isLoading()
  },

  getSelectedSceneMode: (state:AppState):SceneMode => {
    return state.ui.userSelections.getSceneMode()
  },

  getAllSceneModes: (state:AppState):Array<SceneMode> => {
    return [SceneMode.Daytime, SceneMode.Nighttime]
  },

  getEditorPanelState: (state:AppState):EditorPanelState => {
    return state.ui.editorPanelState
  },

  getSelectedVehiclePanelGroups: (state:AppState):Array<PanelGroup> => {
    const vehicle = selectors.getSelectedVehicle(state)
    if (!vehicle) {
      return []
    }

    return vehicle.getMainWrapPanelGroups()
  },

  getSelectedPanelGroup: (state:AppState):Maybe<PanelGroup> => {
    return state.ui.selectedPanelGroup
  },

  getAccentPanelGroup: (state:AppState):Maybe<PanelGroup> => {
    const vehicle = selectors.getSelectedVehicle(state)
    if (!vehicle) {
      return null
    }

    const panel = vehicle.getPanelGroup(PanelGroup.AccentKey)
    return panel
  },

  getWindowPanelGroup: (state:AppState):Maybe<PanelGroup> => {
    const vehicle = selectors.getSelectedVehicle(state)
    if (!vehicle) {
      return null
    }

    const panel = vehicle.getPanelGroup(PanelGroup.WindowKey)
    return panel
  },

  getWrapFinishGroups: (state:AppState):Array<string> => {
    const wraps:Wrap[] = state.ui.wraps.filter( (wrap:Wrap):boolean => {
      return wrap.meta.area !== WrapArea.ProtectionWrapFilm
    })
    const finishGroups:string[] = wraps.map( (wrap:Wrap):string => {
      return selectors.translate(state, wrap.meta.finishGroup)
    })
    return _.uniq(finishGroups).sort()
  },

  getPwfFinishGroups: (state:AppState):Array<string> => {
    const wraps:Wrap[] = state.ui.wraps.filter( (wrap:Wrap):boolean => {
      return wrap.meta.area === WrapArea.ProtectionWrapFilm
    })
    const finishGroups:string[] = wraps.map( (wrap:Wrap):string => {
      return selectors.translate(state, wrap.meta.finishGroup)
    })
    return _.uniq(finishGroups).sort()
  },

  getSelectedFinishGroup: (state:AppState):Maybe<string> => {
    return state.ui.selectedWrapFinishGroup
  },

  getAllWrapsInSelectedFinishGroup: (state:AppState):Wrap[] => {
    let wraps = state.ui.wraps.filter( (wrap) => {
      return !wrap.meta.isDefault /* Do not include the default wrap in any UI */
    })
    const finishGroup = selectors.getSelectedFinishGroup(state)
    if (finishGroup) {
      wraps = wraps.filter( (wrap) => {
        const wrapFinishGroup = selectors.translate(state, wrap.meta.finishGroup)
        return wrapFinishGroup === finishGroup
      })
    }

    const sorted = _.sortBy(wraps, (wrap) => { return wrap.meta.sortOrder })
    return sorted
  },

  getWrapsInSelectedFinishGroup: (state:AppState):Wrap[] => {
    const wraps:Wrap[] = selectors.getAllWrapsInSelectedFinishGroup(state)
    return wraps.filter( (wrap:Wrap) => {
      return wrap.meta.area !== WrapArea.ProtectionWrapFilm
    })
  },

  getPwfsInSelectedFinishGroup: (state:AppState):Wrap[] => {
    const wraps:Wrap[] = selectors.getAllWrapsInSelectedFinishGroup(state)
    return wraps.filter( (wrap:Wrap) => {
      return wrap.meta.area === WrapArea.ProtectionWrapFilm
    })
  },

  getSelectedWrapForSelectedPanelGroup: (state:AppState):Maybe<Wrap> => {
    const panelGroup = selectors.getSelectedPanelGroup(state)
    if (panelGroup) {
      return selectors.getSelectedWrapForPanelGroup(state, panelGroup)
    }

    return state.ui.userSelections.getEntireVehicleWrap()
  },

  getTemporarilySelectedWrapForSelectedPanelGroup: (state:AppState):Maybe<Wrap> => {
    const panelGroup = selectors.getSelectedPanelGroup(state)
    if (panelGroup) {
      return selectors.getTemporarilySelectedWrapForPanelGroup(state, panelGroup)
    }

    return state.ui.temporarySelections.getEntireVehicleWrap()
  },

  getSelectedWrapForPanelGroup: (state:AppState, panelGroup:Maybe<PanelGroup>):Maybe<Wrap> => {
    const wrap = state.ui.userSelections.getWrapForPanelGroup(panelGroup)
    return wrap
  },

  getTemporarilySelectedWrapForPanelGroup: (state:AppState, panelGroup:Maybe<PanelGroup>):Maybe<Wrap> => {
    const wrap = state.ui.temporarySelections.getWrapForPanelGroup(panelGroup)
    return wrap
  },

  getAllSelectedWraps: (state:AppState):Wrap[] => {
    return state.ui.userSelections.getPanelGroupWraps()
  },

  getSelectedAccentWrap: (state:AppState):Maybe<Wrap> => {
    return state.ui.userSelections.getAccent()
  },

  getTemporarilySelectedAccentWrap: (state:AppState):Maybe<Wrap> => {
    return state.ui.temporarySelections.getAccent()
  },

  getTints: (state:AppState):Array<Tint> => {
    const sorted = _.sortBy(state.ui.tints, (tint) => { return tint.sortOrder })
    return sorted
  },

  getSelectedTint: (state:AppState):Maybe<Tint> => {
    return state.ui.userSelections.getTint()
  },

  getTemporarilySelectedTint: (state:AppState):Maybe<Tint> => {
    return state.ui.temporarySelections.getTint()
  },

  getSelectionsShareURL: (state:AppState, shareChannel:string, userVisible:boolean):string => {
    let url = selectors.translate(state, 'share.shareBaseUrl')
    if (url.indexOf('http') < 0) { // If it's a relative URL, we need to do some massaging to make it an absolute URL.
      let base = window.location.origin.toString()
      if (base.lastIndexOf('/') !== base.length - 1) { // If the origin doesn't end with a slash, add it.
        base += '/'
      }

      if (url.indexOf('/') === 0) { // If the url starts with a slash, take it out because we just ensured the base ends with a slash.
        url = url.substr(1)
      }

      url = base + url
    }

    let token = ''
    switch(shareChannel) {
      case 'facebook':
        token = selectors.translate(state, 'share.shareTokenFacebook')
        break
      case 'twitter':
        token = selectors.translate(state, 'share.shareTokenTwitter')
        break
    }
    url = url.replace('[token]', token)

    const removeProtocolIfNeeded = (url:string, visibleToUser:boolean):string => {
      if (!visibleToUser) {
        return url
      }

      return url.replace('http://', '').replace('https://', '')
    }

    // If the user hasn't selected a vehicle yet, we don't need to generate a share code.
    const hasSelectedVehicle = selectors.getSelectedVehicle(state)
    if (!hasSelectedVehicle) {
      return removeProtocolIfNeeded(url, userVisible) 
    }

    const hasMadeSelectionsForVehicle = selectors.hasMadeAnySelectionsForVehicle(state)
    if (!hasMadeSelectionsForVehicle) {
      return removeProtocolIfNeeded(url, userVisible)
    }

    const code = SharingUtility.createShareCode(state.ui.userSelections)
    url = url + code

    return removeProtocolIfNeeded(url, userVisible)
  },

  getSelectionsShareDescription: (state:AppState, channel:'twitter'|'facebook'|'email'):string => {
    const selections = selectors.getAllUserSelections(state)
    const translate = selectors.getTranslator(state)

    const wrapDescriptions = SharingUtility.createWrapsDescription(selections, translate)
    if (wrapDescriptions.length <= 0 ) {
      return translate('share.channels.' + channel + '.description.none')
    }

    let description = translate('share.channels.' + channel + '.description.single')
    if (wrapDescriptions.length > 2) {
      description = translate('share.channels.' + channel + '.description.many')
    }
    else if (wrapDescriptions.length > 1) {
      description = translate('share.channels.' + channel + '.description.two')
    }

    wrapDescriptions.forEach( (wrap, index) => {
      description = description.replace('[wrap' + (index + 1) + ']', wrap)
    })

    const tintDescription = SharingUtility.createTintDescription(selections, translate)
    if (tintDescription) {
      let tintPhrase = translate('share.channels.' + channel + '.description.tint')
      description += ', ' + tintPhrase.replace('[tint]', tintDescription)
    }

    return description
  },

  getAllUserSelections: (state:AppState):UserSelections => {
    return state.ui.userSelections
  },

  hasMadeVehicleSelection: (state:AppState):boolean => {
    const vehicle = selectors.getSelectedVehicle(state)
    return !!vehicle
  },

  hasMadeAnySelectionsForVehicle: (state:AppState):boolean => {
    if (!selectors.getSelectedVehicle(state)) {
      return false
    }

    const selections = selectors.getAllUserSelections(state)
    return selections.hasMadeAnySelectionsForVehicle()
  },

  getAllTemporarySelections: (state:AppState):UserSelections => {
    return state.ui.temporarySelections
  },

  getDefaultWindowTint: (state:AppState):IMaterial => {
    return DefaultsFinder.getDefaultTint(state.ui.tints)
  },

  getDefaultWrap: (state:AppState):IMaterial => {
    const defaultWrap = DefaultsFinder.getDefaultWrap(state.ui.wraps)
    return defaultWrap
  },

  getShareImageURL: (state:AppState):Maybe<string> => {
    if (!selectors.hasMadeAnySelectionsForVehicle(state)) {
      return selectors.translate(state, 'share.defaultShareImageURL')
    }

    return state.ui.shareImageURL
  },

  getSharePreviewImageURL: (state:AppState):Maybe<string> => {
    if (!selectors.hasMadeAnySelectionsForVehicle(state)) {
      return selectors.translate(state, 'share.defaultShareImageURL')
    }

    return state.ui.sharePreviewURL
  },

  getShareWrapsDescription: (state:AppState):Array<string> => {
    return state.ui.shareWrapsDescription
  },

  getShareTintDescription: (state:AppState):Maybe<string> => {
    return state.ui.shareTintDescription
  },

  getShareImageUploadState: (state:AppState):FetchState => {
    if (!selectors.hasMadeAnySelectionsForVehicle(state)) {
      return FetchState.Success
    }

    return state.ui.shareImageState
  },

  needsShareImageData: (state:AppState):boolean => {
    return state.ui.needsShareImageData
  },

  isRenderingPaused: (state:AppState):boolean => {
    return state.ui.renderingPaused
  },

  isPaintProtectionFilmsEnabled: (state:AppState):boolean => {
    const config = selectors.getEnvironmentConfig(state)
    if (!config) {
      return false
    }
    
    return config.isPaintProtectionFilmsEnabled()
  },

  isProtectionWrapFilmsEnabled: (state:AppState):boolean => {
    const config = selectors.getEnvironmentConfig(state)
    if (!config) {
      return false
    }
    
    return config.isProtectionWrapFilmsEnabled()
  },
  
  getAllPaintProtectionFilms: (state:AppState):Array<PaintProtectionFilm> => {
    return state.ui.films
  },

  getSelectablePaintProtectionFilms: (state:AppState):Array<PaintProtectionFilm> => {
    if (!state.ui.films || state.ui.films.length <= 0) {
      return []
    }

    if (!selectors.isPaintProtectionFilmsEnabled(state)) {
      return []
    }

    // Since we have 'duplicates' depending on the finish option, we need to trim these down.
    let selectedFinish = selectors.getSelectedPaintProtectionFinish(state)
    if (!selectedFinish) { // If the user hasn't made a choice yet, we still need to pick one so that we don't show duplicate Films per finish. User can always swap it out later.
      selectedFinish = selectors.translate(state, state.ui.films[0].finish)
    }

    let matches = state.ui.films.filter( (film) => {
      const translated = selectors.translate(state, film.finish)
      return translated === selectedFinish
    })

    // The sports car doesn't have a partial front mesh. Remove it from the options.
    const vehicle = selectors.getSelectedVehicle(state)
    if (vehicle) {
      matches = matches.filter( (film) => {
        return selectors.doesVehicleSupportPaintProtectionFilm(vehicle, film)
      })
    }

    return matches
  },

  doesVehicleSupportPaintProtectionFilm: (vehicle:Vehicle, film:PaintProtectionFilm):boolean => {
    if (!vehicle) {
      throw new Error('doesVehicleSupportPaintProtectionFilm called, but no vehicle supplied!')
    }

    if (!film) {
      throw new Error('doesVehicleSupportPaintProtectionFilm called, but no film supplied!')
    }

    if (film.name.indexOf('silver') >= 0 && vehicle.paintProtectionFilmMeshNamesSilver.length <= 0) {
      return false
    }

    if (film.name.indexOf('gold') && vehicle.paintProtectionFilmMeshNamesGold.length <= 0) {
      return false
    }

    if (film.name.indexOf('platinum') && vehicle.paintProtectionFilmMeshNamesPlatinum.length <= 0) {
      return false
    }

    return true
  },

  getPaintProtectionFinishOptions: (state:AppState):Array<string> => {
    const finishes = _.pluck(state.ui.films, 'finish')
    const translated = _.map(finishes, (finish) => {
      return selectors.translate(state, finish)
    })

    return _.uniq(translated)
  },

  getSelectedPaintProtectionFilm: (state:AppState):Maybe<PaintProtectionFilm> => {
    return state.ui.userSelections.getPaintProtectionFilm()
  },

  getSelectedPaintProtectionFinish: (state:AppState):Maybe<string> => {
    let finish = state.ui.userSelections.getPaintProtectionFilmFinish()
    return finish
  }
}

export default selectors
;(window as any)['selectors'] = selectors;