import THREE from '../three/threeWithExtensions'

const createPromise = (options:any):Promise<THREE.MeshStandardMaterial> => {
  return new Promise( (resolve, reject) => {
    try {
      const material = new THREE.MeshStandardMaterial(options)
      resolve(material)
    }
    catch(error) {
      reject(error)
    }
  })
}

export default createPromise