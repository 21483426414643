import * as dat from 'dat.gui'
import THREE from '../../three/threeWithExtensions'
import AbstractWrapMaterial from './AbstractWrapMaterial'
import { GuiFieldName, prepareColorField, onColorChange } from '../../utils/onColorChange'
import shaderMaterialPromiseCreator from '../../utils/ShaderMaterialPromiseCreator'
import AssetLibrary from '../../AssetLibrary'
import VoidPromise from '../../utils/VoidPromise';
import Maybe from '../../Maybe'

// A configurable paint shader wrap material.
// Shamelessly borrowed from https://2pha.com/demos/threejs/shaders/9700_car_shader/car_shader_5.html
const vertexShader = `
uniform float flakeScale;
varying vec4 mvPosition;
varying vec3 worldNormal;
varying vec3 cameraToVertex;
varying vec2 vUv;
varying vec2 flakeUv;
void main() {
  mvPosition = modelViewMatrix * vec4( position, 1.0 );
  worldNormal = mat3( modelMatrix[ 0 ].xyz, modelMatrix[ 1 ].xyz, modelMatrix[ 2 ].xyz ) * normal;
  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  cameraToVertex = normalize(worldPosition.xyz - cameraPosition);
  vUv = uv;
  flakeUv = uv * flakeScale;

  gl_Position = projectionMatrix * mvPosition;
}
`

const fragmentShader = `
uniform vec3 paintColor1;
uniform vec3 paintColor2;
uniform vec3 paintColor3;

uniform float normalPerturbation;
uniform float microflakePerturbationA;
uniform float microflakePerturbation;

uniform float glossLevel;
uniform float brightnessFactor;

uniform samplerCube envMap;
uniform samplerCube envMapNight;
uniform float daytimePercentage;

uniform sampler2D normalMap;
uniform sampler2D microflakeNMap;
uniform vec3 flakeColor;
uniform float normalScale;
varying vec2 vUv;
varying vec2 flakeUv;
varying vec3 worldNormal;
varying vec4 mvPosition;
varying vec3 cameraToVertex;

// This function taken directly from the three.js phong fragment shader.
// http://hacksoflife.blogspot.ch/2009/11/per-pixel-tangent-space-normal-mapping.html
vec3 perturbNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
    vec3 q0 = dFdx( eye_pos.xyz );
    vec3 q1 = dFdy( eye_pos.xyz );
    vec2 st0 = dFdx( vUv.st );
    vec2 st1 = dFdy( vUv.st );

    vec3 S = normalize( q0 * st1.t - q1 * st0.t );
    vec3 T = normalize( -q0 * st1.s + q1 * st0.s );
    vec3 N = normalize( surf_norm );

    vec3 mapN = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * mapN.xy;
    mat3 tsn = mat3( S, T, N );
    return normalize( tsn * mapN );
 }

vec3 perturbSparkleNormal2Arb( vec3 eye_pos, vec3 surf_norm ) {
    vec3 q0 = dFdx( eye_pos.xyz );
    vec3 q1 = dFdy( eye_pos.xyz );
    vec2 st0 = dFdx( vUv.st );
    vec2 st1 = dFdy( vUv.st );

    vec3 S = normalize( q0 * st1.t - q1 * st0.t );
    vec3 T = normalize( -q0 * st1.s + q1 * st0.s );
    vec3 N = normalize( surf_norm );

    vec3 mapN = texture2D( microflakeNMap, flakeUv ).xyz * 2.0 - 1.0;
    mapN.xy = 1.0 * mapN.xy;
    mat3 tsn = mat3( S, T, N );
    return normalize( tsn * mapN );
 }

void main() {
  vec3 normal = perturbNormal2Arb(mvPosition.xyz, worldNormal);
  float fFresnel = dot( normalize( -cameraToVertex ), normal );
  vec3 reflection = 2.0 * worldNormal * fFresnel - normalize(-cameraToVertex);

  vec4 envColorDay = textureCube( envMap, vec3( -reflection.x, reflection.yz ), glossLevel );
  vec4 envColorNight = textureCube( envMapNight, vec3( -reflection.x, reflection.yz ), glossLevel );
  vec4 envColor = mix(envColorNight, envColorDay, daytimePercentage);
  envColor.rgb *= brightnessFactor;
  float fEnvContribution = 1.0 - 0.5 * fFresnel;

  vec3 vFlakesNormal = perturbSparkleNormal2Arb(mvPosition.xyz, worldNormal);
  vec3 vNp1 = microflakePerturbationA * vFlakesNormal + normalPerturbation * worldNormal;
  vec3 vNp2 = microflakePerturbation * ( vFlakesNormal + worldNormal ) ;

  float  fFresnel1 = clamp(dot( -cameraToVertex, vNp1 ), 0.0, 1.0);
  float  fFresnel2 = clamp(dot( -cameraToVertex, vNp2 ), 0.0, 1.0);

  float fFresnel1Sq = fFresnel1 * fFresnel1;
  vec3 paintColor = fFresnel1 * paintColor1 +
                     fFresnel1Sq * paintColor2 +
                     fFresnel1Sq * fFresnel1Sq * paintColor3 +
                     pow( fFresnel2, 16.0 ) * flakeColor;

  gl_FragColor = envColor * fEnvContribution + vec4(paintColor, 1.0);
}
`

class FleckShaderMaterial extends AbstractWrapMaterial {
  _uniforms:any = null
  _folder:any = null

  constructor(uniformValues:any) {
    super()

    this._uniforms = {
      paintColor1: prepareColorField({ type: "c", value: new THREE.Color(0x000000) }),
      paintColor2: prepareColorField({ type: "c", value: new THREE.Color(0x000000) }),
      paintColor3: prepareColorField({ type: "c", value: new THREE.Color(0x000000) }),
      normalMap: { type: "t", value: null },
      normalScale: { type: "f", value: 0.0, min: 0.0, max: 1.0 },
      glossLevel: { type: "f", value: 1.0, min: 0.0, max: 5.0 },
      brightnessFactor: {type: "f", value: 1.0, min: 0.0, max: 1.0 },
      envMap: { type: "t", value: null },
      envMapNight: { type: "t", value: null },
      daytimePercentage: { type: "f", value: 0.0, min: 0.0, max: 1.0 },
      microflakeNMap: { type: "t", value: null },
      flakeColor: prepareColorField({ type: "c", value: new THREE.Color(0xffffff) }),
      flakeScale: { type: "f", value: -30.0, min: -50.0, max: 1.0 },
      normalPerturbation: { type: "f", value: 1.0, min: -1.0, max: 1.0 },
      microflakePerturbationA: { type: "f", value: 0.1, min: -1.0, max: 1.0 },
      microflakePerturbation: { type: "f", value: 0.48, min: 0.0, max: 1.0 }
    }

    for (let key in uniformValues) {
      let existing = this._uniforms[key]
      existing.value = uniformValues[key]

      if (existing.type === 'c') {
        existing = prepareColorField(existing)
        this._uniforms[key] = existing
      }
    }

    this._folder = null
  }

  load = (basePath:string):Promise<any> => {
    const promise:Maybe<Promise<void>> = this.getLoadPromise()
		if (!!promise) {
			return promise
		}

    const materialPromise = shaderMaterialPromiseCreator({
      uniforms : this._uniforms,
      vertexShader : vertexShader,
      fragmentShader : fragmentShader,
      extensions: {
        derivatives: true
      }
    })
    materialPromise.then( (material) => {
      this.setRenderMaterial(material)
    })

    const normal = AssetLibrary.loadTexture(basePath+'assets/textures/car_normal.png')
    normal.then( (texture:THREE.Texture) => {
      this._uniforms.normalMap.value = texture
    })

    const metalness = AssetLibrary.loadTexture(basePath+'assets/textures/SparkleNoiseMap.png')
    metalness.then( (texture:THREE.Texture) => {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping
      this._uniforms.microflakeNMap.value = texture
    })

		const allPromise:Promise<void> = VoidPromise.all([normal, metalness])
		this.setLoadPromise(allPromise)
    return allPromise
  }

  configureEnvironmentMaps = (dayEnvMap:any, nightEnvMap:any):void => {
    this._uniforms.envMap.value = dayEnvMap
    this._uniforms.envMapNight.value = nightEnvMap

    const material = this.getRenderMaterial() as THREE.ShaderMaterial
    material.map = dayEnvMap // This is needed for iOS/Android/Mac
  }

  setEnvironmentMapDaytimePercentage = (daytimePercentage:number):void => {
    this._uniforms.daytimePercentage.value = daytimePercentage
  }

  configureGui = (gui:dat.GUI):void => {
    const material = this.getRenderMaterial() as THREE.ShaderMaterial
    this._folder = gui.addFolder('Custom Shader')
    this._folder.addColor(material.uniforms.paintColor1, GuiFieldName).name('paintColor1').onChange( onColorChange(material.uniforms.paintColor1) )
    this._folder.addColor(material.uniforms.paintColor2, GuiFieldName).name('paintColor2').onChange( onColorChange(material.uniforms.paintColor2) )
    this._folder.addColor(material.uniforms.paintColor3, GuiFieldName).name('paintColor3').onChange( onColorChange(material.uniforms.paintColor3) )
    this._folder.add(material.uniforms.normalScale, 'value', 0, 1).name('normalScale').step(0.01)
    this._folder.add(material.uniforms.glossLevel, 'value', 0, 5).name('glossLevel').step(0.01)
    this._folder.add(material.uniforms.brightnessFactor, 'value', 0, 1).name('brightnessFactor').step(0.01)
    this._folder.addColor(material.uniforms.flakeColor, GuiFieldName).name('flakeColor').onChange( onColorChange(material.uniforms.flakeColor) )
    this._folder.add(material.uniforms.flakeScale, 'value', -50, 1).name('flakeScale').step(1)
    this._folder.add(material.uniforms.normalPerturbation, 'value', -1, 1).name('normalPerturbation').step(0.01)
    this._folder.add(material.uniforms.microflakePerturbationA, 'value', -1, 1).name('microflakePerturbationA').step(0.01)
    this._folder.add(material.uniforms.microflakePerturbation, 'value', 0, 1).name('microflakePerturbation').step(0.01)
  }

  unloadGui = (gui:dat.GUI):void => {
    if (this._folder) {
      gui.removeFolder(this._folder)
      this._folder = null
    }
  }
}

export default FleckShaderMaterial