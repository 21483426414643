import axios from 'axios'
import * as querystring from 'querystring'
import EnvironmentConfig from "../../../../core/EnvironmentConfig"
import EnvironmentConfigLoader from '../../../../core/EnvironmentConfigLoader'
import Maybe from '../../../../core/Maybe'
import doesUrlExist from '../../../../core/utils/doesUrlExist'
import VoidPromise from '../../../../core/utils/VoidPromise'
import Vehicle from '../../../../core/vehicles/Vehicle'
import VehiclesConfigLoader from '../../../../core/vehicles/VehiclesConfigLoader'
import SessionManager from '../helpers/SessionManager'
import initializeLanguages from '../i18n'
import View from '../store/models/View'
import Dispatch from './Dispatch'
import GetState from './GetState'
import navigateTo from './navigateTo'
import setLanguage from './setLanguage'
import setLoadingError from './setLoadingError'
import setLoadingMessage from './setLoadingMessage'
import setTrackingMeta from './setTrackingMeta'
import showLanguageSelectionIfNecessary from './showLanguageSelectionIfNecessary'
import startupRemaining from './startupRemaining'
import temporarilySelectVehicle from './temporarilySelectVehicle'
import { type i18n } from 'i18next'

export default () => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(setLoadingMessage("")) // No loading message yet, since no translations are available.
		
    // <environment> config
    // @ts-ignore TypeScript doesn't like custom properties on the window scope.
    const domainPrefix:Maybe<string> = window?.CSDRS?.rootDomain // 3M needed to add a domain prefix to the environment configuration since they are hosting assets on different domains than they are loading them from.
    if (domainPrefix) {
      console.log('CSDRS.domain=' + domainPrefix)
    }

    const environmentConfigLoader = new EnvironmentConfigLoader()
		let environmentConfig:Maybe<EnvironmentConfig> = null
		try {
			environmentConfig = await environmentConfigLoader.load((domainPrefix || '') + '/3m_theme_assets/web_apps/apps/mmmRestylingStudio/assets/js/environment-config.json')
		}
		catch(e) {
      console.error('Unable to load environment config json file! Aborting.', e)
      dispatch(setLoadingError("Unable to load configuration file.")) // Sorry, no translations available at this point.
			return
		}

    if (!environmentConfig) {
      console.error('Unable to load environment config! Aborting.')
      dispatch(setLoadingError("Unable to load configuration file.")) // Sorry, no translations available at this point.
      return
    }

		dispatch({
			type: 'SET_ENVIRONMENT_CONFIG',
			environmentConfig: environmentConfig
    })
    // </environment>

    // Wait for the translations to have loaded before doing anything.
    const defaultLanguageKey:string = environmentConfig.getDefaultLanguageKey()
    let i18n:Maybe<i18n> = null
    try {
      i18n = await initializeLanguages(
        environmentConfig.getLanguageKeys(),
        defaultLanguageKey,
        environmentConfig.getAssetBasePath()
      )
    }
    catch(e) {
      console.error('Unable to load translations!', e)
    }
    if (!i18n) {
      dispatch(setLoadingError('Translations failed'))
      return
    }

    let preferredLanguage:string = defaultLanguageKey
    if (window?.location?.pathname) {
      const localeRegex = /\/(my)?3M\/([a-z][a-z]_[A-Z][A-Z])\//;
      const matches = window.location.pathname.match(localeRegex);
      if (matches && matches[2]) {
        preferredLanguage = matches[2]
      }
    }
    else {
      const queryParams = querystring.parse(window.location.search.substring(1))
      if (queryParams.language) {
        preferredLanguage = queryParams.language.toString()
      }
      else if (SessionManager.hasSavedLanguageSelection()) {
        preferredLanguage = SessionManager.getSavedLanguageSelection()
      }
    }

    
    dispatch(setLanguage(i18n, preferredLanguage))
    dispatch(setTrackingMeta())
    dispatch(setLoadingMessage(i18n.t('loading.vehicles')))

    const vehiclesLoader = new  VehiclesConfigLoader()
    let vehicles = null
    let vehiclesPath = environmentConfig.getAssetFullPath('assets/js/locales/' + preferredLanguage + '/vehicles.json')
    if (!await doesUrlExist(vehiclesPath)) {
      vehiclesPath = environmentConfig.getAssetFullPath('assets/js/vehicles.json')
    }

		try {
			vehicles = await vehiclesLoader.load(vehiclesPath)
		}
		catch(e) {
      console.warn('Unable to load vehicles json file from `' + vehiclesPath + '`!', e)
    }

    if (!vehicles) {
      console.warn('Unable to load vehicles! Aborting.')
      dispatch(setLoadingError(i18n.t('errors.vehicles')))
      return
    }

		dispatch({
			type: 'SET_VEHICLES',
			vehicles: vehicles
    })

    if (vehicles.length <= 0) {
      console.warn('No vehicles listed in vehicles json file! Aborting.')
      dispatch(setLoadingError(i18n.t('errors.vehicles')))
			return
    }

    await preloadVehicleImages(environmentConfig, vehicles)

    const middleVehicle = vehicles[Math.floor(vehicles.length / 2)]
    dispatch(temporarilySelectVehicle(middleVehicle))

    let selectionsCode = null
    if (window.location.hash) {
      const regex = /#d\/([A-Z0-9\-]+)/i
      const found = window.location.hash.match(regex)
      if (found && found.length === 2) {
        selectionsCode = found[1] // We can't actually verify the code until the Wraps and Tints are loaded, so proceed onward with the loading.
      }
    }
    else if (SessionManager.hasSavedSelectionsCode()) {
      selectionsCode = SessionManager.getSavedSelectionCode()
    }

    if (selectionsCode) {
      dispatch(startupRemaining(selectionsCode))
      return
    }

    dispatch(navigateTo(View.VehicleSelection, false))

    dispatch(showLanguageSelectionIfNecessary())
  }
}

const preloadVehicleImages = async (environmentConfig:EnvironmentConfig, vehicles:Array<Vehicle>):Promise<any> => {
  const promises = []

  for(let i=0; i<vehicles.length; i++) {
    const vehicle = vehicles[i]
    promises.push(axios.get(environmentConfig.getAssetFullPath(vehicle.selectImageURL)))
    promises.push(axios.get(environmentConfig.getAssetFullPath(vehicle.selectImageMobileURL)))
    promises.push(axios.get(environmentConfig.getAssetFullPath(vehicle.highlightImageURL)))
    promises.push(axios.get(environmentConfig.getAssetFullPath(vehicle.highlightImageMobileURL)))
    promises.push(axios.get(environmentConfig.getAssetFullPath(vehicle.previewImageURL)))
  }

  return VoidPromise.all(promises)
}