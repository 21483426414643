import Dispatch from './Dispatch'
import GetState from './GetState'
import Vehicle from '../../../../core/vehicles/Vehicle'

export default (vehicle:Vehicle) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'SET_TEMPORARY_VEHICLE',
      vehicle: vehicle
    })
  }
}