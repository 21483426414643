import THREE from '../three/threeWithExtensions'

class PanelGroup {
	// Be careful editing this array. The positions of the particular keys are used when generating share URLs. So if you alter the array, you can mess up previously generated share URLs.
	static Keys:Array<string> = ["front", "hood", "left", "right", "top", "rear"]
	static AccentKey = 'accents'
	static WindowKey = 'windows'

	key:string
	localizationKey:string
  meshNames:Array<string>
  extraMeshNames:Array<string>
	cameraPosition:THREE.Vector3

	constructor(key:string, localizationKey:string, meshNames:Array<string>, extraMeshNames:Array<string>, cameraPosition:THREE.Vector3) {
		if (!key) {
			throw new Error('key is required')
		}
		if (PanelGroup.Keys.indexOf(key) < 0 && key !== PanelGroup.AccentKey && key !== PanelGroup.WindowKey) {
			throw new Error('key must be one of the predefined panel keys')
		}
		this.key = key

		if (!localizationKey) {
			throw new Error('localizationKey is required')
		}
		this.localizationKey = localizationKey

		if (!meshNames) {
			throw new Error('meshNames is required')
		}
		if (meshNames.length <= 0) { // Still allowed, I suppose...It's possible that certain models won't have one of the panel groupings. IE No `roof` meshes on an open-frame jeep.
			console.log('PanelGroup `' + name + '` has no mesh names associated.')
		}
    this.meshNames = meshNames

    if (!extraMeshNames) {
      extraMeshNames = []
    }
    this.extraMeshNames = extraMeshNames

		if (!cameraPosition) {
			throw new Error('cameraPosition is required')
		}
		this.cameraPosition = cameraPosition
  }

  hasPaintableMeshNamed = (meshName:string):boolean => {
    return (this.meshNames.indexOf(meshName) >= 0)
  }

  hasMeshNamed = (meshName:string):boolean => {
    return (this.hasPaintableMeshNamed(meshName) || this.extraMeshNames.indexOf(meshName) >= 0)
  }
}

export default PanelGroup