import Dispatch from './Dispatch'
import GetState from './GetState'
import selectors from '../store/selectors'

export default (error:any) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({
      type: 'RECORD_RENDER_ERROR',
      error: error
    })
  }
}