import BrushedSteel from './BrushedSteel'
import THREE from '../../three/threeWithExtensions';

class BrakeCaliper extends BrushedSteel {
	constructor() {
		super(
			{
				name: 'BrakeCaliper',
				color: new THREE.Color(0xff0000)
			}
		)
	}
}

export default BrakeCaliper