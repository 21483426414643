import ViewportDetector from './ViewportDetector'

export default ():number => {
  let pixelRatio =  window.devicePixelRatio
  if (!pixelRatio || pixelRatio > 2) {
    pixelRatio = 1
  }

  if (pixelRatio === 2 && ViewportDetector.isDesktop()) {
    // This lets Retina-display macs laptops retain their full resolution.
    pixelRatio = 1
  }

  return pixelRatio
}