export function remap(
  low1: number,
  high1: number,
  low2: number,
  high2: number,
  value: number
): number {
  return low2 + (value - low1) * ((high2 - low2) / (high1 - low1))
}

export function clamp(low: number, high: number, value: number): number {
  return Math.max(low, Math.min(high, value))
}
