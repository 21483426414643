import * as React from 'react'
import { connect } from 'react-redux'
import Maybe from '../../../../../../../core/Maybe'
import SceneMode from '../../../../../../../core/SceneMode'
import PaintProtectionFilm from '../../../../../../../core/films/PaintProtectionFilm'
import openAccentPanel from '../../../../actionCreators/editorPanels/openAccentPanel'
import openChangeVehiclePanel from '../../../../actionCreators/editorPanels/openChangeVehiclePanel'
import openPanelGroupSelector from '../../../../actionCreators/editorPanels/openPanelGroupSelector'
import openPpfPanel from '../../../../actionCreators/editorPanels/openPpfPanel'
import openPwfPanel from '../../../../actionCreators/editorPanels/openPwfPanel'
import openWindowTintPanel from '../../../../actionCreators/editorPanels/openWindowTintPanel'
import openWrapsPanel from '../../../../actionCreators/editorPanels/openWrapsPanel'
import openYourDesignPanel from '../../../../actionCreators/editorPanels/openYourDesignPanel'
import selectPanelGroup from '../../../../actionCreators/wraps/selectPanelGroup'
import AppState from '../../../../store/models/AppState'
import EditorPanelState from '../../../../store/models/EditorPanelState'
import selectors from '../../../../store/selectors'
import AccentsIcon from '../../../icons/Accents'
import MaterialsIcon from '../../../icons/Materials'
import TintsIcon from '../../../icons/Tints'
import ShieldActive from '../../../icons/shields/ShieldActive'
import ShieldInactive from '../../../icons/shields/ShieldInactive'
import OptionCategoryButton from '../../OptionCategoryButton'
import DesktopOpenButton from '../DesktopOpenButton'
import PwfIcon from '../../../icons/PwfIcon'

interface OwnProps { }

interface ReduxProps {
  sceneMode: SceneMode
  editorPanelState: EditorPanelState
  selectedFilm: Maybe<PaintProtectionFilm>
  paintProtectionFilmsEnabled: boolean
  protectionWrapFilmsEnabled: boolean
}

interface DispatchProps {
  openWrapsPanel: () => void
  openWindowTintPanel: () => void
  openAccentPanel: () => void
  openPpfPanel: () => void
  openPwfPanel: () => void
  openChangeVehiclePanel: () => void
  openYourDesignPanel: () => void
}

type Props = OwnProps & ReduxProps & DispatchProps

function DesktopButtons(props: Props) {
  const visible = props.editorPanelState === EditorPanelState.Closed

  return (
    <div className='DesktopButtons'>
      <div 
        className='option-select-container'
        style={{
          opacity: visible ? 1 : 0,
          pointerEvents: visible ? 'auto' : 'none',
          zIndex: visible ? 1002 : -1
        }}
      >
        <OptionCategoryButton
          icon={<MaterialsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openWrapsPanel}
          forceHover={false}
        />

        <OptionCategoryButton
          icon={<AccentsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openAccentPanel}
          forceHover={false}
        />
        
        <OptionCategoryButton
          icon={<TintsIcon />}
          sceneMode={props.sceneMode}
          onClick={props.openWindowTintPanel}
          forceHover={false}
        />
        
        {props.paintProtectionFilmsEnabled && (
          <OptionCategoryButton
            className={props.selectedFilm ? 'option-category-button--selected' : ''}
            icon={props.selectedFilm ? <ShieldActive width={45} /> : <ShieldInactive width={35} />}
            sceneMode={props.sceneMode}
            onClick={props.openPpfPanel}
            forceHover={false}
          />
        )}
        
        {props.protectionWrapFilmsEnabled && (
          <OptionCategoryButton
            icon={<PwfIcon />}
            sceneMode={props.sceneMode}
            onClick={props.openPwfPanel}
            forceHover={false}
          />
        )}
      </div>

      <DesktopOpenButton
        i18nKey='editor.your-design'
        side='right'
        onClick={props.openYourDesignPanel}
      />

      <DesktopOpenButton
        i18nKey='editor.change-vehicle'
        side='left'
        onClick={props.openChangeVehiclePanel}
      />
    </div>
  )
}

export default connect(
  (state: AppState): ReduxProps => {
    return {
      sceneMode: selectors.getSelectedSceneMode(state),
      editorPanelState: selectors.getEditorPanelState(state),
      selectedFilm: selectors.getSelectedPaintProtectionFilm(state),
      paintProtectionFilmsEnabled: selectors.isPaintProtectionFilmsEnabled(state),
      protectionWrapFilmsEnabled: selectors.isProtectionWrapFilmsEnabled(state)
    }
  },
  {
    openPanelGroupSelector,
    openWindowTintPanel,
    openWrapsPanel,
    openAccentPanel,
    openPpfPanel,
    openPwfPanel,
    openChangeVehiclePanel,
    openYourDesignPanel,
    selectPanelGroup
  }
)(DesktopButtons)
