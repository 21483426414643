import * as React from 'react'

// Source https://commons.wikimedia.org/wiki/File:3M_wordmark.svg

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.65 23.65">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Icons">
          <path
            d="M11.83,23.65A11.83,11.83,0,1,1,23.65,11.83,11.84,11.84,0,0,1,11.83,23.65ZM11.83,1A10.83,10.83,0,1,0,22.65,11.83,10.84,10.84,0,0,0,11.83,1Z"
            fill="currentColor"
          />
          <rect
            x="0.31"
            y="10.24"
            width="23.02"
            height="3.16"
            transform="translate(11.83 -4.9) rotate(45)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}
